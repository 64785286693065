@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.nav-admin{
    height: 50px;
    background-color: blueviolet;
}
.nav-ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.nav-ul li{
    float: left;
}
.nav-ul li a{
    color: white;
    font-size: 17px;
    font-weight: bold;
    padding: 15px 10px;
    display: block;
    font-family: "Ubuntu", sans-serif;

}