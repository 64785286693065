
/*------------------------------------------------------------------
[Table of contents]

1. General styles and resets
2. Header / #header
3. Sidebar / .sidebar
4. Parallax / .parallax
5. Navigation / .navbar-nav
6. Banner / .banner
7. Feature block / .feature-block
8. Gallery list / .gallery-list
9. Pagination / .pagination
10. Social networks / .social-networks
11. Partner block / .partner-block
12. Table / .table
13. Carousel
14. Blog
15. Search form / .search-form
16. Comment form / .comment-form
17. Contact page
18. Default Page
19. Footer / #footer
20. Background colors
21. Elements
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
1. General styles and resets
-------------------------------------------------------------------*/
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,300italic,100italic,100,400italic,500,500italic,700,700italic,900,900italic);

.default-list,
.cart-list,
.top-user-panel,
.top-right-panel,
.side-list,
.testimonial-holder .owl-pagination,
.dropdown-menu ul,
.feature-block ul,
.gallery-list,
.breadcrumbs ul,
.pagination-wrap .pagination,
.article .ico-action,
.article .ico-list,
.meta-article .ico-action,
.meta-article .ico-list,
.list-view .article .ico-list,
.star-rating,
.social-networks,
.booking-form .option,
.twocol-form .option,
.trip-detail .img-list,
.reviews-info,
.reviews-info .ico-list,
.content-list,
.search-form .ui-autocomplete,
.footer-nav ul,
.social-wrap,
.payment-option,
.demo-feature-list,
.material-icons-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.gallery-list:after,
.filter-option:after,
.article:after,
.article .thumbnail:after,
.list-view .article .info-footer:after,
.recent-block footer:after,
.bar-holder:after,
.reviews-info > li:after,
.nav-wrap:after,
.contact-form .form-group:after,
.cart-option:after,
.footer-holder:after {
	content: '';
	display: block;
	clear: both;
}

.trip-detail .detail .img-list,
.tab-container .nav-tabs {
	text-align: justify;
	line-height: 0;
}

.trip-detail .detail .img-list > *,
.tab-container .nav-tabs > * {
	display: inline-block;
	vertical-align: top;
	text-align: left;
	line-height: inherit;
}

.trip-detail .detail .img-list:after,
.tab-container .nav-tabs:after {
	content: '';
	width: 100%;
	display: inline-block;
	vertical-align: top;
}

.gallery-list .hover,
.gallery-list a,
.btn,
.recent-block.recent-wide-thumbnail .thumbnail,
.social-wrap {
	-webkit-transition: all .3s linear;
	transition: all .3s linear;
}

/* general style for html and body */

html,
body {
	height: 100%;
}

body {
	min-width: 320px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: none;
}

img {
	max-width: 100%;
	height: auto;
}

video {
	width: 100%;
	max-width: 100%;
	height: auto;
	vertical-align: top;
}

/* reset default input styles */

input[type='text'],
input[type='search'],
input[type='password'],
input[type='submit'],
input[type='reset'],
button,
textarea,
input[type='email'] {
	-webkit-appearance: none;
	border-radius: 0;
}

iframe {
	vertical-align: top;
}

a {
	outline: none !important;
}

.form-group {
	margin-bottom: 30px;
}

/* main wrapper styles */

#wrapper {
	width: 100%;
	overflow: hidden;
	height: 100%;
	display: table;
	position: relative;
	table-layout: fixed;
}

.page-wrapper {
	position: relative;
}

.layout-fluid {
	max-width: 1600px;
}

.form-control {
	border: 2px solid #f8f8f8;
	height: 37px;
	font-size: 14px;
	line-height: 18px;
	box-shadow: none;
	outline: none;
	font-weight: 400;
}

.form-control::-webkit-input-placeholder {
	color: #5c5e62;
}

.form-control:-moz-placeholder {
	color: #5c5e62;
}

.form-control::-moz-placeholder {
	color: #5c5e62;
}

.form-control:-ms-input-placeholder {
	color: #5c5e62;
}

.form-control:focus {
	border-color: #dfdfdf;
	box-shadow: none;
}

.bg-quarter-spanish-white {
	background: #f8f0de;
}

/* overlay styles */

.has-overlay {
	position: relative;
}

.has-overlay:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	background: rgba(0, 0, 0, .65);
}

.has-overlay.has-overlay-dark:before {
	background: rgba(0, 0, 0, .85);
}

/* background stretch styles */

.bg-stretch {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	z-index: -1;
}

.bg-stretch img {
	max-width: none;
	width: 100%;
	height: 100%;
}

img {
	vertical-align: top;
	-webkit-backface-visibility: hidden;
}

.blog-full-width .img-wrap img {
	width: 100%;
}

.blog-full-width #content {
	float: none;
}

/* main content part styles */

#content {
	margin-bottom: 50px;
}

.content-inner {
	padding: 45px 0;
}

.content-both-sidebar #sidebar,
.content-both-sidebar #sidebar-right {
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
}

.content-both-sidebar #content {
	margin-top: 30px;
}

.default-list li {
	display: inline-block;
	vertical-align: top;
	padding: 0 10px 10px;
}

.common-spacing {
	padding-top: 60px;
	padding-bottom: 60px;
}

/* fixes transition issue while resizing borwser */

.resize-active * {
	-webkit-transition-duration: 0s !important;
	transition-duration: 0s !important;
}

/* code to remove parallax from touch devices */

.touch-device-detected .parallax {
	background-attachment: scroll;
	background-position: 50% 50% !important;
}

/* styles for border */

.has-border {
	border: 8px solid #e2e2e2;
}

/* text styles */

.text-white {
	color: #fff;
}

/* typography */

body {
	font: 1.4em/1.71428571 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
.h3,
h4 {
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	color: #474d4b;
	margin: 0 0 15px;
}

h1 {
	font-size: 2.571428em;
}

h2 {
	font-weight: 700;
	font-size: 1.2857em;
	letter-spacing: 1px;
}

h3,
.h3 {
	font-size: 1.142857em;
	font-weight: normal;
}

h2.title-heading,
h3.title-heading {
	font-weight: 400;
	letter-spacing: .02em;
}

h2.intro-heading,
h3.intro-heading {
	font-size: 1.71428em;
	letter-spacing: 0;
	font-weight: 400;
	margin-bottom: 15px;
}

h1.small-size {
	font-size: 1.71428em;
	letter-spacing: 2px;
	text-transform: capitalize;
}

h2.small-space,
h3.small-space {
	margin-bottom: 5px;
}

h2.no-space,
h3.no-space {
	margin-bottom: 0;
}

h2.small-size {
	font-size: 1.143em;
	line-height: 1.2;
}

h1.content-main-heading,
h2.content-main-heading {
	font-size: 1.714285em;
	letter-spacing: 1px;
	margin: 0 0 30px;
}

p.intro {
	font-size: 1em;
	margin-bottom: 35px;
}

p.special-text {
	font-size: 1.5em;
	font-weight: 300;
	text-align: center;
	letter-spacing: .035em;
	margin: 0;
}

p.special-text strong {
	font-weight: 700;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

p.special-text a {
	color: #fff;
	display: inline-block;
}

blockquote {
	border: none;
	font-size: 1.428571em;
	line-height: 1.2;
	font-style: italic;
	font-weight: normal;
	margin: 0;
	padding: 0;
	quotes: none;
}

blockquote cite {
	text-transform: uppercase;
	letter-spacing: 2px;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 700;
	font-style: normal;
}

blockquote.testimonial-quote {
	font-style: normal;
	font-size: 1em;
	line-height: 1.6;
}

blockquote.normal {
	font-size: 1.28571em;
}

.main-heading {
	letter-spacing: 2px;
	text-transform: uppercase;
}

.main-subtitle {
	font-size: 1.371428em;
	line-height: 1.25;
	font-weight: 300;
	font-style: italic;
	letter-spacing: .04em;
}

.content-title {
	display: block;
	font-size: 1.143em;
	margin: 0 0 10px;
	text-transform: capitalize;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
}

.info-title {
	font-size: 1em;
	font-style: italic;
	font-weight: normal;
}

.inner-main-heading {
	font-size: 2.571em;
	line-height: 1.2;
	font-weight: normal;
	text-transform: capitalize;
}

/* preloader styles */

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	white-space: nowrap;
	text-align: center;
	background: #f8f8f8;
	z-index: 99;
	-webkit-transition: all linear .3s;
	transition: all linear .3s;
	background-color: #474d4b;
}

.preloader:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	margin-right: -.25em;
}

.preloader.loaded {
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}

.preloader .holder {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
}

/*coffee cup*/

.coffee_cup {
	width: 40px;
	height: 50px;
	border: 2px #6b6957 solid;
	border-radius: 0 0 5px 5px;
	position: relative;
	margin: 36px auto;
}

.coffee_cup:after,
.coffee_cup:before {
	position: absolute;
	content: '';
}

.coffee_cup:after {
	width: 10px;
	height: 20px;
	border: 2px #6b6957 solid;
	border-left: none;
	border-radius: 0 20px 20px 0;
	left: 40px;
	top: 5px;
}

.coffee_cup:before {
	width: 3px;
	height: 10px;
	background-color: #6b6957;
	top: -16px;
	left: 12px;
	box-shadow: 5px 0 0 0 #6b6957,
	5px -5px 0 0 #6b6957,
	10px 0 0 0 #6b6957;
	-webkit-animation: steam 700ms linear infinite alternate;
	animation: steam 700ms linear infinite alternate;
}

/*------------------------------------------------------------------
2. Header / #header
-------------------------------------------------------------------*/

/* header styles */

#header {
	position: relative;
	z-index: 10;
	background: transparent;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
	min-width: 320px;
	background: #252525;
}

/* styles for header fixed state */

/* logo styles */

.logo {
	padding: 21px 0;
	text-align: center;
	position: relative;
	margin: 0 90px;
	z-index: 9;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
}

.logo .gray-logo {
	display: none;
}

.logo:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	margin-right: -.25em;
}

.logo a {
	display: inline-block;
	vertical-align: middle;
	width: 100px;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
}

.cart-wrap .footer {
	padding: 20px 0;
	overflow: hidden;
}

.cart-wrap .footer .btn {
	float: right;
	padding: 2px 20px;
	margin-left: 5px;
}

.cart-wrap .footer .btn:hover {
	color: #fff;
}

.cart-wrap .total {
	color: #adadad;
	font-size: 16px;
	line-height: 18px;
	font-weight: 700;
	display: inline-block;
	vertical-align: middle;
	padding-top: 2px;
}

/* cart list styles */

.cart-list li {
	overflow: hidden;
	padding: 10px 0;
	border-bottom: 1px solid #181818;
}

.cart-list li:hover {
	-webkit-transition: color .2s linear;
	transition: color .2s linear;
}

.cart-list .img {
	float: left;
	width: 40px;
	margin: 5px 10px 0 0;
}

.cart-list .img a {
	display: block;
	border: 2px solid #fff;
	-webkit-transition: border .2s linear;
	transition: border .2s linear;
}

.cart-list .img a:hover {
	border-color: #adadad;
}

.cart-list .text-holder {
	overflow: hidden;
}

.cart-list .amount {
	float: right;
	margin-left: 10px;
}

.cart-list .text-wrap {
	overflow: hidden;
}

.cart-list .name {
	display: block;
	font-weight: 400;
	text-transform: uppercase;
}

.cart-list .name a {
	display: inline-block;
	color: #9d9d9d;
	-webkit-transition: color .2s linear;
	transition: color .2s linear;
}

.cart-list .price {
	display: block;
}

/* white header transparent styles */

/* white header styles */

.default-page #header.default-white-header,
#header.default-white-header {
	background: #f8f8f8;
}

.default-page #header.default-white-header.fixed-position,
#header.default-white-header.fixed-position {
	background: #f8f8f8;
}

.default-page #header.default-white-header .logo,
#header.default-white-header .logo {
	border-right-color: #eaeaea;
}

.default-page #header.default-white-header .logo .normal,
#header.default-white-header .logo .normal {
	display: none;
}

.default-page #header.default-white-header .logo .gray-logo,
#header.default-white-header .logo .gray-logo {
	display: block;
}

.default-page #header.default-white-header .search-form .search-opener,
#header.default-white-header .search-form .search-opener {
	color: #5c5e62;
}

.default-page #header.default-white-header .search-form .search-opener:hover,
#header.default-white-header .search-form .search-opener:hover {
	color: #000;
}

.default-page #header.default-white-header .search-form .search-opener.close,
#header.default-white-header .search-form .search-opener.close {
	color: #fff;
}

.default-page #header.default-white-header .navbar-default .navbar-nav > li > a,
#header.default-white-header .navbar-default .navbar-nav > li > a {
	color: #fff;
}

.default-page #header.default-white-header .navbar-default .nav-opener .icon-bar,
#header.default-white-header .navbar-default .nav-opener .icon-bar {
	background: #5c5e62;
	border-color: #5c5e62;
}

/* header top styles */

.header-top {
	background: #252525;
	padding: 10px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 100%;
	border-bottom: 1px solid #1b1b1b;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.header-top .drop-wrap {
	font-size: .9em;
}

.top-user-panel,
.top-right-panel,
.top-right-panel {
	margin: 0 -10px;
	float: left;
	max-width: 67%;
}

.top-user-panel > li,
.top-right-panel > li,
.top-right-panel > li {
	display: inline-block;
	vertical-align: top;
	padding: 0 10px;
}

.top-user-panel > li > a,
.top-right-panel > li > a,
.top-right-panel > li > a {
	color: #fff;
}

.top-user-panel > li > a:hover,
.top-right-panel > li > a:hover,
.top-right-panel > li > a:hover {
	color: #b0a377;
}

.last-dropdown .dropdown-menu {
	left: auto;
	right: 0;
}

.top-right-panel {
	float: right;
	max-width: 33%;
}

.top-header {
	margin-top: 44px;
}

.top-header.fixed-position {
	margin-top: 0;
}

/* header v1 styles */

.header-v1 .logo {
	text-align: left;
	border: 0;
}

.header-v1 .navbar-default .navbar-nav li:last-child {
	padding-right: 0;
}

.header-v1 .has-mega-dropdown .dropdown-menu {
	left: 0;
	right: 0;
}

.header-v1 .has-mega-dropdown.mega-md .dropdown-menu {
	right: 30px;
}

/* header v1 styles */

.header-v2 .logo {
	border-right: 0;
}
#nav{
    background-color: #252525;
}

/*------------------------------------------------------------------
3. Sidebar / .sidebar
-------------------------------------------------------------------*/

/* sidebar styles */

.sidebar {
	font-weight: 500;
}

.sidebar label {
	font-weight: 500;
}

.sidebar .sidebar-holder {
	box-shadow: 0 2px 2px rgba(1, 2, 2, .1);
}

.sidebar .heading {
	padding: 20px 30px;
	background: #fff;
}

.sidebar .sub-link {
	margin-left: 30px;
	display: block;
	font-weight: 500;
}

.sidebar .sub-link a {
	color: #9d9d9d;
}

.sidebar .sub-link a:hover {
	color: #474d4b;
}

.sidebar h3 {
	margin: 0;
	padding: 0 0 0 50px;
	color: #5c5e62;
	font-size: 1.143em;
	line-height: 1.5em;
	position: relative;
	text-transform: uppercase;
}

.sidebar h3:before {
	content: '\e928';
	font: 24px/18px 'icomoon';
	position: absolute;
	left: 0;
	top: 5px;
}

.sidebar .side-block {
	box-shadow: 0 2px 2px rgba(1, 2, 2, .1);
}

.sidebar .panel-group .panel-default {
	border: 0;
	margin: 0;
}

.sidebar .panel-title {
	font-size: 1em;
	line-height: 1.7124em;
	letter-spacing: .05em;
}

.sidebar .panel-heading a {
	display: block;
	text-transform: uppercase;
	padding: 10px 50px 10px 30px;
	background: #f8f8f8;
	color: #b0a377;
	box-shadow: 0 1px 1px rgba(1, 2, 2, .1);
	position: relative;
}

.sidebar .panel-heading a.collapsed:after {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.sidebar .panel-heading a:after {
	content: '\e906';
	font: 14px/12px 'icomoon';
	position: absolute;
	right: 30px;
	top: 15px;
	-webkit-transition: -webkit-transform .3s linear;
	transition: transform .3s linear;
}

.sidebar .title {
	display: block;
	font-size: 1.143em;
	margin-bottom: 10px;
}

.sidebar .panel-body {
	background: #fff;
	padding: 30px 30px;
	color: #9d9d9d;
}

/* sidebar list styles */

.side-list.check-list {
	margin-bottom: 5px;
}

.side-list.check-list li {
	margin-bottom: 5px;
}

.side-list.post-list li {
	position: relative;
	margin-bottom: 30px;
	padding-bottom: 5px;
}

.side-list.post-list li:last-child {
	margin-bottom: 0;
}

.side-list.post-list li:before {
	content: '';
	position: absolute;
	left: 0;
	top: 100%;
	height: 2px;
	width: 30px;
	background: #e2e2e2;
}

.side-list.post-list time {
	display: block;
	font-size: 12px;
}

.side-list.horizontal-list li {
	display: inline-block;
	vertical-align: top;
	padding: 0 15px;
	margin-bottom: 20px;
	margin-right: -.25em;
	text-align: center;
}

.side-list.gallery-side-list.horizontal-list li {
	width: 33%;
	padding-left: 5px;
	padding-right: 5px;
	margin-bottom: 10px;
}

.side-list.gallery-side-list.horizontal-list img {
	width: 100%;
}

.side-list.hovered-list a {
	color: #9d9d9d;
}

.side-list.hovered-list a:hover {
	color: #b0a377;
}

.side-list.region-list .text {
	width: calc(100% - 50px);
}

.side-list [class^='icon-'],
.side-list [class*=' icon-'] {
	font-size: 25px;
	display: inline-block;
	vertical-align: middle;
}

.side-list a {
	position: relative;
}

.side-list a:hover .popup {
	display: block;
}

.side-list li {
	margin-bottom: 15px;
}

.side-list li.active a {
	color: #b0a377;
}

.side-list li:last-child {
	margin-bottom: 0;
}

.side-list .ico-holder {
	display: inline-block;
	vertical-align: middle;
	width: 45px;
}

.side-list .ico-holder img {
	width: 25px;
}

.side-list .hover {
	display: none;
}

.side-list .text {
	display: inline-block;
	vertical-align: middle;
	width: calc(100% - 20px);
}

.price-input {
	width: 100%;
	border: 0;
	background: none;
	color: #5c5e62;
	font-size: 14px;
}

/* boxed layout styles */

.boxed-layout {
	background: #6b6957;
}

.boxed-layout.bg-pattern {
	/* background: url('../img/retina_wood.png'); */
}

.boxed-layout.bg-image {
	/* background: url('../img/banner/img-15.jpg') no-repeat; */
	background-attachment: fixed;
	background-size: cover;
}

.boxed-layout #wrapper {
	background: #fff;
}

.boxed-layout #wrapper {
	max-width: 1230px;
	margin: 0 auto;
}

.boxed-layout #header {
	max-width: 1230px;
	margin: 0 auto;
	width: 100%;
	left: 0;
	right: 0;
}

.boxed-layout.search-active .search-wrap {
	max-width: 1232px;
	margin: 0 auto;
}

.boxed-layout .has-mega-dropdown .dropdown-menu {
	left: 10px;
	right: 10px;
}

.wow {
	visibility: hidden;
}

.ui-widget {
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.rev_slider {
	color: #fff;
}

.rev_slider_wrapper {
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.rev_slider_wrapper .banner-heading-sub {
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 26px;
	line-height: 30px;
	color: #b0a377;
	padding-right: 10px;
}

.rev_slider_wrapper .banner-heading-text {
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.rev_slider_wrapper .rev-btn {
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}

.rev_slider_wrapper .rev-btn .btn-banner {
	border-color: #6b6957;
	background: none;
	color: #b0a377;
	border-radius: 25px;
}

.rev_slider_wrapper .rev-btn .btn-banner:hover {
	border-color: #6b6957;
	background: #6b6957;
	color: #fff;
}

.rev_slider_wrapper .rev-btn.filled a {
	color: #b0a377;
}

.rev_slider_wrapper .rev-btn.filled:hover a {
	color: #b0a377;
}

.rev_slider_wrapper .rev-btn a {
	display: inline-block;
}

/* testimonial block styles */

.testimonial-holder {
	position: relative;
	color: #fff;
	text-align: center;
	padding: 60px 0;
}

.testimonial-holder .owl-pagination {
	position: static;
	text-align: center;
	padding: 45px 0 0;
}

.testimonial-holder .owl-pagination .owl-page {
	margin: 0 6px;
}

.testimonial-holder .owl-pagination .owl-page.active span:after,
.testimonial-holder .owl-pagination .owl-page:hover span:after {
	visibility: visible;
}

.testimonial-holder .owl-pagination .owl-page span {
	height: 12px;
	width: 12px;
	border: 0;
	background: none;
	position: relative;
	border-radius: 12px;
	text-indent: -9999px;
	opacity: 1;
}

.testimonial-holder .owl-pagination .owl-page span:before {
	top: -1px;
	left: -1px;
	right: -1px;
	bottom: -1px;
	border: 1px solid #fff;
	content: '';
	position: absolute;
	border-radius: 100%;
}

.testimonial-holder .owl-pagination .owl-page span:after {
	position: absolute;
	left: 2px;
	top: 2px;
	right: 2px;
	bottom: 2px;
	background: #fff;
	content: '';
	border-radius: 10px;
	visibility: hidden;
}

.testimonial-holder blockquote .img {
	border: 2px solid #fff;
	width: 90px;
	border-radius: 100%;
	margin: 0 auto 45px;
}

.testimonial-holder blockquote .img img {
	max-width: 100%;
	border-radius: 100%;
}

.testimonial-holder blockquote cite {
	display: block;
	border-bottom: 1px solid #e2e2e2;
	padding-bottom: 20px;
	margin-bottom: 45px;
	position: relative;
}

.testimonial-holder blockquote cite:after {
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	width: 170px;
	height: 8px;
	background: #e2e2e2;
	content: '';
	position: absolute;
	left: 50%;
	bottom: 0;
}

/* service block styles */

.services-block {
	position: relative;
	padding: 60px 0;
}

.services-block .ico-holder {
	font-size: 40px;
}

.services-block.has-bg {
	color: #fff;
}

.services-block.has-bg .ico-article {
	line-height: 24px;
}

.services-block.has-bg .ico-article .content-title,
.services-block.has-bg .ico-article a {
	color: #fff;
	-webkit-transition: color .3s;
	transition: color .3s;
}

.services-block.has-bg .ico-article .ico-holder {
	-webkit-transition: color .3s;
	transition: color .3s;
}

.services-block.has-bg .ico-article:hover .content-title,
.services-block.has-bg .ico-article:hover a {
	color: #b0a377;
}

.services-block.has-bg .ico-article:hover .ico-holder {
	color: #b0a377;
}

.services-block.has-bg .ico-holder {
	min-height: 50px;
}

.services-block.has-bg .title {
	text-transform: uppercase;
	margin-bottom: 15px;
}

.services-block .ico-article {
	margin-top: 45px;
}

.services-block .row {
	margin-top: -45px;
}

.description-text {
	margin-top: 45px;
}

/*------------------------------------------------------------------
4. Parallax / .parallax
-------------------------------------------------------------------*/

.parallax {
	overflow: hidden;
	background-repeat: no-repeat;
	background-color: transparent;
	background-attachment: initial;
	background-size: cover;
	background-position: 50% 0;
	z-index: 1;
	position: relative;
}

.parallax:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
	background: rgba(0, 0, 0, .7);
	z-index: -1;
}

.parallax.banner:before {
	background: rgba(0, 0, 0, .1);
}

.parallax.banner-inner:before {
	background: rgba(0, 0, 0, .5);
}

.parallax.services-block:before {
	background: rgba(0, 0, 0, .85);
}

.no-overlay:before {
	display: none;
}
/* 
#testimonial-home-page {
	background-image: url('../img/generic/img-05.jpg');
}

#banner-homeparallax-default {
	background-image: url('../img/banner/img-07.jpg');
}

#gridview-2-col {
	background-image: url('../img/banner/img-23.jpg');
}

#gridview-3-col {
	background-image: url('../img/banner/img-24.jpg');
}

#gridview-4-col {
	background-image: url('../img/banner/img-25.jpg');
}

#gridview-sidebar {
	background-image: url('../img/banner/img-26.jpg');
}

#gridview-full-width {
	background-image: url('../img/banner/img-27.jpg');
}

#list-view-normal {
	background-image: url('../img/banner/img-29.jpg');
}

#list-view-detail {
	background-image: url('../img/banner/img-28.jpg');
}

#activity-hiking {
	background-image: url('../img/banner/img-31.jpg');
}

#activity-jungle {
	background-image: url('../img/banner/img-12.jpg');
}

#activity-city-tour {
	background-image: url('../img/banner/img-27.jpg');
}

#activity-summer {
	background-image: url('../img/banner/img-25.jpg');
}

#about-banner {
	background-image: url('../img/banner/img-13.jpg');
}



#about-step-block {
	background-image: url('../img/generic/img-16.jpg');
}

#banner-layout-bothsidebar {
	background-image: url('../img/banner/img-14.jpg');
}

#banner-layout-fullwidth-wide {
	background-image: url('../img/banner/img-14.jpg');
}

#banner-layout-fullwidth {
	background-image: url('../img/banner/img-14.jpg');
}

#banner-layout-leftsidebar {
	background-image: url('../img/banner/img-14.jpg');
}

#banner-layout-rightsidebar {
	background-image: url('../img/banner/img-14.jpg');
}

#blog-default {
	background-image: url('../img/banner/img-16.jpg');
}

#blog-leftsidebar {
	background-image: url('../img/banner/img-16.jpg');
}

#blog-rightsidebar {
	background-image: url('../img/banner/img-16.jpg');
}

#blog-fullwidth {
	background-image: url('../img/banner/img-16.jpg');
}

#blog-single {
	background-image: url('../img/banner/img-16.jpg');
}

#banner-contact {
	background-image: url('../img/banner/img-17.jpg');
}

#banner-intro {
	background-image: url('../img/intro/img-01.jpg');
} */

/*------------------------------------------------------------------
5. Navigation / .navbar-nav
-------------------------------------------------------------------*/
#about-service-block {
	background-image: url('../img/generic/img-15.jpg');
}
/* default navigation styles  */

.navbar-default {
	background: transparent;
	border: none;
	min-height: 1px;
	height: 100%;
	margin: 0;
	font-size: 14px;
	letter-spacing: .05em;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.navbar-default .container-fluid,
.navbar-default .navbar-header {
	height: 100%;
}

.navbar-default .navbar-toggle {
	margin-top: 23px;
	margin-right: 48px;
	border: none;
	padding: 0;
	position: absolute;
	margin: 0;
	left: 20px;
	top: -52px;
	padding: 10px;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
	background: none;
}

.navbar-default .navbar-toggle .icon-bar {
	width: 28px;
	height: 1px;
	background-color: #9d9d9d;
	border-radius: 0;
	border: 1px solid #9d9d9d;
}

.navbar-default .navbar-toggle .icon-bar + .icon-bar {
	margin-top: 6px;
}

.navbar-default .icon-angle-down {
	font-size: 8px;
	float: right;
	margin-top: 7px;
}

.navbar-default .navbar-nav {
	margin: 0;
}

.navbar-default .navbar-nav > li {
	border-top: 1px solid #000;
	padding: 0;
	-webkit-transition: all linear .2s;
	transition: all linear .2s;
}

.navbar-default .navbar-nav > li > a {
	color: #fff;
	padding: 20px 30px;
	text-transform: uppercase;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
}

.navbar-default .navbar-nav .icon {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}

.navbar-default .navbar-nav .text {
	display: inline-block;
	vertical-align: middle;
}

.navbar-collapse {
	background: #252525;
	margin: 0;
	padding: 0;
}

.navbar-collapse.in {
	overflow-y: auto;
}

/* dropdown menu styles */

.dropdown-menu {
	border: 0;
	padding: 0;
	position: static;
	width: 100%;
	min-width: 210px;
	font-size: 12px;
	color: #9d9d9d;
	border-radius: 0;
}

.dropdown-menu [class^='icon-'],
.dropdown-menu [class*=' icon-'] {
	margin-right: 5px;
	-webkit-transition: margin .2s linear;
	transition: margin .2s linear;
}

.dropdown-menu > ul > li > a {
	text-transform: uppercase;
	padding: 10px 10px 10px 30px;
}

.dropdown-menu > ul > li > a:hover {
	padding-left: 35px;
}

.dropdown-menu a {
	display: block;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
	color: #9d9d9d;
}

.dropdown-menu a:hover {
	color: #b0a377;
}

.dropdown-menu a:hover [class^='icon-'],
.dropdown-menu a:hover [class*=' icon-'] {
	margin-right: 10px;
	margin-left: -5px;
}

.dropdown-menu .top-title {
	overflow: hidden;
}

.dropdown-menu .top-title .back {
	float: left;
}

.dropdown-menu .top-title .menu-title {
	float: right;
}

.dropdown-menu .title {
	padding: 10px 0;
	display: block;
	text-transform: uppercase;
	cursor: pointer;
	position: relative;
	font-size: 1.18em;
}

.dropdown-menu .sub-link-opener:before {
	content: '\e903';
	font-family: 'icomoon' !important;
	font-size: 8px;
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	transform: rotate(0);
	position: absolute;
	right: 10px;
	top: 7px;
}

.dropdown-menu .drop-wrap {
	padding: 0 30px;
}

.dropdown-menu .drop-wrap ul {
	padding: 0;
}

.dropdown-menu .header-link li {
	padding: 10px 0;
}

.dropdown-menu .header-link li a {
	padding: 0;
	text-transform: uppercase;
	display: inline-block;
}

.dropdown-menu .header-link li a:hover {
	padding-left: 5px;
}

.dropdown-menu .drop-holder a {
	padding: 0;
}

.dropdown-menu .drop-holder .des {
	max-width: 400px;
}

.dropdown-menu .drop-holder .col {
	margin: 20px auto 0;
	max-width: 400px;
}

.dropdown-menu .drop-holder .img-wrap {
	display: block;
	margin-bottom: 15px;
}

.dropdown-menu .drop-holder .img-wrap img {
	width: 100%;
	max-width: 300px;
}

.dropdown-menu .five-col {
	overflow: hidden;
}

.dropdown-menu .five-col .col {
	margin-top: 0;
}

.dropdown-menu .five-col ul {
	text-transform: none;
}

.dropdown-menu .five-col .active .sub-link-opener:before {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

/* mega menu dropdown styles */

.has-mega-dropdown > .dropdown-menu {
	width: 100%;
}

/* global dropdown styles */

.dropdown .icon-angle-down {
	-webkit-transition: -webkit-transform .3s linear;
	transition: transform .3s linear;
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	transform: rotate(0);
}

.dropdown.open .icon-angle-down {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.navbar-nav > li:last-child .dropdown-menu,
.navbar-nav > li.last-dropdown .dropdown-menu {
	left: auto;
	right: 0;
}

.navbar-nav > li.has-mega-dropdown:last-child .dropdown-menu,
.navbar-nav > li.has-mega-dropdown.last-dropdown .dropdown-menu {
	left: 10px;
	right: 10px;
}

/* language dropdown styles */

.lang-wrap .lang-row {
	overflow: hidden;
}

.lang-wrap .lang-col {
	padding: 2px;
}

.lang-wrap a {
	display: block;
	padding: 2px 5px;
}

.lang-wrap a .text {
	color: #9d9d9d;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: .01em;
	margin-left: 5px;
}

.lang-wrap a:hover {
	background: #181818;
}

/*------------------------------------------------------------------
6. Banner / .banner
-------------------------------------------------------------------*/

/* banner styles */

.banner {
	overflow: hidden;
	position: relative;
	z-index: 1;
	background-position: 50% 0;
}

.banner.banner-slider {
	background-position: 0 0;
	min-height: 1px;
}

.banner.banner-slider .trip-form {
	white-space: normal;
}

.banner.banner-slider .trip-form .holder {
	margin-bottom: 10px !important;
}

.banner.banner-slider .solid-border {
	overflow: visible;
	border-radius: 0;
}

.banner.banner-slider .banner-text {
	width: 1170px;
	position: static;
	font-size: 14px;
}

.banner .banner-video {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: -1;
	/* background: url('../videos/video-thumb.jpg') no-repeat; */
	background-size: cover;
	background-position: 50% 50%;
}

.banner .banner-video.has-overlay:before {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	content: '';
	background-color: rgba(51, 51, 51, .7);
}

.banner .banner-video video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	max-width: none;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	-webkit-transition: 1s opacity;
	transition: 1s opacity;
}

.banner.banner-inner {
	height: 100vh;
	min-height: 320px;
	max-height: 500px;
	padding-top: 0;
	color: #fff;
}

.banner.banner-inner .banner-image {
	height: 100%;
}

.banner.banner-inner .img-holder {
	float: left;
	position: relative;
	min-width: 1920px;
	width: 100%;
	height: auto;
	left: 50%;
}

.banner.banner-inner .img-holder:after {
	content: '';
	position: absolute;
	left: -9999px;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, .25);
}

.banner.banner-inner .img-holder img {
	width: 100%;
	position: relative;
	left: -50%;
	width: 100%;
}

.banner.banner-inner .banner-text {
	text-align: left;
	top: auto;
}

.banner.banner-inner .banner-text .center-text {
	padding: 0;
}

.banner.banner-inner .subtitle {
	display: block;
	font-size: 1.428571em;
	line-height: 1.1;
	font-style: italic;
	margin-bottom: 70px;
	font-weight: 400;
}

.banner.banner-inner h1 {
	line-height: 1.2;
	margin-bottom: 15px;
	color: #fff;
}

.banner-inner .banner-text h1 {
	font-weight: 400;
}

#slider-wrapper {
	position: relative;
}

/* banner image styles */

.banner-image {
	height: 100%;
	min-height: 320px;
	position: relative;
}

.banner-image .has-overlay:before {
	background: rgba(0, 0, 0, .35);
}

.banner:hover .slick-arrow {
	opacity: 1;
}

.banner-home .banner-text h1 {
	font-size: 3.571em;
}

.banner-home .banner-text h1 span {
	display: block;
	font-weight: 400;
	font-size: .8em;
	font-style: normal;
}

.banner-home .banner-text .subtitle {
	font-size: 1.429em;
	color: #b0a377;
	font-weight: 300;
	font-style: italic;
	display: block;
	margin-bottom: 25px;
}

/* banner text styles */

.banner-text {
	position: absolute;
	top: 70px;
	bottom: 100px;
	left: 0;
	right: 0;
	white-space: nowrap;
	z-index: 22;
	text-align: center;
}

.banner-text h1 {
	line-height: .9em;
	color: #fff;
	font-weight: 700;
	margin-bottom: 0;
}

.banner-text h1 span {
	color: #b0a377;
}

.banner-text:after {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	margin-right: -.25em;
}

.banner-text .center-text {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
	width: 100%;
	padding: 0 10px;
}

.banner-icon-search .banner-text h1 {
	font-size: 3.571em;
}

.banner-icon-search .banner-text .center-text {
	margin-top: 0;
}

.heading-home {
	font: 700 3.571em/.9em 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
	color: #fff;
	margin-bottom: 0;
	text-align: center;
}

.heading-home span {
	display: block;
	font-weight: 400;
	font-size: .8em;
}

.banner-caption-s1 .center-text,
.banner-home .banner-caption-s1 .center-text {
	max-width: 750px;
	text-align: right;
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.banner-caption-s1 .center-text h1,
.banner-home .banner-caption-s1 .center-text h1 {
	color: #b0a377;
	font-style: italic;
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.banner-caption-s1 .center-text h1 span,
.banner-home .banner-caption-s1 .center-text h1 span {
	color: #fff;
	font-style: normal;
	font-weight: 100;
}

.banner-caption-s1 .center-text .banner-subtitle,
.banner-home .banner-caption-s1 .center-text .banner-subtitle {
	font-weight: 300;
	color: #fff;
	margin-top: 30px;
}

/*------------------------------------------------------------------
7. feature block / .feature-block
-------------------------------------------------------------------*/

/* feature block global styles */

.feature-block {
	background: #252525;
	z-index: 99;
	padding: 10px 0;
	text-transform: uppercase;
	text-align: center;
	font-size: 1em;
	position: relative;
}

.feature-block.hover .swipe.left {
	opacity: 1;
}

.feature-block .ico {
	position: relative;
	top: 0;
	display: block;
	margin: 0 auto 10px;
	-webkit-transition: top .2s linear;
	transition: top .2s linear;
	font-size: 25px;
	line-height: 1;
}

.feature-block .info {
	-webkit-transition: color .2s linear;
	transition: color .2s linear;
}

.feature-block .holder {
	overflow-x: auto; /* 1 */
	-webkit-overflow-scrolling: touch; /* 2 */
}

.feature-block ul {
	text-align: center;
	overflow: hidden;
}

.feature-block li {
	display: inline-block;
	vertical-align: middle;
	margin-left: -.25em;
	padding: 10px;
	position: relative;
	width: 12.5%;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.feature-block li:first-child:before {
	display: none;
}

.feature-block li:before {
	content: '';
	position: absolute;
	left: -1px;
	top: 0;
	bottom: 0;
	background: #000;
	width: 1px;
}

.feature-block li a {
	display: block;
	height: 100%;
	color: #9d9d9d;
}

.feature-block li a:hover {
	color: #b0a377;
}

.feature-block li a:hover .ico {
	top: -5px;
}

/* content heading styles */

.content-heading {
	text-align: center;
	position: relative;
}

.content-heading .subtitle {
	display: block;
}

.gallery-home-holder {
	margin-top: 45px;
}

.gallery-home-holder h2 {
	color: #5c5e62;
}

/* content block styles */

.content-block {
	padding: 60px 0;
	background: #fafafa;
}

.content-block.content-sub {
	padding: 45px 0;
}

.content-block.content-sub .article {
	margin-top: 30px;
}

.content-block.content-sub .content-holder {
	margin: 0 0 45px;
}

.content-block.content-sub .list-view.content-holder {
	margin-top: 45px;
}

.content-block.content-sub .list-view .article {
	margin-top: 15px;
}

.content-block.content-center .img-wrap {
	width: 110px;
	margin: 0 auto 20px;
}

.content-block.guide-sub .img-article {
	margin-top: 45px;
}

.content-block.guide-sub .description,
.content-block.guide-sub .bar-holder {
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
}

.content-block.guide-sub .holder {
	position: relative;
}

.content-block.guide-sub .holder:before {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	content: '';
	background: rgba(0, 0, 0, .4);
	z-index: 1;
	opacity: 0;
	-webkit-transition: opacity linear .5s;
	transition: opacity linear .5s;
}

.content-block.guide-sub .holder:hover:before {
	opacity: 1;
}

.content-block.guide-sub .holder:hover .social-networks {
	opacity: 1;
}

.content-block.guide-sub .holder:hover .caption {
	color: #b0a377;
	margin-left: 0;
	margin-right: 0;
}

.content-block.guide-sub .holder:hover .caption h3 {
	color: #b0a377;
}

.content-block.guide-sub .thumbnail {
	padding: 0;
	background: none;
	border: 0;
	margin-bottom: 0;
}

.content-block.guide-sub .img-wrap img {
	width: 100%;
}

.content-block.guide-sub .designation {
	letter-spacing: .05em;
}

.content-block.guide-sub .description {
	font-size: 1.143em;
	line-height: 1.875;
}

.content-block.guide-sub .description p {
	margin-bottom: 0;
}

.content-block.guide-add .social-networks {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 2;
	width: 160px;
	text-align: center;
	opacity: 0;
	-webkit-transition: all .3s linear;
	transition: all .3s linear;
}

.content-block.guide-add .social-networks li {
	padding: 1px;
}

.content-block.guide-add .social-networks a {
	display: block;
	height: 35px;
	width: 35px;
	display: block;
	text-align: center;
	font-size: 17px;
	line-height: 34px;
	background: #2e3436;
	-webkit-transition: background .2s linear;
	transition: background .2s linear;
}

.content-block.guide-add .social-networks a:hover {
	color: #fff;
}

.content-block.guide-add .social-networks a:hover span {
	color: #fff;
}

.content-block.guide-add .social-networks a span:before {
	line-height: 34px;
}

.content-block.guide-add .social-networks a:hover {
	background: #b0a377;
}

.content-block .content-boxed .thumbnail {
	border: 0;
}

.content-block .content-boxed .article {
	margin-top: 45px;
}

.content-block.article-boxed .content-heading {
	margin-bottom: 0;
}

.content-block.content-spacing .content-heading {
	margin-bottom: 0;
}

.content-sub-holder .article {
	margin-top: 30px;
}

.content-with-sidebar .content-sub-holder {
	padding-bottom: 45px;
}

/* adventure styles */

.adventure-holder .text-block {
	white-space: nowrap;
}

.adventure-holder .text-block:after {
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	margin-right: -.25em;
	content: '';
}

.adventure-holder .text-block .centered {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
	width: 100%;
	padding: 30px 0 15px;
}

.adventure-holder.featured-content {
	text-align: center;
}

.adventure-holder.featured-content .centered {
	padding: 60px 15px;
}

.grid-view-sidebar {
	padding-bottom: 60px;
}

.grid-view-sidebar #content {
	float: none;
	margin-top: 30px;
}

.grid-view-sidebar #sidebar {
	margin-top: 30px;
}

.grid-view-sidebar .db-3-col .article {
	display: block;
	margin-right: auto;
}

.grid-view-sidebar .pagination-wrap {
	max-width: 470px;
	margin-left: auto;
	margin-right: auto;
}

.grid-view-sidebar .filter-option {
	max-width: 470px;
}

/* thumbnail with link styles */

a.thumbnail {
	padding: 0;
	background: transparent;
	border: none;
	border-radius: 0;
	overflow: hidden;
}

a.thumbnail img {
	width: 100%;
	max-width: none;
}

/*------------------------------------------------------------------
8. Gallery list / .gallery-list
-------------------------------------------------------------------*/

/* gallery list styles */

.gallery-list {
	margin: 0 -5px 20px;
}

.gallery-list.has-center {
	text-align: center;
}

.gallery-list.has-center .caption {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 10px;
	white-space: nowrap;
	color: #5c5e62;
	z-index: 2;
}

.gallery-list.has-center .caption:before {
	content: '';
	position: absolute;
	left: 10px;
	right: 10px;
	bottom: 10px;
	top: 10px;
	border: 1px solid #5c5e62;
	z-index: 8;
}

.gallery-list.has-center .caption:after {
	display: inline-block;
	vertical-align: middle;
	content: '';
	margin-right: -.25em;
	height: 100%;
}

.gallery-list.has-center .caption .centered {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
	font-weight: 700;
	z-index: 9;
}

.gallery-list.has-center .title {
	display: block;
	font-size: 1.286em;
	font-weight: 700;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	padding-bottom: 5px;
	margin-bottom: 5px;
	position: relative;
}

.gallery-list.has-center .title:after {
	position: absolute;
	left: 50%;
	width: 50px;
	height: 5px;
	background: #5c5e62;
	content: '';
	bottom: 0;
	margin-left: -25px;
}

.gallery-list.has-center a:after {
	background: rgba(255, 255, 255, .9);
	opacity: 0;
}

.gallery-list.has-center a:hover:after,
.gallery-list.has-center a:hover .caption:before,
.gallery-list.has-center a:hover .centered {
	opacity: 1;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.gallery-list.has-center a .caption:before,
.gallery-list.has-center a .centered {
	opacity: 0;
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all linear .3s;
	transition: all linear .3s;
}

.gallery-list.has-center img {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	display: block;
}

.gallery-list .hover {
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	left: 50%;
	top: 50%;
	position: absolute;
	font-size: 30px;
	color: #fff;
	display: block;
	z-index: 4;
	margin: -25px 0 0 -20px;
}

.gallery-list a {
	overflow: hidden;
	margin: 0;
	display: block;
	position: relative;
	overflow: hidden;
	z-index: 2;
}

.gallery-list a img {
	-webkit-transition: -webkit-transform .3s linear;
	transition: transform .3s linear;
}

.gallery-list a:hover img {
	transform: scale(1.05);
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
}

.gallery-list a:after {
	content: '';
	position: absolute;
	left: 0;
	right: -1px;
	top: 0;
	bottom: 0;
	background: rgba(71, 77, 75, .92);
	opacity: 0;
	-webkit-transition: opacity .2s linear;
	transition: opacity .2s linear;
}

.gallery-list a:hover:after {
	opacity: 1;
}

.gallery-list a:hover .hover {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.gallery-list li {
	float: left;
	width: 33.333%;
	padding: 0 5px;
	margin-bottom: 10px;
	position: relative;
	overflow: hidden;
	clear: right;
}

.gallery-list .info {
	position: absolute;
	bottom: 10px;
	left: 5px;
	letter-spacing: 1px;
	right: 5px;
	text-align: center;
	color: #fff;
	z-index: 2;
	font-size: 14px;
	line-height: 1.5;
	font-weight: 700;
	text-transform: uppercase;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

/* button styles */

.btn {
	text-transform: uppercase;
}

.btn.radius {
	border-radius: 25px;
}

.btn.btn-h-slide,
.btn.btn-default {
	position: relative;
	z-index: 1;
}

.btn.btn-h-slide:before,
.btn.btn-default:before {
	content: '';
	width: 0;
	overflow: hidden;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: -1;
	-webkit-transition: width .2s linear;
	transition: width .2s linear;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
}

.btn.btn-h-slide:hover:before,
.btn.btn-default:hover:before {
	width: 100%;
}

.btn.btn-shadow {
	box-shadow: 1px 2px 5px rgba(0, 0, 0, .35);
}

.btn.btn-primary {
	background: none;
}

.btn.btn-primary:hover {
	border-color: #b0a377;
	background: #b0a377;
	color: #fff;
}

.btn.btn-default {
	font-weight: 700;
	padding: 3px 20px;
	letter-spacing: 1px;
	min-width: 150px;
	font-size: 16px;
	line-height: 20px;
	border-color: #e2e2e2;
	background: #6b6957;
}

.btn.btn-default:before {
	background: #b0a377;
}

.btn.btn-default:hover {
	background: #b0a377;
	border-radius: 0;
	border-color: #e2e2e2;
}

.btn.btn-trip {
	border-radius: 3px;
	font-style: normal;
	font-weight: 700;
	width: 100%;
	color: #b0a377;
	background: #252525;
	letter-spacing: .05em;
}

.btn.btn-trip:hover {
	background: #4f4d40;
}

.btn.btn-info-sub {
	background: #6b6957;
	border-color: #6b6957;
	color: #fff;
	font-weight: 700;
}

.btn.btn-info-sub:hover {
	box-shadow: none;
	color: #fff;
	background: #87856e;
	border-color: #87856e;
}

.btn.btn-info {
	background: #b0a377;
	border-color: #b0a377;
	color: #fff;
}

.btn.btn-info:hover {
	background: #9a8c5a;
	color: #fff;
}

.btn.btn-white {
	border: 1px solid #fff;
	color: #fff;
	padding: 3px 15px;
	min-width: 260px;
}

.btn.btn-white:hover {
	background: #fff;
	color: #b0a377;
}

.btn.btn-lg {
	padding: 10px;
	min-width: 250px;
}

.btn.btn-md {
	font-size: 18px;
	line-height: 24px;
	padding: 12px;
	min-width: 250px;
	letter-spacing: 1px;
}

.btn-banner {
	z-index: 8;
	white-space: nowrap;
	font-size: 12px;
	line-height: 16px;
	font-weight: 700;
	color: #b0a377;
	background-color: transparent;
	padding: 7px 45px;
	border: 1px solid #6b6957;
	text-transform: uppercase;
}

.btn-banner:hover {
	background: #6b6957;
	color: #fff;
}

.banner-caption-s1 .center-text .btn-banner-holder,
.banner-home .banner-caption-s1 .center-text .btn-banner-holder {
	margin-top: 20px;
}

.banner-caption-s1 .center-text .btn-banner,
.banner-home .banner-caption-s1 .center-text .btn-banner {
	border-radius: 25px;
}

/* breadcrumbs styles */

.breadcrumbs {
	color: #fff;
	font-size: 1.143em;
	text-transform: uppercase;
}

.breadcrumbs li {
	display: inline;
}

.breadcrumbs li:first-child:before {
	display: none;
}

.breadcrumbs li:before {
	content: '/';
	display: inline-block;
	vertical-align: middle;
	margin: -5px 2px 0;
}

.breadcrumbs a {
	color: #fff;
	-webkit-transition: color .2s linear;
	transition: color .2s linear;
}

.breadcrumbs a:hover {
	color: #e6e6e6;
}

/* filter option styles */

.filter-option {
	position: relative;
	max-width: 470px;
	margin: 0 auto;
}

.filter-option .btn-filter {
	margin: 0 15px;
	border-width: 2px;
	line-height: 40px;
	padding: 0 10px;
}

.filter-option .btn-filter i {
	font-size: 25px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	margin-top: -2px;
}

.filter-option .select-col.sidebar-filter-opener {
	display: none;
}

.filter-option .filter-slide {
	max-height: 0;
	-webkit-transition: all linear .3s;
	transition: all linear .3s;
	overflow: hidden;
	width: calc(100% + 130px);
	margin-bottom: -10px;
	padding-top: 10px;
}

.filter-option .filter-slide .select-col {
	margin-top: 10px;
	width: 100%;
}

.filter-option.filter-box {
	background: #fff;
	padding: 15px 25px 5px 20px;
}

.filter-option.filter-box .select-holder {
	text-align: right;
}

.filter-option.filter-box .result-info {
	padding-top: 5px;
}

.filter-option.filter-box .link {
	width: 34px;
	height: 34px;
	font-size: 18px;
	line-height: 34px;
	margin-left: 15px;
}

.filter-option.filter-box .select-holder {
	width: calc(100% - 77px);
	text-align: left;
}

.filter-option .result-info {
	font-size: 1.143em;
	line-height: 1.5em;
	padding-top: 10px;
	display: block;
	margin-bottom: 15px;
}

.filter-option .select-col {
	padding: 0 15px 10px;
	width: 180px;
	display: inline-block;
	vertical-align: top;
	margin-right: -.25em;
}

.filter-option .select-holder {
	float: left;
	width: calc(100% - 100px);
	margin: 0 -15px;
}

.filter-option .layout-action {
	float: right;
	max-width: 140px;
}

.filter-option .link {
	display: inline-block;
	vertical-align: top;
	height: 44px;
	width: 44px;
	border: 2px solid #e2e2e2;
	line-height: 44px;
	text-align: center;
	color: #e2e2e2;
	margin: 0 -.25em 0 19px;
	font-size: 25px;
	-webkit-transition: all .3s linear;
	transition: all .3s linear;
}

.filter-option .link:last-child {
	margin-right: 0;
}

.filter-option .link.active,
.filter-option .link:hover {
	color: #b0a377;
}

.filter-active .filter-slide {
	max-height: 350px;
}

/*------------------------------------------------------------------
9. Pagination / .pagination
-------------------------------------------------------------------*/

/* pagination styles */

.pagination-wrap {
	padding: 25px 20px;
	text-align: center;
	background: #fff;
	box-shadow: 0 1px 1px rgba(1, 2, 2, .1);
}

.pagination-wrap.bg-gray {
	background: #f8f8f8;
}

.pagination-wrap .pagination {
	font-size: 0;
	line-height: 0;
	min-width: 170px;
}

.pagination-wrap .btn-next,
.pagination-wrap .btn-prev {
	font-size: 14px;
	padding-top: 5px;
}

.pagination-wrap .btn-next a,
.pagination-wrap .btn-prev a {
	margin: 0;
	width: auto;
	height: auto;
	border: 0;
}

.pagination-wrap .btn-next a:hover,
.pagination-wrap .btn-prev a:hover {
	background: none;
	color: #b0a377;
	border: 0;
}

.pagination-wrap .btn-prev {
	float: left;
}

.pagination-wrap .btn-prev span {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	display: inline-block;
}

.pagination-wrap .btn-next {
	float: right;
}

.pagination-wrap li {
	display: inline-block;
	padding: 0 5px;
	vertical-align: middle;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}

.pagination-wrap li a {
	padding: 0;
	background: none;
	border: 0;
	line-height: 20px;
	font-size: 16px;
	width: 22px;
	height: 22px;
	display: block;
	color: #5c5e62;
}

.pagination-wrap li a:hover {
	background: #b0a377;
	color: #fff;
	border: 1px solid #f8f8f8;
}

.pagination-wrap li.active a {
	color: #fff;
	border: 1px solid #f8f8f8;
	border-color: transparent;
	background: #b0a377;
}

.pagination-wrap li.active a:hover {
	background: #b0a377;
	color: #fff;
	border-color: transparent;
}

/* count block styles */

.count-block {
	color: #fff;
	text-align: center;
}

.count-block .icon {
	display: block;
	margin: 0 auto 15px;
	position: relative;
	top: 0;
	-webkit-transition: top .2s linear;
	transition: top .2s linear;
	font-size: 50px;
}

.count-block .icon.icon-duration {
	font-size: 38px;
	height: 50px;
}

.count-block .info,
.count-block .txt {
	display: block;
	margin-bottom: 0;
	text-transform: uppercase;
}

.count-block .txt {
	margin: 0;
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.count-block .info {
	font-size: 1.667em;
	font-weight: 700;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	margin-bottom: 10px;
}

.count-block .block-1 {
	background: #6b6957;
}

.count-block .block-2 {
	background: #565335;
}

.count-block .block-3 {
	background: #474d4b;
}

.count-block .block-4 {
	background: #b0a377;
}

.count-block .holder {
	padding: 45px 10px;
	font-size: 1.286em;
	text-align: center;
}

.count-block .holder:hover .icon {
	top: -15px;
}

.count-block .wpb_wrapper {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
}

/* article styles */

.article {
	line-height: 1.714;
	max-width: 500px;
	margin: 45px auto 0;
}

.article.thumb-full .thumbnail {
	border-radius: 5px 5px 0 0;
}

.article.thumb-full .thumbnail .img-wrap {
	margin: -10px -10px 25px;
	overflow: hidden;
	border-radius: 5px 5px 0 0;
}

.article.has-hover-s1 .img-wrap {
	overflow: hidden;
}

.article.has-hover-s1 .img-wrap img {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform .2s linear;
	transition: transform .2s linear;
}

.article.has-hover-s1:hover .img-wrap img {
	-webkit-transform: scale(1.4);
	-ms-transform: scale(1.4);
	transform: scale(1.4);
}

.article.has-hover-s2 .img-wrap {
	position: relative;
	overflow: hidden;
}

.article.has-hover-s2 .img-wrap img {
	margin: -10px 0 0 -10px;
	max-width: none;
	width: calc(100% + 10px);
	-webkit-transition: opacity .35s, -webkit-transform .35s;
	transition: opacity .35s, transform .35s;
	-webkit-transform: translate3d(10px, 10px, 0);
	transform: translate3d(10px, 10px, 0);
}

.article.has-hover-s2 .img-wrap .text:before {
	position: absolute;
	right: -120px;
	bottom: -140px;
	width: 250px;
	height: 250px;
	border: 2px solid #fff;
	border-radius: 50%;
	box-shadow: 0 0 0 900px rgba(255, 255, 255, .2);
	content: '';
	opacity: 0;
	-webkit-transform: scale3d(.5, .5, 1);
	transform: scale3d(.5, .5, 1);
	-webkit-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	background: rgba(0, 0, 0, .2);
}

.article.has-hover-s2 .img-wrap .text:before,
.article.has-hover-s2 .img-wrap p {
	-webkit-transition: opacity .35s, -webkit-transform .35s;
	transition: opacity .35s, transform .35s;
}

.article.has-hover-s2 .img-wrap p {
	margin: 0;
	color: #fff;
	font-size: 42px;
	line-height: 1;
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 0 28px 25px 0;
	width: 110px;
	text-align: right;
	opacity: 0;
	-webkit-transform: translate3d(20px, 20px, 0);
	transform: translate3d(20px, 20px, 0);
}

.article.has-hover-s2:hover h3 a {
	color: #474d4b;
}

.article.has-hover-s2:hover img {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.article.has-hover-s2:hover .text:before {
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}

.article.has-hover-s2:hover p {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

.article.has-hover-s3 img,
.article.has-hover-s3 .info-footer,
.article.has-hover-s3 .hover-article {
	-webkit-transition: opacity .35s, -webkit-transform .35s;
	transition: opacity .35s, transform .35s;
}

.article.has-hover-s3 .star-rating > span {
	color: #b0a377;
}

.article.has-hover-s3 .star-rating > span.disable {
	color: #fff;
}

.article.has-hover-s3 .star-rating > span:hover {
	color: #9a8c5a;
}

.article.has-hover-s3:hover img {
	opacity: .4;
	-webkit-transform: scale3d(1.2, 1.2, 1);
	transform: scale3d(1.2, 1.2, 1);
}

.article.has-hover-s3:hover .hover-article {
	opacity: 1;
}

.article.has-hover-s3:hover .img-caption {
	color: #fff;
}

.article.has-hover-s3:hover .info-footer {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
}

.article.has-hover-s3:hover h3 a {
	color: #b0a377;
}

.article.has-hover-s3:hover .more {
	color: #b0a377;
}

.article.has-hover-s3 .img-wrap {
	background: #000;
	overflow: hidden;
}

.article .hover-article {
	color: #fff;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: left;
	opacity: 0;
}

.article .hover-article a {
	color: #fff;
}

.article .hover-article a:hover {
	color: #b0a377;
}

.article .hover-article .icons {
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 25px;
}

.article .hover-article .star-rating {
	position: absolute;
	top: 15px;
	left: 15px;
}

.article .hover-article .price {
	display: inline-block;
	vertical-align: middle;
	width: 50%;
	font-size: 1em;
	margin-right: -.25em;
	font-weight: 400;
	letter-spacing: 1px;
	font-style: italic;
}

.article .hover-article .price span {
	font-size: 1.286em;
	display: block;
	margin-top: -5px;
	font-weight: 900;
	font-style: normal;
}

.article .hover-article .link-more {
	max-width: 50%;
	text-align: right;
	float: right;
	margin: 20px 0 0;
	text-transform: uppercase;
	font-weight: 900;
	font-size: 1.143em;
	letter-spacing: 1px;
}

.article .hover-article .info-footer {
	-webkit-transform: translate(0, -20px);
	-ms-transform: translate(0, -20px);
	transform: translate(0, -20px);
	font-weight: 700;
	position: absolute;
	bottom: 15px;
	left: 15px;
	right: 15px;
}

.article .link-view a:after {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
	content: '\e906';
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
	font: 10px/10px 'icomoon';
	margin-top: -3px;
}

.article .ico-action {
	display: inline-block;
	vertical-align: middle;
}

.article .ico-action li {
	display: inline-block;
	vertical-align: middle;
	padding: 0 5px;
}

.article .ico-action a:hover {
	color: #474d4b;
}

.article .heading {
	margin-bottom: 15px;
	font-weight: 700;
}

.article h3 a {
	color: #5c5e62;
}

.article h3 a:hover {
	color: #b0a377;
}

.article .more {
	color: #5c5e62;
	font-style: italic;
	font-weight: 400;
	letter-spacing: .05em;
}

.article .more:hover {
	color: #b0a377;
}

.article .social-networks {
	font-size: 21px;
	margin: 0 -10px;
}

.article .social-networks li {
	padding: 0 10px;
	font-size: 17px;
}

.article .social-networks a {
	color: #5c5e62;
}

.article .ico-list {
	line-height: 1;
}

.article .ico-list li {
	display: inline-block;
	vertical-align: middle;
	padding: 0 3px;
}

.article .sub-title {
	font-style: italic;
	font-size: 1em;
	display: block;
	margin-bottom: 10px;
}

.article .img-wrap,
.article .img-wrap1 {
	margin: 0 0 40px;
	text-align: center;
}

.article h2 {
	color: #000;
	letter-spacing: .1em;
}

.article .col {
	display: table-cell;
	vertical-align: middle;
	padding: 0 30px;
}

.article .col:first-child {
	width: 51.3%;
}

.article .img-wrap {
	position: relative;
}

.article .img-wrap img {
	width: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.article .img-wrap .social-networks {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 2;
	width: 160px;
	text-align: center;
	opacity: 0;
	-webkit-transition: all linear .2s;
	transition: all linear .2s;
}

.article .img-wrap .social-networks li {
	margin-right: -.25em;
	padding: 0 1px;
}

.article .img-wrap .social-networks a {
	width: 36px;
	height: 34px;
	display: block;
	text-align: center;
	line-height: 34px;
	background: #474d4b;
}

.article .img-wrap .social-networks a:hover {
	background: #000;
	color: #fff;
}

.article .img-caption {
	position: absolute;
	top: 15px;
	right: 0;
	background: #000;
	color: #b0a377;
	font-size: 14px;
	line-height: 18px;
	padding: 4px 10px;
	font-weight: 400;
	z-index: 2;
	letter-spacing: .02em;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.article .thumbnail {
	margin-bottom: 0;
	padding: 10px;
	border: none;
	line-height: 1.7142857;
	box-shadow: 0 2px 2px rgba(1, 2, 2, .05);
}

.article .thumbnail:hover h3 a {
	color: #b0a377;
}

.article .thumbnail:hover footer .price span {
	color: #b0a377;
}

.article .thumbnail .info-title {
	display: block;
	margin-bottom: 10px;
}

.article .thumbnail .img-wrap {
	margin: 0 0 25px;
}

.article .thumbnail .meta {
	padding-bottom: 25px;
	margin-bottom: 10px;
	border-bottom: 1px solid #dedede;
}

.article .thumbnail .meta .country {
	padding-right: 5px;
}

.article .thumbnail .meta .country,
.article .thumbnail .meta .activity {
	max-width: 50%;
	display: inline-block;
	vertical-align: top;
	margin-right: -.25em;
}

.article .thumbnail .meta .country [class^='icon-'],
.article .thumbnail .meta .country [class*=' icon-'],
.article .thumbnail .meta .activity [class^='icon-'],
.article .thumbnail .meta .activity [class*=' icon-'] {
	font-size: 15px;
	display: inline-block;
	vertical-align: middle;
	margin: -1px 5px 0 0;
}

.article .thumbnail .star-rating [class^='icon-'],
.article .thumbnail .star-rating [class*=' icon-'] {
	margin-right: 0;
}

.article .thumbnail p {
	letter-spacing: .03em;
	margin-bottom: 25px;
}

.article footer,
.article .info-aside {
	overflow: hidden;
	margin: 15px 0 -5px;
	padding-top: 5px;
	border-top: 1px solid #dedede;
}

.article footer .social-networks,
.article .info-aside .social-networks {
	max-width: 68%;
	float: right;
	padding-top: 5px;
}

.article footer .price,
.article .info-aside .price {
	overflow: hidden;
	display: block;
	font-style: italic;
	letter-spacing: 1px;
	max-width: 32%;
	font-weight: 400;
}

.article footer .price span,
.article .info-aside .price span {
	display: block;
	font-weight: 900;
	font-style: normal;
	font-size: 1.286em;
	margin-top: -5px;
}

.article .ico-list {
	overflow: hidden;
}

.article .ico-list li {
	display: inline-block;
	vertical-align: middle;
	padding: 0 5px;
}

.article .info-aside {
	text-align: center;
	border-top: 0;
	border-left: 1px solid #ebebeb;
}

.article .info-aside .price {
	font-size: 1.286em;
	margin-bottom: 15px;
}

.article .info-aside .price span {
	font-size: 2.143em;
	line-height: 1.5em;
}

.article .activity-level {
	margin-bottom: 15px;
}

.article .activity-level .text {
	display: block;
	margin-top: 5px;
	color: #9d9d9d;
}

/* meta article styles */

.meta-article {
	overflow: hidden;
	margin: 30px 0 5px -5px;
	padding-top: 5px;
}

.meta-article .ico-action {
	display: inline-block;
	vertical-align: middle;
}

.meta-article .ico-action li {
	display: inline-block;
	vertical-align: middle;
	padding: 0 5px;
}

.meta-article .ico-action a {
	color: #5c5e62;
}

.meta-article .ico-action a:hover {
	color: #474d4b;
}

.meta-article .ico-list {
	display: inline-block;
}

.meta-article .ico-list li {
	display: inline-block;
	vertical-align: middle;
	padding: 0 5px;
}

.meta-article .star-rating,
.meta-article .comment,
.meta-article .ico-action,
.meta-article .rate-info {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding: 0 10px;
}

.meta-article .star-rating:before,
.meta-article .comment:before,
.meta-article .ico-action:before,
.meta-article .rate-info:before {
	content: '';
	position: absolute;
	left: 0;
	top: 4px;
	bottom: 3px;
	width: 1px;
	background: #e2e2e2;
}

.meta-article .star-rating {
	padding-left: 0;
}

.meta-article .star-rating:before {
	display: none;
}

/* article with image styles */

.img-article {
	max-width: 500px;
	margin: 0 auto;
}

.img-article .img-wrap {
	margin-bottom: 0;
}

.img-article .img-wrap img {
	width: 100%;
}

.img-article.article-light:hover .text-block {
	background: #ebebeb;
}

.img-article.article-light .text-block {
	background: #f8f8f8;
	color: #5c5e62;
}

.img-article.article-light .text-block a {
	color: #5c5e62;
}

.img-article .caption {
	margin: -50px 40px 0;
	position: relative;
	z-index: 4;
	background: #6b6957;
	padding: 20px 10px;
	color: #fff;
	text-align: center;
	box-shadow: 1px 2px 1px rgba(1, 2, 2, .3);
	-webkit-transition: margin .2s linear;
	transition: margin .2s linear;
}

.img-article .caption h3 {
	color: #fff;
}

.img-article .text-block {
	background: #6b6957;
	padding: 30px 15px 15px;
	color: #fff;
	-webkit-transition: background .2s linear;
	transition: background .2s linear;
}

.img-article .text-block h3 {
	text-transform: none;
	font-weight: 700;
	color: #fff;
	letter-spacing: 1px;
	margin-bottom: 15px;
}

.img-article .text-block h3 a {
	color: #fff;
}

.img-article .text-block p {
	margin-bottom: 25px;
}

.img-article .holder:hover .caption,
.img-article .holder:hover .text-block {
	background: #474d4b;
}

.img-article .social-networks {
	margin: 0 -10px;
}

.img-article .social-networks li {
	font-size: 22px;
	padding: 0 10px;
}

.img-article .social-networks a {
	color: #fff;
}

.guide-info {
	margin-top: 45px;
}

/* list view styles */

.list-view .filter-option {
	max-width: 500px;
}

.list-view .star-rating {
	margin-right: 15px;
}

.list-view .star-rating .disable {
	color: #787878;
}

.list-view .activity-level .hover {
	display: none;
}

.list-view .activity-level .ico {
	font-size: 67px;
	line-height: 1;
	color: #9d9d9d;
}

.list-view .article {
	max-width: 500px;
}

.list-view .article .thumbnail:hover h3 a {
	color: #b0a377;
}

.list-view .article .thumbnail:hover .price span {
	color: #b0a377;
}

.list-view .article .thumbnail:hover .activity-level .ico {
	color: #b0a377;
}

.list-view .article .thumbnail:hover .star-rating {
	color: #b0a377;
}

.list-view .article .img-wrap {
	text-align: center;
	margin: 0 auto 20px;
}

.list-view .article .img-wrap img {
	border-radius: 5px;
	width: 100%;
}

.list-view .article .info-aside {
	border: solid #ebebeb;
	border-width: 1px 0 0;
	margin-bottom: 20px;
	padding-top: 15px;
}

.list-view .article .info-aside .price {
	max-width: none;
}

.list-view .article .info-aside .price span {
	color: #5c5e62;
	font-size: 1.186em;
}

.list-view .article h3 {
	overflow: hidden;
}

.list-view .article .star-rating,
.list-view .article .info-rate {
	display: inline-block;
	vertical-align: middle;
	max-width: 42%;
}

.list-view .article .info-rate {
	max-width: 56%;
}

.list-view .article .info-footer {
	overflow: visible;
}

.list-view .article .info-footer li {
	display: inline-block;
	vertical-align: middle;
	margin: 0 10px;
	padding: 0;
}

.list-view .article .info-footer [class^='icon-'],
.list-view .article .info-footer [class*=' icon-'] {
	font-size: 20px;
}

.list-view .article .reviews-holder {
	margin-bottom: 15px;
}

.list-view .article .ico-list {
	display: inline-block;
	vertical-align: middle;
	border-right: 1px solid #ebebeb;
	overflow: visible;
}

.list-view .article footer {
	border: 0;
	margin-top: 0;
	padding-top: 0;
	padding-bottom: 5px;
}

.list-view .article .ico-action a {
	color: #9d9d9d;
}

.list-view .article .ico-action a:hover {
	color: #b0a377;
}

/* recent block styles */

.recent-block {
	padding: 45px 0;
}

.recent-block h2 {
	margin: 0;
}

.recent-block .thumbnail {
	padding: 20px 10px;
}

.recent-block .thumbnail:hover .sub-info span:last-child {
	color: #474d4b;
}

.recent-block.recent-wide-thumbnail .img-wrap {
	margin: 0 -10px 25px;
}

.recent-block.recent-wide-thumbnail .thumbnail:hover {
	box-shadow: 0 3px 7px rgba(0, 0, 0, .35);
}

.recent-block.recent-list .thumbnail {
	background: #fafafa;
	box-shadow: none;
}

.recent-block .article {
	margin-top: 30px;
}

.recent-block.recent-gray {
	background: #fafafa;
}

.recent-block.recent-gray .thumbnail {
	background: #fff;
}

.recent-block.recent-gray .article .col-holder {
	background: #fff;
	box-shadow: none;
}

.recent-block.recent-gray .article footer {
	margin: 0;
}

.recent-block .thumbnail {
	background: #f8f8f8;
}

.recent-block h3 {
	color: #5c5e62;
}

.recent-block .article .img-wrap {
	margin-bottom: 20px;
}

.recent-block .article .img-wrap img {
	width: 100%;
}

.recent-block footer {
	overflow: visible;
	border-top: 0;
	margin-top: 0;
}

.recent-block .sub-info {
	float: right;
	width: 124px;
	font-size: 1.143em;
	line-height: 1em;
	font-weight: 700;
	margin: 0 -10px;
}

.recent-block .sub-info span {
	display: inline-block;
	vertical-align: middle;
	padding: 0 10px;
	position: relative;
	margin-right: -.25em;
}

.recent-block .sub-info span:before {
	content: '';
	left: 0;
	top: 2px;
	bottom: 2px;
	background: #5c5e62;
	width: 1px;
	position: absolute;
}

.recent-block .sub-info span:first-child:before {
	display: none;
}

.recent-block .ico-list {
	overflow: visible;
	float: left;
	width: calc(100% - 112px);
	margin: 0 -5px;
}

.recent-block .ico-list li {
	margin: 0 5px 5px;
	padding: 0;
}

.recent-block .ico-list .popup {
	padding: 5px;
}

.recent-block .ico-list [class^='icon-'],
.recent-block .ico-list [class*=' icon-'] {
	font-size: 20px;
}

.recent-block .ico-list .hover {
	display: none;
}

.recent-block .ico-list img {
	display: inline-block;
}

/* image slider styles */

.image-slide {
	position: relative;
	height: 100%;
	margin-left: -15px;
	margin-right: -15px;
}

.image-slide .controls {
	position: absolute;
	bottom: 0;
	z-index: 9;
	right: 0;
}

.image-slide .controls a {
	background: #b0a377;
	color: #fff;
	width: 50px;
	height: 50px;
	float: left;
	text-align: center;
	line-height: 50px;
	font-size: 14px;
	-webkit-transition: background linear .3s;
	transition: background linear .3s;
}

.image-slide .controls a:hover {
	background: #c3b997;
}

.image-slide .controls a.btn-prev {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.image-slide .controls a.btn-next {
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.image-slide .slide {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
}

.image-slide .slide.active {
	z-index: 2;
}

.image-slide.not-enough-slides a.btn-prev,
.image-slide.not-enough-slides a.btn-next {
	visibility: hidden;
}

/* global popup styels */

.popup {
	-webkit-transform: translate(-50%, 15px);
	-ms-transform: translate(-50%, 15px);
	transform: translate(-50%, 15px);
	width: 100px;
	position: absolute;
	left: 50%;
	top: 100%;
	border-radius: 7px;
	background: #b0a377;
	color: #fff;
	padding: 2px 5px;
	text-align: center;
	text-transform: uppercase;
	display: none;
	z-index: 999;
	font-size: 12px;
}

.popup:before {
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 7px solid #b0a377;
	content: '';
	position: absolute;
	left: 50%;
	bottom: 100%;
}

.pop-opener {
	position: relative;
}

.pop-opener:hover [class^='icon-'],
.pop-opener:hover [class*=' icon-'] {
	color: #b0a377;
}

.pop-opener:hover .popup {
	display: block;
}

.recent-block .popup {
	top: auto;
	bottom: 100%;
	margin-bottom: 25px;
}

.recent-block .popup:before {
	border-bottom: 0;
	border-top: 7px solid #474d4b;
	bottom: auto;
	top: 100%;
}

/* styles for popup within article */

.article .pop-opener:hover [class^='icon-'],
.article .pop-opener:hover [class*=' icon-'] {
	color: #474d4b;
}

.article .popup {
	background: #474d4b;
}

.article .popup:before {
	border-bottom-color: #474d4b;
}

/* comments styles */

.comment-slot.v-middle .thumb,
.comment-slot.v-middle .text {
	vertical-align: middle;
}

.comment-slot .thumb {
	display: inline-block;
	vertical-align: top;
	width: 50px;
	margin-right: 15px;
	overflow: hidden;
	border-radius: 50px;
}

.comment-slot .thumb img {
	border-radius: 100%;
}

.comment-slot .name {
	display: block;
	font-size: 1.143em;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.comment-slot .name a {
	color: #5c5e62;
}

.comment-slot .name a:hover {
	color: #474d4b;
}

.comment-slot .text {
	display: inline-block;
	vertical-align: middle;
	width: calc(100% - 70px);
}

/* main comment styles */

.comments {
	padding: 30px 15px;
	border: 1px solid #f8f8f8;
	box-shadow: 0 0 1px 1px rgba(1, 2, 2, .1);
}

.comments .comment-head {
	overflow: hidden;
	margin: 0 0 15px;
}

.comments .comment-head .left {
	float: left;
	width: 50%;
	padding-right: 10px;
}

.comments .comment-head .meta {
	font-style: italic;
}

.comments .comment-head .name {
	display: block;
	font-weight: 400;
	margin-bottom: 5px;
}

.comments .comment-head .right {
	width: 50%;
	text-align: right;
	float: left;
	padding-top: 5px;
}

.comments p {
	margin: 0 0 30px;
}

.comments.reply {
	padding: 0;
	box-shadow: none;
	border: 0;
	padding: 40px 0 0 100px;
}

.comments.reply .comment-slot .comment-detail {
	max-width: 570px;
}

.comments .comment-slot {
	overflow: hidden;
	border-bottom: 0;
	padding: 20px 0 15px;
}

.comments .comment-slot .text:hover a {
	color: #b0a377;
}

.comments .comment-slot .text:hover .star-rating {
	color: #b0a377;
}

.comments .comment-slot:first-child {
	padding-top: 0;
}

.comments .comment-slot .thumb {
	width: 75px;
	padding-right: 20px;
	margin-right: 0;
	margin-top: 10px;
}

.comments .comment-slot .text {
	width: calc(100% - 80px);
}

.comments .comment-slot .comment-detail {
	max-width: 670px;
	border-bottom: 1px solid #e2e2e2;
	padding: 0 20px 30px 0;
}

.comments .link-holder {
	font-style: italic;
}

.comments .link-holder a {
	color: #5c5e62;
}

.comments .link-holder a:hover {
	color: #474d4b;
}

.comments .comment-holder {
	margin: 0 0 20px;
}

.comments .link-more {
	font-size: 1.143em;
	font-weight: 700;
	padding-top: 2px;
	margin-left: 175px;
}

.comments .link-more a {
	color: #5c5e62;
}

.comments .link-more a:hover {
	color: #b0a377;
}

/* star rating styles */

.star-rating > span {
	display: inline-block;
	vertical-align: middle;
	margin-right: -2px;
}

.star-rating > span:hover {
	cursor: pointer;
	color: #b0a377;
}

.star-rating [class^='icon-'],
.star-rating [class*=' icon-'] {
	font-size: 15px;
	line-height: 1em;
}

.star-rating .disable {
	color: #adadad;
}

/*------------------------------------------------------------------
10. Social networks / .social-networks
-------------------------------------------------------------------*/

/* social network styles */

.social-networks li {
	display: inline-block;
	vertical-align: middle;
	padding: 0 5px;
}

.social-networks a span {
	-webkit-transition: color .2s linear;
	transition: color .2s linear;
}

.social-networks a.facebook:hover {
	color: #136ad5;
}

.social-networks a.twitter:hover {
	color: #00aced;
}

.social-networks a.google:hover {
	color: #dd4b39;
}

.social-networks a.pin:hover {
	color: #cb2027;
}

.social-networks .icon-twitter:hover {
	color: #00aced;
}

.social-networks .icon-facebook:hover {
	color: #136ad5;
}

.social-networks .icon-google-plus:hover {
	color: #dd4b39;
}

.social-networks .icon-linkedin:hover {
	color: #007bb5;
}

/* global seperator styles */

.seperator {
	border-bottom: 1px solid #e2e2e2;
	position: relative;
	padding-bottom: 30px;
}

.seperator:before {
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	content: '';
	position: absolute;
	left: 50%;
	bottom: 0;
	width: 120px;
	height: 4px;
	background: #e2e2e2;
}

/* booking form styles */

.booking-form .form-holder,
.twocol-form .form-holder {
	box-shadow: .5px .9px 1px rgba(1, 2, 2, .1);
	background: #f8f8f8;
	padding: 20px 10px;
	margin-bottom: 45px;
}

.booking-form .form-holder .form-control,
.twocol-form .form-holder .form-control {
	border-color: #dfdfdf;
	background: transparent;
	color: #5c5e62;
	height: 40px;
}

.booking-form .form-holder .form-control::-webkit-input-placeholder,
.twocol-form .form-holder .form-control::-webkit-input-placeholder {
	font-style: italic;
}

.booking-form .form-holder .form-control:-moz-placeholder,
.twocol-form .form-holder .form-control:-moz-placeholder {
	font-style: italic;
}

.booking-form .form-holder .form-control::-moz-placeholder,
.twocol-form .form-holder .form-control::-moz-placeholder {
	font-style: italic;
}

.booking-form .form-holder .form-control:-ms-input-placeholder,
.twocol-form .form-holder .form-control:-ms-input-placeholder {
	font-style: italic;
}

.booking-form .form-holder .form-control:focus,
.booking-form .form-holder .form-control:active,
.twocol-form .form-holder .form-control:focus,
.twocol-form .form-holder .form-control:active {
	border-color: #c5c5c5;
}

.booking-form .col-md-6:last-child .form-holder,
.twocol-form .col-md-6:last-child .form-holder {
	margin-bottom: 0;
}

.booking-form textarea.form-control,
.twocol-form textarea.form-control {
	min-height: 190px !important;
	resize: none;
}

.booking-form h2,
.twocol-form h2 {
	color: #5c5e62;
	padding-bottom: 30px;
	margin-bottom: 25px;
	border-bottom: 1px solid rgba(125, 125, 125, .2);
}

.booking-form label,
.twocol-form label {
	margin-bottom: 20px;
	text-transform: capitalize;
}

.booking-form .hold,
.twocol-form .hold {
	margin-bottom: 20px;
}

.booking-form .option,
.twocol-form .option {
	padding-bottom: 20px;
	padding-top: 10px;
}

.booking-form .option li,
.twocol-form .option li {
	overflow: hidden;
	margin-bottom: 5px;
}

.booking-form .option label,
.twocol-form .option label {
	margin-bottom: 0;
}

.booking-form .option .info-hold,
.twocol-form .option .info-hold {
	padding-left: 30px;
}

.booking-form .payment-option,
.twocol-form .payment-option {
	display: none;
	float: none;
	padding-left: 30px;
}

.booking-form .order-block,
.twocol-form .order-block {
	padding-top: 25px;
}

.booking-form [type='submit'].btn,
.twocol-form [type='submit'].btn {
	width: 100%;
	padding: 10px;
}

/* product table styles */

.product-table {
	border: 2px solid #dfdfdf;
	width: 100%;
	margin-bottom: 60px;
}

.product-table td,
.product-table th {
	padding: 10px;
}

.product-table th {
	border-bottom: 1px solid rgba(125, 125, 125, .2);
}

.product-table tfoot td {
	font-weight: 700;
	border-top: 1px solid rgba(125, 125, 125, .2);
}

.product-table .amount {
	font-weight: 700;
}

.product-table .title,
.product-table time {
	display: block;
}

.product-table .title {
	font-weight: 700;
}

/* top heading box styles */

.top-box {
	font-size: 16px;
	line-height: 1.2;
	font-weight: 400;
	margin-bottom: 25px;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.top-box .holder {
	color: #5c5e62;
	display: block;
	border: 2px solid #f8f8f8;
	padding: 10px 20px;
	overflow: hidden;
	position: relative;
	-webkit-transition: border .2s linear;
	transition: border .2s linear;
}

.top-box .holder:hover .arrow {
	right: 20px;
}

.top-box .left {
	display: none;
}

.top-box .right {
	float: left;
}

.top-box .arrow {
	position: absolute;
	right: 35px;
	top: 50%;
	-webkit-transform: translate(0, -50%) rotate(270deg);
	-ms-transform: translate(0, -50%) rotate(270deg);
	transform: translate(0, -50%) rotate(270deg);
	-webkit-transition: right .2s linear;
	transition: right .2s linear;
}

.top-box .arrow:before {
	content: '\e906';
	font: 18px/1 'icomoon';
}

/* featured content styles */

.featured-content {
	position: relative;
	overflow: hidden;
	text-align: center;
}

.featured-content.feature-small {
	background: #474d4b;
	color: #fff;
}

.featured-content.feature-small .column {
	min-height: 0;
}

.featured-content.feature-small .bg-stretch {
	z-index: 1;
}

.featured-content.feature-small .btn-lg {
	min-width: 260px;
}

.featured-content .bg-stretch {
	z-index: 1;
}

.featured-content .row {
	overflow: hidden;
	margin: 0;
}

.featured-content .row:nth-child(even) .image {
	float: right;
}

.featured-content .article {
	padding: 20px;
}

.featured-content .container-fluid {
	padding: 0;
}

/* progress bar holder styles */

.bar-holder {
	margin-bottom: -5px;
}

.bar-holder .title {
	font-size: 1.143em;
	line-height: 1;
	display: block;
	margin-bottom: 25px;
}

/* progressbar */

.progress {
	height: 20px;
	background: #414141;
	box-shadow: none;
	margin-bottom: 30px;
	overflow: visible;
}

.progress .progress-bar {
	position: relative;
	box-shadow: none;
	background: #b0a377;
}

.progress .value {
	position: absolute;
	right: -35px;
	font-style: italic;
	font-size: 14px;
	line-height: 1.2;
	background: #474d4b;
	color: #fff;
	padding: 5px;
	min-width: 50px;
	top: -40px;
}

.progress .value:before {
	content: '';
	position: absolute;
	left: 50%;
	top: 100%;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 8px 8px 0 0;
	margin-left: -11px;
	border-color: #474d4b transparent transparent;
}

.progress-holder {
	padding-top: 45px;
	margin-bottom: -10px;
}

/* block quotation styles */

.block-quotation {
	padding: 15px 10px;
	color: #fff;
	position: relative;
	text-align: center;
	z-index: 1;
	background: #b0a377;
	margin: 40px 0;
}

.block-quotation.no-bottom {
	margin-bottom: 0;
}

.block-quotation:after {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(176, 163, 119, .69);
	z-index: -1;
}

.block-quotation img {
	opacity: .52;
}

/*------------------------------------------------------------------
11. Partner block / .partner-block
-------------------------------------------------------------------*/

/* partner block styles */

.partner-block {
	padding: 60px 0;
	text-align: center;
	background: #fafafa;
}

.partner-block .partner {
	margin-top: 45px;
	padding: 0 15px;
	padding-bottom: 1px;
}

.partner-block a {
	display: block;
	height: 100%;
	border-bottom: 1px solid #dedede;
	padding-bottom: 45px;
	position: relative;
	min-height: 117px;
}

.partner-block a:before {
	content: '';
	background: #474d4b;
	left: 50%;
	bottom: -1px;
	width: 0;
	height: 1px;
	position: absolute;
	-webkit-transition: width .2s linear, left .2s linear;
	transition: width .2s linear, left .2s linear;
}

.partner-block a .hover {
	display: none;
}

.partner-block a img {
	-webkit-transition: opacity .2s linear;
	transition: opacity .2s linear;
	display: inline-block;
}

.partner-block a:hover img {
	display: none;
}

.partner-block a:hover img.hover {
	display: inline-block;
}

.partner-block a:hover:before {
	left: 0;
	width: 100%;
}

/* article with icon */

.ico-article {
	margin-top: 30px;
	line-height: 1.714;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
}

.ico-article .ico-holder {
	margin: 0 0 13px;
	min-height: 35px;
}

/* step block styles */

.step-block {
	padding: 45px 0;
	color: #fff;
	position: relative;
	overflow: hidden;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.step-block .container {
	position: relative;
	z-index: 2;
}

/* content intro styles */

.content-intro {
	padding-bottom: 60px;
}

.content-intro .text-holder {
	padding-top: 60px;
	margin-bottom: 45px;
	text-align: center;
}

.content-intro .text-holder .btn-holder {
	margin-top: 45px;
}

.content-intro .map-col {
	position: relative;
	z-index: 1;
	text-align: center;
	margin-top: 20px;
}

.content-intro .map-col .holder {
	background: #474d4b;
	border: 10px solid #2f3231;
	padding: 10px;
	max-width: 400px;
	margin: 0 auto;
}

.content-intro .map-holder {
	margin: 0 0 20px;
	border: 0;
}

.content-intro .info {
	color: #fff;
}

.content-intro .info strong {
	display: block;
	font-size: 18px;
	font-weight: 700;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.content-intro .info .sub {
	font-style: italic;
}

/* trip info styles */

.trip-info {
	background: #fafafa;
}

.trip-info .height:first-child {
	padding-left: 0;
	padding-right: 0;
}

.trip-info .text-col {
	white-space: nowrap;
	text-align: center;
}

.trip-info .text-col:after {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	margin-right: -.25em;
}

.trip-info .text-col .holder {
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	white-space: normal;
	max-width: 755px;
	padding: 30px 0;
}

.trip-info h1 {
	margin-bottom: 35px;
}

.trip-info .price {
	font-size: 1.7124em;
	line-height: 1.33333em;
	font-weight: 300;
	font-style: italic;
	margin: 0 0 30px;
}

.trip-info .price strong {
	font-style: normal;
	font-weight: 700;
	display: block;
	color: #474d4b;
	margin-top: 20px;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.trip-info .description {
	margin-bottom: 30px;
}

.trip-info .reviews-info .star-rating {
	color: #474d4b;
}

.trip-info .social-share {
	display: table;
	width: 100%;
	table-layout: fixed;
}

.trip-info .social-share li {
	display: table-cell;
}

.trip-info .social-share .ico {
	display: inline-block;
	vertical-align: middle;
	font-size: 18px;
}

.trip-info .btn-holder {
	margin: 0 0 45px;
}

.reviews-slot .name {
	font-size: 1.143em;
}

#tour-slide {
	height: 100%;
}

#tour-slide .owl-wrapper-outer,
#tour-slide .owl-item,
#tour-slide .owl-wrapper {
	height: 100%;
}

#tour-slide .owl-controls {
	margin: 0;
}

#tour-slide .slide {
	min-height: 320px;
}

#tour-slide .owl-buttons {
	position: absolute;
	bottom: 0;
	right: 0;
}

#tour-slide .owl-prev,
#tour-slide .owl-next {
	float: left;
	background: #252525;
	color: #fff;
	width: 50px;
	height: 50px;
	float: left;
	text-align: center;
	line-height: 50px;
	font-size: 14px;
	-webkit-transition: background linear .3s;
	transition: background linear .3s;
	border: 0;
	font-size: 0;
	z-index: 9;
	position: relative;
	border-radius: 0;
	margin: 0;
	opacity: 1;
}

#tour-slide .owl-prev:after,
#tour-slide .owl-next:after {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
	content: '\e906';
	font: 14px/50px 'icomoon';
	display: block;
	text-align: center;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
}

#tour-slide .owl-prev:hover,
#tour-slide .owl-next:hover {
	background: #b0a377;
}

#tour-slide .owl-prev:after {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

/* trip detail styles */

.trip-detail {
	padding-top: 45px;
	padding-bottom: 45px;
}

.trip-detail .img-article {
	max-width: none;
	margin-bottom: 30px;
}

.trip-detail .img-article .text-block {
	padding: 25px 20px 15px;
	font-size: .857em;
	font-weight: 400;
	letter-spacing: .02em;
}

.trip-detail .img-article h3 {
	font-size: 1.333em;
	font-weight: 400;
	margin-bottom: 10px;
}

.trip-detail .img-article p {
	margin: 0;
}

.trip-detail .comments {
	padding-bottom: 45px;
}

.trip-detail .comments .thumb {
	width: 80px;
}

.trip-detail .comments .text {
	width: auto;
}

.trip-detail .comments .des {
	border-top-color: #9d9d9d;
}

.trip-detail .comments .link-more {
	margin: 0;
	letter-spacing: .04em;
}

.trip-detail .comments .comment-holder {
	margin-bottom: 30px;
}

.trip-detail .header-box {
	margin-bottom: 20px;
}

.trip-detail .gallery-list {
	margin: 0 -15px;
}

.trip-detail .gallery-list.has-center .title {
	padding-bottom: 10px;
	margin-bottom: 15px;
	letter-spacing: .03em;
}

.trip-detail .gallery-list li {
	width: auto;
	margin: 0 auto 30px;
	float: none;
	max-width: 400px;
	padding: 0 15px;
}

.trip-detail .gallery-list li img {
	width: 100%;
}

.trip-detail [class^='col-'],
.trip-detail [class*=' col-'] {
	margin-bottom: 30px;
}

.trip-detail .nav-wrap {
	background: #b0a377;
	padding: 20px 0;
}

.trip-detail .img-list li {
	display: inline-block;
	vertical-align: middle;
	padding: 0 20px;
}

.trip-detail .tab-content {
	padding: 30px 0;
	font-size: 14px;
	line-height: 24px;
}

.trip-detail .detail {
	padding: 20px 10px 10px;
	background: #f8f8f8;
	box-shadow: 1px 1px 1px rgba(1, 2, 2, .1);
}

.trip-detail .detail p {
	margin: 0 0 25px;
}

.trip-detail .detail .reviews-slot {
	padding-top: 25px;
	border-top: 1px solid #e6e6e6;
	margin-top: 15px;
	padding-bottom: 5px;
}

.trip-detail .detail .img-list {
	padding-bottom: 10px;
}

.trip-detail .text-box {
	padding-left: 55px;
	padding-bottom: 35px;
}

.trip-detail .text-box.not-included:before {
	content: '\e947';
}

.trip-detail .text-box:before {
	font: 40px/1 'icomoon';
	position: absolute;
	left: 10px;
	top: 10px;
	content: '\e957';
	color: #adadad;
}

.trip-detail .accomodation-block .text-box {
	padding-right: 30px;
	padding-bottom: 45px;
}

.trip-detail .accomodation-block .text-box .sub-title {
	margin-bottom: 30px;
}

/* reviews info styles */

.reviews-info {
	margin-bottom: 45px;
}

.reviews-info > li {
	padding: 0 0 5px;
	position: relative;
	margin: 0 0 15px 0;
}

.reviews-info > li:before {
	content: '';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 1px;
	background: #e2e2e2;
}

.reviews-info .info-left {
	width: 50%;
	padding-right: 10px;
	float: left;
	text-align: left;
}

.reviews-info .info-right {
	float: right;
	max-width: 50%;
	text-align: right;
}

.reviews-info .title {
	display: block;
}

.reviews-info .ico-list {
	margin-left: -5px;
	margin-right: -5px;
}

.reviews-info .ico-list li {
	display: inline-block;
	vertical-align: middle;
	padding: 0 5px;
}

.question-select {
	margin-bottom: 20px;
}

.question-select .jcf-select {
	border: 1px solid #f8f8f8;
	display: block;
	padding: 10px;
	margin: 0;
}

.question-select .jcf-select:hover .jcf-select-text {
	color: #b0a377;
}

.question-select .jcf-select .jcf-select-text {
	font-size: 14px;
	line-height: 24px;
	font-weight: normal;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	color: #5c5e62;
	margin-right: 30px;
	letter-spacing: 0;
	text-transform: none;
}

.question-select .jcf-select .jcf-select-opener {
	width: 31px;
}

.question-select .jcf-select .jcf-select-opener:after {
	color: #9d9d9d;
	left: 0;
	right: auto;
	font-size: 15px;
	line-height: 1;
	margin-top: -7px;
}

.question-select .nav {
	display: none;
}

/* header box styles */

.header-box {
	padding: 10px;
	border: 1px solid #f8f8f8;
	display: block;
	margin: 0 0 30px;
	overflow: hidden;
	font-weight: 400;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.header-box:hover {
	color: #b0a377;
}

.header-box:hover .star-rating {
	color: #474d4b;
}

.header-box .title {
	font-weight: 400;
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
}

.header-box .star-rating {
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.header-box .value {
	display: inline-block;
	vertical-align: middle;
}

.header-box .link-right {
	float: right;
	max-width: 40%;
}

.header-box a:hover {
	color: #b0a377;
}

/* tab container styles */

.tab-container {
	position: relative;
}

.tab-container .tab-pane {
	display: block !important;
	position: absolute;
	top: -9999px;
	left: -9999px;
}

.tab-container .tab-pane.active {
	position: static;
}

.nav-wrap {
	background: #252525;
	z-index: 3;
}

/* tab styles */

.nav-tabs {
	font-size: 1.286em;
	padding: 10px 0;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.nav-tabs > li {
	border: 0;
	float: none;
	display: inline-block;
	margin: 0;
	padding: 10px 5px;
	width: 49%;
}

.nav-tabs > li.active a,
.nav-tabs > li:focus a {
	background: none;
	border: 0;
	color: #b0a377;
	margin: 0;
}

.nav-tabs > li.active a:hover,
.nav-tabs > li.active a.active,
.nav-tabs > li.active a:focus,
.nav-tabs > li:focus a:hover,
.nav-tabs > li:focus a.active,
.nav-tabs > li:focus a:focus {
	background: none;
	margin: 0;
	border: 0;
	color: #b0a377;
}

.nav-tabs > li > a {
	background: none;
	border: 0;
	margin: 0;
	color: #9d9d9d;
	padding: 0;
	display: inline-block;
}

.nav-tabs > li > a:hover {
	background: none;
	color: #b0a377;
}

/* tabs for dropdown */

.has-mega-dropdown .nav-hover {
	display: inline-block;
	vertical-align: top;
	width: 150px;
	padding-right: 15px;
	font-size: inherit;
}

.has-mega-dropdown .nav-hover li {
	margin: 0 0 10px;
	padding: 0;
}

.has-mega-dropdown .nav-hover li a {
	padding: 0;
}

.has-mega-dropdown .nav-hover li a:focus {
	background: none;
}

.has-mega-dropdown .tab-content {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 155px);
}

.has-mega-dropdown .tab-content .header-link {
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
}

.dropdown-menu {
	top: 99%;
}

.no-icons .navbar-nav .mega-center.has-mega-dropdown.mega-md .dropdown-menu {
	right: 0;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .dropdown-menu {
	left: 0;
	right: 0;
	width: auto;
	box-shadow: none;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .drop-wrap {
	max-width: 800px;
	margin: 0 0 0 auto;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .nav-hover li {
	margin-bottom: 20px;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .tab-content .header-link li {
	padding: 0;
	margin-bottom: 20px;
}

/*------------------------------------------------------------------
12. Table / .table
-------------------------------------------------------------------*/

/* table container styles */

.table-container .date-text {
	display: block;
	font-size: 16px;
	margin-bottom: 5px;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.table-container .sub-text {
	display: block;
	font-weight: 400;
	font-style: italic;
	letter-spacing: .09em;
}

.table-container .btn {
	float: right;
}

/* stripped table styles */

.table-striped tbody tr:nth-child(even) {
	background: #f8f8f8;
}

.table-striped tbody tr:nth-child(odd) {
	background: transparent;
}

.table-hover tbody tr {
	-webkit-transition: background .2s linear;
	transition: background .2s linear;
}

.table-hover tbody tr:hover {
	background: #f8f8f8;
}

/* global table styles */

table.table {
	font-size: .9em;
	min-width: 725px;
}

table.table tbody tr td,
table.table thead tr td {
	vertical-align: middle;
}

table.table .btn {
	min-width: 1px;
	font-size: 1em;
}

table.table tbody td:last-child .cell {
	border-right: 1px solid #f8f8f8;
}

table.table tbody tr {
	border-color: #fff;
	border-style: solid;
	border-width: 15px 0;
	background: transparent;
}

table.table tbody tr td:first-child .cell {
	border-left: 1px solid #f8f8f8;
}

table.table tbody tr td .cell {
	border: solid #f8f8f8;
	border-width: 1px 0;
}

table.table > thead > tr > th {
	border: 0;
	padding: 10px 5px 15px;
}

table.table > tbody > tr {
	background: none;
	font-weight: 500;
}

table.table > tbody > tr:first-child td {
	border-top: 0;
}

table.table > tbody > tr > td {
	padding: 0;
}

table.table > tbody > tr .middle {
	padding: 5px;
	min-height: 65px;
}

.table-responsive > .table > thead > tr > th,
.table-responsive > .table > thead > tr > td,
.table-responsive > .table > tbody > tr > th,
.table-responsive > .table > tbody > tr > td,
.table-responsive > .table > tfoot > tr > th,
.table-responsive > .table > tfoot > tr > td {
	white-space: normal;
}

.table .cell {
	display: table;
	width: 100%;
	min-height: 65px;
}

.table .cell .middle {
	display: table-cell;
	vertical-align: middle;
}

/* detail accordion styles */

.detail-accordion {
	margin: 0;
	padding: 0;
	list-style-type: none;
	counter-reset: ol-counter;
}

.detail-accordion > li {
	position: relative;
	margin-bottom: 5px;
	border: 1px solid #f8f8f8;
}

.detail-accordion > li:before {
	content: counter(ol-counter);
	counter-increment: ol-counter;
	position: absolute;
	top: 6px;
	left: 12px;
	width: 43px;
	line-height: 55px;
	font-size: 16px;
	text-align: center;
}

.detail-accordion > li:after {
	border: 2px solid #e2e2e2;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	content: '';
	left: 16px;
	height: 35px;
	width: 35px;
	position: absolute;
	top: 16px;
}

.detail-accordion > li:hover:after {
	border-color: #5c5e62;
}

.detail-accordion > li:not(.active) > a:after {
	transform: rotate(270deg);
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
}

.detail-accordion > li.active {
	box-shadow: 1px 1px 1px rgba(1, 2, 2, .07);
}

.detail-accordion > li.active:after {
	border-color: #5c5e62;
}

.detail-accordion > li.active > a:after {
	color: #9d9d9d;
}

.detail-accordion > li.active > a:before {
	border-color: #9d9d9d;
}

.detail-accordion > li > a {
	display: block;
	padding: 10px 20px 10px 80px;
	position: relative;
	-webkit-transition: all linear .2s;
	transition: all linear .2s;
}

.detail-accordion > li > a:after {
	content: '\e906';
	font: 18px/10px 'icomoon';
	position: absolute;
	right: 20px;
	top: 50%;
	color: #e2e2e2;
	margin-top: -5px;
	-webkit-transition: all linear .2s;
	transition: all linear .2s;
}

.detail-accordion > li > a span {
	font-style: italic;
}

.detail-accordion .title {
	display: block;
	font-size: 1.143em;
	margin-bottom: -5px;
	font-weight: 400;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.detail-accordion .slide {
	padding: 0 20px;
}

.detail-accordion .slide-holder {
	padding: 25px 0 20px;
	border-top: 1px solid #e1e1e1;
	margin-left: 60px;
	margin-top: 10px;
}

/* fancybox */

.fancybox-overlay {
	background: rgba(0, 0, 0, .87);
	min-width: 320px;
}

.fancybox-skin {
	border-radius: 0;
	padding: 20px !important;
	background: none;
	box-shadow: none !important;
}

.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
	width: 30px;
	height: 30px;
	border-radius: 100%;
	background: rgba(0, 0, 0, .6);
	box-shadow: 0 0 1px rgba(0, 0, 0, .4);
}

.fancybox-close:after,
.fancybox-prev span:after,
.fancybox-next span:after {
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	position: absolute;
	left: 50%;
	top: 50%;
	font: 8px/30px 'icomoon';
}

.fancybox-prev span,
.fancybox-next span {
	display: block;
	vertical-align: middle;
	background: none;
	box-shadow: none;
	visibility: visible;
	position: relative;
	left: auto;
	right: auto;
	width: 30px;
	height: 30px;
	margin: 0;
	top: 0;
}

.fancybox-close {
	top: 20px;
	right: 20px;
	border-radius: 0;
	position: absolute;
	color: #fff;
}

.fancybox-close:hover {
	background: #000;
}

.fancybox-close:hover:after {
	color: #fff;
}

.fancybox-close:after {
	content: '\e914';
	font-size: 14px;
}

.fancybox-nav {
	width: 30px;
	height: 30px;
	top: 50%;
	margin-top: -15px;
	background: rgba(0, 0, 0, .6);
	text-align: center;
	color: #fff;
}

.fancybox-nav:hover {
	background: #000;
	color: #fff;
}

.fancybox-prev span:after,
.fancybox-next span:after {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	content: '\e906';
	text-align: center;
	left: 3px;
	top: 0;
	font-size: 20px;
}

.fancybox-next span:after {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
	left: auto;
	right: 3px;
}

.fancybox-title {
	right: 0;
	left: 0;
	margin: 0 20px -9px;
	width: auto !important;
}

.fancybox-title .child {
	margin: 0;
	display: block;
	border-radius: 0;
	padding: 5px 20px;
	background: #252525;
}

/*------------------------------------------------------------------
13. Carousel
-------------------------------------------------------------------*/

/* carousel styles */

.carousel.carousel-fade .carousel-inner .item {
	opacity: 0;
	-webkit-transition-property: opacity;
	transition-property: opacity;
}

.carousel.carousel-fade .carousel-inner .item,
.carousel.carousel-fade .carousel-inner .active.left,
.carousel.carousel-fade .carousel-inner .active.right {
	opacity: 0;
}

.carousel.carousel-fade .carousel-inner .active,
.carousel.carousel-fade .carousel-inner .next.left,
.carousel.carousel-fade .carousel-inner .prev.right {
	opacity: 1;
	z-index: 2;
}

.carousel.carousel-fade .carousel-inner .next,
.carousel.carousel-fade .carousel-inner .prev,
.carousel.carousel-fade .carousel-inner .active.left,
.carousel.carousel-fade .carousel-inner .active.right {
	left: 0;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.carousel .carousel-control {
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	background: #252525;
	width: 30px;
	height: 30px;
	top: 50%;
	opacity: 1;
	font-size: 8px;
	line-height: 30px;
	text-shadow: none;
	z-index: 2;
}

.carousel .carousel-control span {
	display: inline-block;
}

.carousel .carousel-control.left span {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.carousel .carousel-control.right span {
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.carousel .carousel-control:hover {
	background: #b0a377;
}

/* content list styles */

.content-list.tick-list li:before {
	content: '\e962';
}

.content-list.cross-list li:before {
	content: '\e914';
}

.content-list li {
	padding-left: 35px;
	position: relative;
	margin-bottom: 5px;
}

.content-list li:before {
	position: absolute;
	left: 0;
	top: 7px;
	font: 16px/16px 'icomoon';
	color: #adadad;
}

/* styles for review blocks */

.reviews-slot.v-middle .thumb,
.reviews-slot.v-middle .text {
	display: inline-block;
	vertical-align: middle;
}

.reviews-slot.v-middle .thumb {
	width: 75px;
}

.reviews-slot.v-middle .text {
	width: calc(100% - 82px);
	letter-spacing: .04em;
}

.reviews-slot .name a:hover {
	color: #b0a377;
}

.reviews-body {
	background: #f8f8f8;
}

.reviews-body .comment-slot {
	border-bottom: 1px solid rgba(157, 157, 157, .15);
	padding-bottom: 30px;
	padding-top: 30px;
}

/* range slider styles and override for ui widget */

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
	border-radius: 0;
}

.ui-widget-content {
	background: #9d9d9d;
}

.ui-slider-horizontal {
	height: 6px;
	border: none;
	margin-top: 6px;
}

.ui-slider-horizontal .ui-widget-header {
	background: #9d9d9d;
}

.ui-slider-horizontal .ui-slider-handle {
	height: 18px;
	width: 6px;
	border: none;
	margin-left: 0;
	outline: none;
	background: #9d9d9d;
	top: -6px;
}

.ui-slider-horizontal .ui-slider-handle.ui-state-active {
	background: #9d9d9d;
}

.price-input {
	text-align: center;
	padding: 0;
	margin-top: 10px;
	color: #5c5e62;
}

/*------------------------------------------------------------------
14. Blog
-------------------------------------------------------------------*/

/*  blog list page */

.blog-full-width {
	padding-bottom: 100px;
}

/* article */

.article.blog-article {
	max-width: none;
}

.article.blog-article:first-child {
	margin-top: 0;
}

.article.blog-article .img-wrap {
	overflow: hidden;
	border-radius: 5px;
}

.article.blog-article .img-wrap img {
	border-radius: 5px;
}

.article.blog-article .thumbnail .meta {
	border-bottom: 0;
	padding-bottom: 15px;
}

.article.blog-article:hover .heading {
	color: #b0a377;
}

.article.blog-article:hover .heading h3 a {
	color: #b0a377;
}

.article.blog-article:hover .link-view a {
	color: #b0a377;
}

.article.blog-article:hover .star-rating .disable a,
.article.blog-article:hover .star-rating .disable span {
	color: #adadad;
}

.article.blog-article:hover .star-rating a,
.article.blog-article:hover .star-rating span {
	color: #b0a377;
}

.article.blog-article footer {
	padding-top: 0;
	margin-top: 0;
	border-top: 0;
	padding-bottom: 15px;
}

.article.blog-article .image-slide {
	height: auto;
}

.article.blog-article .description {
	padding: 10px;
	border: 1px solid #f8f8f8;
}

.article.blog-article .footer-sub {
	margin: 0 -10px;
}

.article.blog-article .footer-sub > * {
	display: inline-block;
	vertical-align: top;
	padding: 0 10px;
}

.article.blog-article .footer-sub a:hover {
	color: #b0a377;
}

.article.blog-article .star-rating {
	padding-left: 0;
}

.article.blog-article .star-rating:before {
	display: none;
}

/* blog list  */

.blog-list.list-view .article.blog-article .description {
	border: 0;
	padding: 0;
	overflow: hidden;
}

.blog-list .article.blog-article .thumbnail {
	border: 1px solid #f8f8f8;
	box-shadow: none;
}

.blog-holder {
	margin-bottom: 45px;
}

.blog-holder.no-pagination {
	margin-bottom: 0;
}

/* blog single page */

.blog-single .img-wrap {
	margin: 0 0 30px;
	overflow: hidden;
	border-radius: 5px;
}

.blog-single .img-wrap img {
	width: 100%;
}

.blog-single h1 {
	color: #5c5e62;
}

.blog-single .description {
	border: 1px solid #f8f8f8;
	padding: 30px 10px;
	margin: 0 0 30px;
}

.blog-single .description p {
	margin: 0 0 25px;
}

.blog-single .block-quotation p {
	margin: 0;
}

.blog-single .header-box {
	padding: 15px 25px 20px;
}

.blog-single .meta-article .footer-sub {
	display: inline-block;
}

.blog-single .meta-article a:hover {
	color: #b0a377;
}

/*------------------------------------------------------------------
15. Search form / .search-form
-------------------------------------------------------------------*/

/* search form styles */

.search-form {
	position: absolute;
	right: 20px;
	top: 23px;
	font-size: 20px;
	z-index: 2;
	-webkit-transition: top .2s linear;
	transition: top .2s linear;
	line-height: 0;
	z-index: 999;
}

.search-form .search-opener {
	color: #9d9d9d;
	padding: 10px;
}

.search-form .ui-autocomplete {
	position: static;
	background: transparent;
	border: none;
	padding-top: 20px;
}

.search-form .ui-menu .ui-menu-item {
	padding: 5px 0;
	list-style-image: none;
	color: #fff;
	text-transform: capitalize;
	font-weight: 300;
	font-size: 20px;
	line-height: 30px;
	border: none;
	-webkit-transition: padding .2s linear;
	transition: padding .2s linear;
	cursor: pointer;
}

.search-form .ui-menu .ui-menu-item.ui-state-focus,
.search-form .ui-menu .ui-menu-item.ui-state-hover,
.search-form .ui-menu .ui-menu-item.ui-state-active {
	margin: 0;
	background: transparent;
	border: none;
	padding-left: 5px;
}

.search-wrap {
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(0, 0, 0, .87);
	position: fixed;
	z-index: 999999;
	visibility: hidden;
	opacity: 0;
	white-space: nowrap;
	text-align: center;
	overflow-y: auto;
	padding: 60px 0;
	line-height: 1.2;
	-webkit-transition: opacity .3s linear;
	transition: opacity .3s linear;
}

.search-wrap:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	margin-right: -.25em;
}

.search-wrap .form-group {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	white-space: normal;
	width: 100%;
	max-width: 1200px;
	margin-bottom: 90px;
	padding: 0 20px;
}

.search-wrap .close {
	height: 40px;
	width: 40px;
	position: absolute;
	right: 30px;
	top: 30px;
	font-size: 20px;
	line-height: 40px;
	border: 1px solid #fff;
	-webkit-transition: opacity .3s linear;
	transition: opacity .3s linear;
	opacity: 1;
	text-shadow: none;
	color: #fff;
	padding: 0;
}

.search-wrap .close:hover {
	opacity: .8;
}

.search-wrap .input-holder {
	overflow: hidden;
}

.search-wrap .form-control {
	width: 100%;
	height: 50px;
	font-size: 20px;
	line-height: 50px;
	font-weight: 300;
	color: #fff;
	border: solid #fff;
	border-width: 0 0 1px;
	background: none;
	outline: none;
	box-shadow: none;
	padding: 0;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
	font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.search-wrap .form-control::-webkit-input-placeholder {
	color: #fff;
	overflow: hidden;
	text-overflow: ellipsis;
}

.search-wrap .form-control:-moz-placeholder {
	color: #fff;
	overflow: hidden;
	text-overflow: ellipsis;
}

.search-wrap .form-control::-moz-placeholder {
	color: #fff;
	overflow: hidden;
	text-overflow: ellipsis;
}

.search-wrap .form-control:-ms-input-placeholder {
	color: #fff;
	overflow: hidden;
	text-overflow: ellipsis;
}

.search-wrap .form-control:focus {
	border-bottom-color: #fff;
}

/* styles when search is active */

.search-active .search-wrap {
	visibility: visible;
	opacity: 1;
	z-index: 99999;
	max-width: none;
}

.search-active .search-slide .search-wrap {
	max-width: 500px;
}

.search-active .navbar-default {
	z-index: 9;
}

.ui-helper-hidden-accessible {
	display: none;
}

/* trip form styles */

.trip-form {
	font-size: 1.143em;
	line-height: 1.5em;
	font-style: italic;
	font-weight: 700;
	color: #fff;
	max-width: 1170px;
	margin: 0 auto;
	box-shadow: none;
	background: #6b6957;
	border: 10px solid #414141;
	padding: 20px 10px;
	margin-top: 5%;
}

.trip-form .holder {
	padding: 0 5px;
	text-align: left;
	margin-bottom: 10px;
}

.trip-form .select-holder {
	margin: 10px 0 0;
}

.trip-form .input-group.date {
	max-width: none;
}

.trip-form-v2.trip-search-main {
	display: inline-block;
	vertical-align: top;
	margin-top: 10%;
	width: 100%;
	font-size: 14px;
	white-space: normal;
	max-width: 1010px;
}

/* trip form styles */

/* custom select styles */

.jcf-select {
	display: inline-block;
	vertical-align: top;
	position: relative;
	cursor: pointer;
}

.jcf-select.jcf-select-filter-select {
	background: #b0a377;
	box-shadow: 0 1px 1px rgba(1, 2, 2, .1);
	height: 44px;
	width: 100%;
}

.jcf-select.jcf-select-filter-select.jcf-select-bg-white {
	background: #fff;
}

.jcf-select.jcf-select-filter-select.jcf-select-bg-white .jcf-select-text {
	color: #5c5e62;
}

.jcf-select.jcf-select-filter-select.jcf-select-bg-white .jcf-select-opener:after {
	color: #5c5e62;
}

.jcf-select.jcf-select-filter-select.jcf-select-bg-gray {
	background: #f8f8f8;
}

.jcf-select.jcf-select-filter-select.jcf-select-bg-gray .jcf-select-text {
	color: #5c5e62;
}

.jcf-select.jcf-select-filter-select.jcf-select-bg-gray .jcf-select-opener:after {
	color: #5c5e62;
}

.jcf-select.jcf-select-filter-select .jcf-select-text {
	font-size: 14px;
	color: #5c5e62;
	font-weight: 700;
	line-height: 44px;
	padding-left: 15px;
	color: #fff;
	margin-right: 35px;
}

.jcf-select.jcf-select-filter-select .jcf-select-opener {
	width: 44px;
}

.jcf-select.jcf-select-filter-select .jcf-select-opener:after {
	color: #fff;
	right: 15px;
	content: '\e906';
	font-size: 14px;
}

.jcf-select.jcf-select-language .jcf-select-opener:after {
	content: '\e903';
}

.jcf-select.jcf-select-language:hover .jcf-select-opener:after {
	color: #e6e6e6;
}

.jcf-select.jcf-select-sort-select {
	background: #b0a377;
	box-shadow: none;
	text-align: left;
	height: 34px;
}

.jcf-select.jcf-select-sort-select .jcf-select-text {
	line-height: 32px;
	color: #fff;
}

.jcf-select.jcf-select-sort-select .jcf-select-opener:after {
	color: #fff;
}

.jcf-select.jcf-select-trip {
	background: rgba(37, 37, 37, .7);
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 5px 10px;
	min-width: 75px;
	color: #9d9d9d;
	width: 100%;
}

.jcf-select.jcf-select-trip .jcf-select-text {
	font-size: 14px;
	line-height: 24px;
	font-style: normal;
	color: #b0a377;
}

.jcf-select.jcf-select-trip .jcf-select-opener:after {
	right: 10px;
	color: #b0a377;
	font-size: 14px;
}

.jcf-select-trip .jcf-list .jcf-option {
	color: #9d9d9d;
	letter-spacing: .05em;
}

.jcf-select-trip.jcf-select-dark .jcf-list .jcf-option {
	color: #9d9d9d;
}

.jcf-select select {
	z-index: 1;
	left: 0;
	top: 0;
}

.jcf-select .jcf-select-text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	cursor: default;
	display: block;
	font-size: 18px;
	line-height: 26px;
	margin: 0 20px 0 0;
	color: #fff;
	letter-spacing: .05em;
	text-transform: uppercase;
}

.jcf-select .jcf-select-opener {
	position: absolute;
	text-align: center;
	width: 25px;
	bottom: 0;
	right: 0;
	top: 0;
}

.jcf-select .jcf-select-opener:after {
	content: '\e906';
	position: absolute;
	right: 0;
	top: 50%;
	font: 14px/14px 'icomoon';
	margin-top: -7px;
	color: #fff;
}

body > .jcf-select-drop {
	position: absolute;
	margin: -1px 0 0;
	z-index: 9999;
	min-width: 90px;
}

body > .jcf-select-drop.jcf-drop-flipped {
	margin: 1px 0 0;
}

.jcf-select .jcf-select-drop {
	position: absolute;
	margin-top: 0;
	z-index: 9999;
	top: 100%;
	left: -1px;
	right: -1px;
}

.jcf-select .jcf-drop-flipped {
	bottom: 100%;
	top: auto;
}

.jcf-select-drop .jcf-select-drop-content {
	border: 0;
}

.jcf-list-box {
	overflow: hidden;
	display: inline-block;
	border: 1px solid #b8c3c9;
	min-width: 200px;
	margin: 0 15px;
}

.jcf-list {
	display: inline-block;
	vertical-align: top;
	position: relative;
	background: #fff;
	font-size: 14px;
	line-height: 16px;
	width: 100%;
}

.jcf-list .jcf-list-content {
	vertical-align: top;
	display: inline-block;
	overflow: auto;
	width: 100%;
}

.jcf-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.jcf-list ul li {
	overflow: hidden;
	display: block;
}

.jcf-list ul li:first-child {
	display: none;
}

.jcf-list .jcf-overflow {
	overflow: auto;
}

.jcf-list .jcf-option {
	white-space: nowrap;
	overflow: hidden;
	cursor: default;
	display: block;
	padding: 10px;
	color: #656565;
	height: 1%;
}

.jcf-list .jcf-disabled {
	background: #fff !important;
	color: #9d9d9d !important;
}

.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
	background: #e6e6e6;
	color: #000;
}

.jcf-list .jcf-optgroup-caption {
	white-space: nowrap;
	font-weight: bold;
	display: block;
	padding: 5px 10px;
	cursor: default;
	color: #000;
}

.jcf-list .jcf-optgroup .jcf-option {
	padding-left: 30px;
}

.jcf-select-drop {
	box-shadow: 0 1px 1px 0 rgba(157, 157, 157, .5);
}

.jcf-select-drop.jcf-select-filter-select .jcf-option {
	padding-left: 15px;
	color: #fff;
}

.jcf-select-drop.jcf-select-filter-select .jcf-option.jcf-hover {
	background: #8a7d50;
}

.jcf-select-drop.jcf-select-filter-select .jcf-list {
	background: #a79867;
}

.jcf-select-drop.jcf-select-filter-select.jcf-select-bg-white .jcf-list {
	background: #fff;
}

.jcf-select-drop.jcf-select-filter-select.jcf-select-bg-white .jcf-option {
	color: #5c5e62;
}

.jcf-select-drop.jcf-select-filter-select.jcf-select-bg-white .jcf-option.jcf-hover {
	background: #e6e6e6;
}

.jcf-select-drop.jcf-select-filter-select.jcf-select-bg-gray .jcf-option {
	color: #5c5e62;
}

.jcf-select-drop.jcf-select-filter-select.jcf-select-bg-gray .jcf-option.jcf-hover {
	background: #dfdfdf;
}

.jcf-select-drop.jcf-select-filter-select.jcf-select-bg-gray .jcf-list {
	background: #f8f8f8;
}

.jcf-select-drop.jcf-select-question .jcf-select-drop-content {
	border: 1px solid #f8f8f8;
}

.jcf-select-drop.jcf-select-question .jcf-list {
	font-size: 14px;
	line-height: 16px;
}

.jcf-select-drop.jcf-select-question .jcf-list li:first-child {
	display: block;
}

.jcf-select-drop.jcf-select-question .jcf-option {
	padding: 10px;
}

.custom-checkbox input {
	display: none;
}

.custom-checkbox input[type='checkbox']:checked + .check-input:after {
	opacity: 1;
}

.custom-checkbox .check-input {
	width: 15px;
	height: 15px;
	border: 1px solid #9d9d9d;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	margin-right: 10px;
}

.custom-checkbox .check-input:after {
	content: '\e962';
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	font: 8px/13px 'icomoon';
	text-align: center;
	opacity: 0;
}

.custom-checkbox .check-label {
	display: inline-block;
	vertical-align: middle;
}

.custom-radio input {
	display: none;
}

.custom-radio input[type='radio']:checked + .check-input:after {
	opacity: 1;
}

.custom-radio .check-input {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	border: 2px solid #adadad;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	margin-right: 12px;
}

.custom-radio .check-input:after {
	content: '';
	position: absolute;
	left: 3px;
	right: 3px;
	top: 3px;
	bottom: 3px;
	background: #adadad;
	border-radius: 50%;
	text-align: center;
	opacity: 0;
}

.custom-radio .check-label {
	display: inline-block;
	vertical-align: middle;
}

.jcf-select-trip .jcf-select-drop-content {
	background: rgba(37, 37, 37, .7);
	color: #b0a377;
}

.jcf-select-trip .jcf-list {
	background: rgba(37, 37, 37, .7);
	color: #b0a377;
}

.jcf-select-trip .jcf-hover {
	background: rgba(12, 12, 12, .7);
	color: #b0a377;
}

/* subscribe form styles */

.subscribe-form .form-control {
	border: 0;
	background: #f8f8f8;
	box-shadow: none;
	font-size: 14px;
	height: 30px;
	border-radius: 0;
	font-weight: 400;
}

.subscribe-form .form-control::-webkit-input-placeholder {
	color: #9d9d9d;
}

.subscribe-form .form-control:-moz-placeholder {
	color: #9d9d9d;
}

.subscribe-form .form-control::-moz-placeholder {
	color: #9d9d9d;
}

.subscribe-form .form-control:-ms-input-placeholder {
	color: #9d9d9d;
}

.subscribe-form .form-control:focus {
	background: #ebebeb;
}

.subscribe-form .form-group {
	margin-bottom: 20px;
}

.subscribe-form .btn {
	font-size: 14px;
	min-width: 110px;
}

/*------------------------------------------------------------------
16. Comment form / .comment-form
-------------------------------------------------------------------*/

/* comment form styles */

.comment-form {
	padding: 45px 0 0;
	max-width: 765px;
}

.comment-form h3 {
	margin: 0 0 45px;
	color: #5c5e62;
}

.comment-form .form-control {
	font-style: italic;
	padding: 0 15px;
	height: 40px;
}

.comment-form .form-rate {
	position: relative;
}

.comment-form .form-rate .form-control {
	padding-right: 110px;
}

.comment-form .form-rate .star-rating {
	position: absolute;
	top: 5px;
	right: 30px;
}

.comment-form .form-rate .star-rating > span:hover {
	color: #474d4b;
}

.comment-form textarea.form-control {
	width: 100% !important;
	min-height: 190px;
	resize: none;
	padding-top: 10px;
}

.comment-form .btn {
	width: 100%;
	height: 40px;
}

.comment-form .btn-holder {
	margin-top: 15px;
}

/*------------------------------------------------------------------
17. Contact page
-------------------------------------------------------------------*/

/* map col styles */

.map-col-main {
	margin-top: 40px;
}

.contact-info {
	margin-top: 45px;
	margin-bottom: 45px;
}

.contact-info .col-sm-4 {
	margin-bottom: 30px;
}

.contact-info .tel {
	padding: 20px 5px;
	display: block;
	background: #252525;
	text-align: center;
	font-size: 24px;
	line-height: 62px;
	font-weight: 700;
	letter-spacing: 2px;
	height: 118px;
	margin-bottom: 20px;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.contact-info .tel [class^='icon-'],
.contact-info .tel [class*=' icon-'] {
	font-size: 30px;
	color: #fff;
	display: inline-block;
	vertical-align: middle;
}

.contact-info .tel a {
	color: #fff;
	display: inline-block;
	vertical-align: middle;
}

.contact-info .tel.bg-blue {
	background: #474d4b;
}

.contact-info h3 {
	color: #5c5e62;
}

/* contact form styles */

.contact-form {
	background: #f8f8f8;
	color: #5c5e62;
	padding: 15px;
	letter-spacing: .04em;
	margin-bottom: 16px;
}

.contact-form .form-group {
	margin-bottom: 20px;
}

.contact-form .form-title {
	display: block;
	font-size: 16px;
	line-height: 20px;
	padding-top: 13px;
	text-transform: capitalize;
}

.contact-form .form-control {
	background: transparent;
	border-width: 0 0 1px;
	border-color: #adadad;
	border-style: solid;
	outline: none;
	box-shadow: none;
	color: #5c5e62;
	height: 31px;
	width: 100%;
	padding: 4px 0;
}

.contact-form .form-control:focus {
	box-shadow: none;
}

.contact-form textarea.form-control {
	resize: none;
	height: 115px;
	width: 100% !important;
	min-height: 115px !important;
}

.contact-form [type='submit'].btn {
	background: transparent;
	width: 100%;
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
	padding: 14px 10px;
	border-color: #b0a377;
	color: #5c5e62;
	min-width: 1px;
}

.contact-form [type='submit'].btn:hover {
	background: #b0a377;
	color: #fff;
}

.map-holder {
	min-height: 320px;
}

.map-holder iframe {
	width: 100%;
	border: 0;
}

.contact-confirmation {
	color: #474d4b;
	padding: 20px 0;
}

.error {
	color: #474d4b;
	padding: 5px 0;
}

/* accomodation block styles */

.accomodation-block .text-box .sub-title {
	padding-bottom: 0;
	border-bottom: 0;
}

/* text box styles */

.text-box {
	box-shadow: .5px .9px 1px rgba(1, 2, 2, .07);
	padding: 10px;
	position: relative;
	border: 1px solid #f8f8f8;
	margin: 0 0 20px;
}

.text-box p {
	margin: 0 0 20px;
}

.text-box .title {
	display: block;
	font-size: 1.143em;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	line-height: 1.2;
	margin-bottom: 5px;
	padding-top: 4px;
}

.text-box .sub-title {
	display: block;
	font-style: italic;
	line-height: 1.2;
	padding-bottom: 25px;
	border-bottom: 1px solid #f8f8f8;
	margin-bottom: 25px;
}

/* load more styles */

.load-more a {
	font-size: 1.143em;
	line-height: 1.5625em;
	font-weight: 700;
	box-shadow: 0 1px 1px rgba(1, 2, 2, .1);
	background: #f8f8f8;
	display: block;
	padding: 15px;
	-webkit-transition: background linear .3s, color linear .3s;
	transition: background linear .3s, color linear .3s;
}

.load-more a:hover {
	background: #dfdfdf;
}

/* error page styles */

.error-page #main {
	padding-top: 30px;
}

.error-page #header.fixed-position .cart-holder:before {
	background: #eaeaea;
}

.error-holder {
	background-color: #6b6957;
	color: #fff;
	text-align: center;
	padding: 30px 0 45px;
	position: relative;
	overflow: hidden;
}

.error-holder .container {
	position: relative;
	z-index: 3;
}

.error-holder h1 {
	color: #fff;
	font-size: 5.714em;
	line-height: 1.3;
	margin-bottom: 30px;
	font-weight: 400;
}

.error-holder .title {
	display: block;
	font-size: 1.786em;
	line-height: 1.2;
	margin-bottom: 45px;
}

.error-holder .button-holder .btn {
	margin: 0 0 10px;
}

/* inner search form styles */

.inner-search {
	font-size: 1em;
	line-height: 1.4;
	margin: 0 auto 45px;
}

.inner-search .input-wrap {
	max-width: 570px;
	margin: 0 auto 40px;
	background: #fff;
	height: 50px;
	position: relative;
}

.inner-search [type='text'] {
	width: 100%;
	height: 100%;
	border: 1px solid transparent;
	padding: 10px 45px 10px 20px;
	color: #9d9d9d;
	font-size: 14px;
	line-height: 20px;
	outline: none;
	text-transform: uppercase;
	-webkit-transition: border .3s linear;
	transition: border .3s linear;
}

.inner-search [type='text']::-webkit-input-placeholder {
	color: #9d9d9d;
}

.inner-search [type='text']:-moz-placeholder {
	color: #9d9d9d;
}

.inner-search [type='text']::-moz-placeholder {
	color: #9d9d9d;
}

.inner-search [type='text']:-ms-input-placeholder {
	color: #9d9d9d;
}

.inner-search [type='text']:focus {
	border-color: #9d9d9d;
}

.inner-search [type='submit'] {
	position: absolute;
	background: transparent;
	border: none;
	color: #818181;
	right: 20px;
	font-size: 15px;
	top: 50%;
	padding: 0;
	margin: 2px 0 0;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.inner-search [type='submit']:hover {
	color: #000;
}

/*------------------------------------------------------------------
18. Default Page
-------------------------------------------------------------------*/

/* default page styles */

.default-page #header {
	background: #252525;
	border-bottom: 1px solid #181818;
}

.default-page .navbar-default .navbar-nav > li.has-mega-dropdown .dropdown-menu {
	border-color: #000;
}

.default-page .logo {
	border-right-color: #252525;
	padding: 0px;
}

.default-page .header-right .login-holder > a:hover,
.default-page .header-right .cart-holder > a:hover {
	color: #fff;
	opacity: .7;
}

.default-page .header-right .login-holder.active > a,
.default-page .header-right .cart-holder.active > a {
	color: #fff;
}

.inner-top {
	background: #f8f8f8;
	padding: 30px 0;
	box-shadow: 1px 1.7px 2px rgba(173, 173, 173, .35);
}

.inner-top h1 {
	margin: 0 0 15px;
	color: #5c5e62;
}

.inner-top .breadcrumbs {
	font-size: .8em;
	color: #5c5e62;
}

.inner-top .breadcrumbs a {
	color: #5c5e62;
}

.inner-top .breadcrumbs a:hover {
	color: #000;
}

.inner-main .contact-form {
	padding: 20px 10px;
	margin-bottom: 20px;
	background: #9d9d9d;
}

.inner-main .contact-form .form-title {
	padding-top: 5px;
}

.inner-main .contact-form .form-control {
	border: 1px solid transparent;
	border-bottom-color: #adadad;
	-webkit-transition: border .2s linear;
	transition: border .2s linear;
	padding: 5px;
}

.inner-main .contact-form .form-control:focus {
	border-bottom-color: #000;
}

.inner-main .contact-form .btn-holder {
	padding-top: 0;
}

.inner-main .contact-form .forgot {
	display: inline-block;
	margin-bottom: 15px;
	color: #fff;
	font-size: .8em;
}

.inner-main .contact-form .forgot:hover {
	color: #000;
}

.inner-main .contact-form [type='submit'].btn {
	padding: 8px 25px;
	width: auto;
	font-size: 14px;
}

/* special block styles */

.special-block {
	background: #474d4b;
	padding: 30px 0;
	border: 20px solid #5f6865;
	color: #fff;
}

.special-block .special-text {
	text-align: center;
}

.special-block .special-text a {
	color: #fff;
	display: inline-block;
}

/* browse block styles */

.browse-block {
	overflow: hidden;
	font-size: 1em;
}

.browse-block a {
	color: #fff;
	display: block;
	height: auto;
	-webkit-transition: background .3s linear;
	transition: background .3s linear;
}

.browse-block a span {
	display: block;
	padding: 0 10px;
	height: 100%;
	line-height: 85px;
}

.browse-block .column {
	letter-spacing: 2px;
	text-align: center;
	overflow: hidden;
}

.browse-block .column.browse-destination a {
	background: #b0a377;
}

.browse-block .column.browse-destination a:hover {
	background: #9a8c5a;
}

.browse-block .column.browse-adventures a {
	background: #474d4b;
}

.browse-block .column.browse-adventures a:hover {
	background: #2f3231;
}

/* cart holder of the page */

.cart-holder table.table {
	table-layout: initial;
}

.cart-holder table.table tbody tr .middle {
	padding-top: 20px;
	padding-bottom: 20px;
}

.cart-holder .price {
	font-weight: 700;
}

.cart-holder .delete {
	font-size: 24px;
	color: #252525;
}

.cart-holder .delete:hover {
	color: #b0a377;
}

.cart-holder .img-wrap {
	width: 85px;
	float: left;
	margin-right: 20px;
}

.cart-holder .text-wrap {
	overflow: hidden;
}

.cart-holder .product-title {
	display: block;
	font-weight: 400;
	margin-bottom: 5px;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.cart-holder .time {
	display: block;
	font-style: italic;
	font-weight: normal;
}

.cart-holder .num-hold {
	width: 150px;
	height: 32px;
	background: #f8f8f8;
	border: 1px solid #e1e1e1;
	text-align: center;
	line-height: 29px;
}

.cart-holder .num-hold .control {
	float: left;
	height: 100%;
	border-style: solid;
	border-color: #e1e1e1;
	border-width: 0 1px 0 0;
	width: 41px;
	color: #5c5e62;
	font-size: 14px;
	line-height: 31px;
}

.cart-holder .num-hold .control:hover {
	background: #e1e1e1;
}

.cart-holder .num-hold .control.plus {
	float: right;
	border-width: 0 0 0 1px;
}

.cart-holder .num-hold .val {
	padding: 0 10px;
	font-weight: 700;
}

.cart-option {
	padding-top: 15px;
	max-width: 400px;
	margin: 0 auto;
}

.cart-option .btn {
	height: 41px;
	width: 100%;
	float: none;
}

.cart-option .button-hold {
	text-align: center;
}

.cart-option .button-hold .btn {
	margin: 0 0 20px;
	padding: 10px 20px;
}

.cart-option .button-hold .btn:last-child {
	margin-bottom: 0;
}

/* coupon holder styles */

.coupon-hold {
	margin-bottom: 20px;
	display: table;
	table-layout: fixed;
	width: 100%;
}

.coupon-hold .submit-wrap {
	display: table-footer-group;
	height: 1%;
}

.coupon-hold .input-hold {
	overflow: hidden;
}

.coupon-hold .form-control {
	margin-bottom: 10px;
	height: 41px;
}

/* shorcode page styles */

.show-grid [class^='col-'] {
	background-color: rgba(176, 163, 119, .15);
	border: 1px solid #a79867;
	padding-bottom: 10px;
	padding-top: 10px;
}

/* demo font wrapper styles */

.font-demo-wrapper {
	font-size: 24px;
}

.font-demo-wrapper .glyph.fs1 {
	padding: 0 10px;
	margin-bottom: 20px;
}

.font-demo-wrapper .mls {
	font-size: 18px;
	display: inline-block;
	vertical-align: middle;
}

.font-demo-wrapper [class^='icon-'],
.font-demo-wrapper [class*=' icon-'] {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}

/*------------------------------------------------------------------
19. Footer / #footer
-------------------------------------------------------------------*/

/* footer styles */

#footer {
	/* background: #252525 url(../img/footer/footer-pattern.png); */
	display: table-footer-group;
	height: 1%;
	overflow: hidden;
}

#footer > .container {
	padding-top: 60px;
	padding-bottom: 45px;
}

#footer.fullscreen-footer {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
}

/* newsletter form styles */

.newsletter-form {
	margin: 0 0 45px;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.newsletter-form .info {
	font-style: italic;
	display: block;
	letter-spacing: .04em;
	color: #9d9d9d;
}

.newsletter-form .form-control {
	width: 100%;
	background: transparent;
	border: none;
	font-size: 18px;
	line-height: 22px;
	font-weight: 400;
	letter-spacing: 3px;
	color: #6b6957;
	padding: 0 0 6px;
	height: auto;
	box-shadow: none;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.newsletter-form .form-control::-webkit-input-placeholder {
	color: #6b6957;
}

.newsletter-form .form-control:-moz-placeholder {
	color: #6b6957;
}

.newsletter-form .form-control::-moz-placeholder {
	color: #6b6957;
}

.newsletter-form .form-control:-ms-input-placeholder {
	color: #6b6957;
}

.newsletter-form .input-holder {
	position: relative;
	padding-right: 90px;
	margin-bottom: 6px;
	border-bottom: 2px solid #6b6957;
}

.newsletter-form [type='submit'] {
	border-width: 0 0 5px;
	border-style: solid;
	border-color: transparent transparent #6b6957;
	background: transparent;
	font-size: 18px;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	line-height: 28px;
	font-weight: 400;
	color: #6b6957;
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 0 6px;
	margin: 0;
}

.footer-holder {
	margin: 0 -15px 45px;
}

/* footer navigation styles */

.footer-nav {
	letter-spacing: .02em;
	margin-bottom: 15px;
	font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.footer-nav h3 {
	font-weight: 700;
	color: #6b6957;
	text-transform: capitalize;
	cursor: default;
	position: relative;
}

.footer-nav h3:before {
	-webkit-transition: -webkit-transform .2s linear;
	transition: transform .2s linear;
	font-size: 8px;
	position: absolute;
	right: 0;
	top: 8px;
	content: '\e906';
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

.footer-nav.active h3:before {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.footer-nav ul li {
	margin-bottom: 1px;
	color: #9d9d9d;
}

.footer-nav a {
	color: #9d9d9d;
	-webkit-transition: color .3s linear;
	transition: color .3s linear;
}

.footer-nav a:hover {
	color: #e2e2e2;
}

.footer-nav [class^='icon-'],
.footer-nav [class*=' icon-'] {
	float: left;
	width: 30px;
	margin-top: 7px;
}

.footer-nav address {
	display: block;
	overflow: hidden;
	margin: 0;
	line-height: 24px;
	font-style: normal;
}

.wrap-text {
	white-space: nowrap;
}

.address-block li:first-letter {
	text-transform: inherit;
}

/* footer social network styles */

.social-wrap {
	display: table;
	width: 100%;
	text-align: center;
}

.social-wrap li {
	display: table-cell;
	vertical-align: middle;
}

.social-wrap li a {
	display: inline-block;
	position: relative;
	vertical-align: top;
	color: #6b6957;
}

.social-wrap li a:hover {
	color: #b0a377;
}

.social-wrap li a:hover .txt {
	opacity: 1;
}

.social-wrap [class^='icon-'],
.social-wrap [class*=' icon-'] {
	font-size: 32px;
	margin-left: 1px;
	display: block;
}

.social-wrap .txt {
	position: absolute;
	left: 50%;
	bottom: 100%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	font-size: 14px;
	width: 176px;
	opacity: 0;
	margin-bottom: 15px;
	font-weight: normal;
	letter-spacing: 1px;
}

.footer-bottom {
	background: #252525;
	padding: 10px 0;
	font-size: 1em;
	text-align: center;
	color: #9d9d9d;
	position: relative;
}

.footer-bottom a {
	color: #9d9d9d;
}

.footer-bottom a:hover {
	color: #e2e2e2;
}

.copyright {
	font-weight: normal;
	letter-spacing: .03em;
	font-size: .9em;
	line-height: 1;
}

.copyright .icon-copyright {
	font-size: .9em;
	display: inline-block;
	vertical-align: middle;
	margin-top: -2px;
}

/* payment option styles */

.payment-option {
	float: right;
	margin: 15px -14px 0;
	display: none;
}

.payment-option li {
	display: inline-block;
	vertical-align: middle;
	padding: 0 14px;
}

/*------------------------------------------------------------------
20. Background Color
-------------------------------------------------------------------*/

/* background color for content blocks */

.bg-white {
	background: #fff;
}

.bg-light-gray {
	background: #fafafa;
}

.bg-gray {
	background: #f8f8f8;
}

/* intro page banner styles */

.banner.banner-inner.intro-banner {
	background: #32353f;
	max-height: 550px;
}

.banner.banner-inner.intro-banner:before {
	background: rgba(45, 49, 47, .87);
}

.banner.banner-inner.intro-banner .banner-text {
	text-align: center;
	top: 0;
	bottom: 0;
}

.banner.banner-inner.intro-banner .center-text .container {
	max-width: 1020px;
}

.banner.banner-inner.intro-banner .ico-holder {
	margin-bottom: 30px;
}

.banner.banner-inner.intro-banner .logo-holder {
	margin-bottom: 30px;
}

.banner.banner-inner.intro-banner .subtitle {
	font-style: normal;
	color: #b0a377;
	font-size: 24px;
	font-weight: 700;
}

.slider-top {
	padding: 30px 0;
	text-align: center;
}

.slider-top .column {
	margin: 10px 0;
}

.demo-wrap {
	padding: 45px 0;
}

.demo-wrap .img-wrap {
	position: relative;
	z-index: 1;
	display: block;
	overflow: hidden;
	max-height: 300px;
}

.demo-wrap .img-wrap:hover:after {
	visibility: visible;
	opacity: 1;
}

.demo-wrap .img-wrap:hover .icon {
	opacity: 1;
	z-index: 9;
	visibility: visible;
}

.demo-wrap .img-wrap:after {
	background: rgba(0, 0, 0, .7);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: '';
	-webkit-transition: all linear .2s;
	transition: all linear .2s;
	opacity: 0;
	visibility: hidden;
}

.demo-wrap .img-wrap img {
	width: 100%;
}

.demo-wrap .bg-mongoose .img-wrap:after {
	background: rgba(176, 163, 119, .9);
}

.demo-wrap .bg-puerto-rico .img-wrap:after {
	background: rgba(64, 194, 168, .9);
}

.demo-wrap .bg-yellow-metal .img-wrap:after {
	background: rgba(128, 128, 64, .9);
}

.demo-wrap .bg-golden-grass .img-wrap:after {
	background: rgba(227, 163, 29, .9);
}

.demo-wrap .icon {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	color: #fff;
	font-size: 40px;
	opacity: 0;
}

.demo-wrap .content-heading {
	margin-bottom: 30px;
}

.demo-wrap .content-heading h2 {
	margin-bottom: 0;
}

.demo-wrap .column {
	margin-top: 30px;
}

.demo-wrap .title {
	display: block;
	text-align: center;
	font-size: 16px;
	padding: 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.demo-feature-list {
	margin-top: 40px;
}

.demo-feature-list li {
	margin-top: 30px;
}

.demo-feature-list .ico {
	display: inline-block;
	vertical-align: middle;
	width: 55px;
}

.demo-feature-list .text {
	display: inline-block;
	vertical-align: middle;
	width: calc(100% - 60px);
}

.demo-footer {
	background: #39382b;
	text-align: center;
	padding: 30px 0;
}

.demo-footer .btn-demo {
	border: 2px solid #746e51;
	background: none;
	font-size: 18px;
	line-height: 28px;
	padding: 12px;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
	color: #b0a377;
	font-weight: 700;
	position: relative;
}

.demo-footer .btn-demo:hover {
	border-color: #bdb8a1;
}

.demo-footer .btn-demo img {
	display: inline-block;
	vertical-align: middle;
	width: 32px;
	margin-right: 15px;
}

.demo-footer .btn-demo .text {
	display: inline-block;
	vertical-align: middle;
}

/*------------------------------------------------------------------
20. Scroll To Top / #scroll-to-top
-------------------------------------------------------------------*/

#scroll-to-top {
	position: fixed;
	bottom: 10px;
	right: 15px;
	background: rgba(0, 0, 0, .7);
	width: 45px;
	height: 45px;
	text-decoration: none;
	border-radius: 4px;
	display: none;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	z-index: 99;
}

#scroll-to-top i {
	color: #b0a377;
	position: relative;
	top: 12px;
	font-size: 20px;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
	display: inline-block;
}

#scroll-to-top:hover {
	background: rgba(0, 0, 0, .9);
}

#scroll-to-top:hover i {
	color: #fff;
	top: 10px;
}

/* datetime picker */

.dropdown-menu.datepicker-dropdown {
	visibility: visible;
	opacity: 1;
	-webkit-transform: rotate(0);
	-ms-transform: rotate(0);
	transform: rotate(0);
	position: absolute;
	background: #252525;
	color: #fff;
	border-radius: 0;
	width: 250px;
	padding: 5px;
}

.dropdown-menu.datepicker-dropdown table {
	width: 100%;
}

.datepicker-dropdown.dropdown-menu.datepicker-orient-bottom:before,
.datepicker-dropdown.dropdown-menu.datepicker-orient-bottom:after {
	border-bottom-color: #252525;
}

.datepicker-dropdown.dropdown-menu.datepicker-orient-top:before,
.datepicker-dropdown.dropdown-menu.datepicker-orient-top:after {
	border-top-color: #252525;
}

.datepicker-dropdown.dropdown-menu.datepicker-orient-bottom:after {
	border-bottom-color: #252525;
}

.datepicker table tr td.day:hover,
.datepicker table tr td span:hover,
.datepicker table tr td.day.focused {
	background: #b0a377;
}

.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
	background: #9a8c5a;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
	background: #b0a377;
}

.datepicker table tr td.day.focused,
.datepicker table tr td span.focused {
	background: #c3b997;
	color: #252525;
}

.datepicker table tr td.old:hover,
.datepicker table tr td span.new:hover,
.datepicker table tr td span.old:hover,
.datepicker table tr td.new:hover {
	background: none;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
	background: #474d4b;
	color: #fff;
}

.input-group.date {
	max-width: 400px;
}

.input-group.date .form-control {
	margin: 0;
	border: 1px solid #ccc;
}

.trip-form .input-group.date {
	background: rgba(37, 37, 37, .7);
	border-radius: 4px;
}

.trip-form .input-group.date input {
	background: none;
	border: 0;
	font-size: 14px;
	line-height: 24px;
	font-style: normal;
	color: #b0a377;
	font-weight: 700;
}

.trip-form .input-group.date .input-group-addon {
	background: rgba(24, 24, 24, .7);
	border-color: rgba(24, 24, 24, .7);
	border-radius: 0 4px 4px 0;
	color: #b0a377;
}

/* trip form variation v2 styles */

.trip-form-v2 {
	background: rgba(36, 36, 34, .88);
	border-color: rgba(54, 54, 54, .88);
	color: #a0a0a0;
	max-width: 670px;
	text-align: left;
	margin: 0 auto;
	font-size: 14px;
}

.trip-form-v2 label {
	color: #a0a0a0;
	font-weight: 400;
	font-style: italic;
	text-transform: uppercase;
	letter-spacing: .1em;
}

.trip-form-v2 select {
	width: 100%;
}

.trip-form-v2 .form-control {
	height: 44px;
	color: #a0a0a0;
	font-weight: 500;
	font-size: 14px;
	font-style: normal;
}

.trip-form-v2 .form-control::-webkit-input-placeholder {
	color: #a0a0a0;
}

.trip-form-v2 .form-control:-moz-placeholder {
	color: #a0a0a0;
}

.trip-form-v2 .form-control::-moz-placeholder {
	color: #a0a0a0;
}

.trip-form-v2 .form-control:-ms-input-placeholder {
	color: #a0a0a0;
}

.trip-form-v2 .input-group {
	position: relative;
	padding-left: 40px;
}

.trip-form-v2 .input-group:before {
	content: '\e979';
	font: 16px/18px 'icomoon';
	position: absolute;
	left: 15px;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.trip-form-v2 .input-group.date {
	border: 4px solid #464646;
	background: none;
	border-radius: 0;
}

.trip-form-v2 .input-group.date input {
	color: #a0a0a0;
}

.trip-form-v2 .input-group.date input::-webkit-input-placeholder {
	color: #a0a0a0;
}

.trip-form-v2 .input-group.date input:-moz-placeholder {
	color: #a0a0a0;
}

.trip-form-v2 .input-group.date input::-moz-placeholder {
	color: #a0a0a0;
}

.trip-form-v2 .input-group.date input:-ms-input-placeholder {
	color: #a0a0a0;
}

.trip-form-v2 .input-group.date .input-group-addon {
	background: none;
	color: #a0a0a0;
	border: 0;
}

.trip-form-v2 .holder {
	margin-bottom: 30px;
}

.banner.banner-home-search .banner-image {
	min-height: 600px;
}

.banner.banner-home-search .center-text {
	text-align: left;
}

.trip-note {
	font-style: italic;
	margin: 0 25px 20px;
	line-height: 1.4;
	font-weight: 400;
	letter-spacing: .1em;
}

.btn-holder {
	padding: 0 25px;
}

.jcf-select.jcf-select-trip-select-v2 {
	background: none;
	border: 4px solid #464646;
	height: 52px;
	width: 100%;
}

.jcf-select.jcf-select-trip-select-v2 .jcf-select-text {
	color: #a0a0a0;
	font-size: 14px;
	font-style: normal;
	padding: 13px 0 13px 50px;
	position: relative;
}

.jcf-select.jcf-select-trip-select-v2 .jcf-select-text:before {
	content: '\e979';
	font: 16px/18px 'icomoon';
	position: absolute;
	left: 15px;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.jcf-select.jcf-select-trip-select-v2 .jcf-select-opener {
	padding: 0 10px;
}

.jcf-select.jcf-select-trip-select-v2 .jcf-select-opener:after {
	content: '\e91e';
	right: 10px;
	color: #a0a0a0;
}

.jcf-select-drop.jcf-select-trip-select-v2 {
	background: #252525;
}

.jcf-select-drop.jcf-select-trip-select-v2 .jcf-list {
	background: #252525;
	text-transform: capitalize;
}

.jcf-select-drop.jcf-select-trip-select-v2 .jcf-option {
	color: #fff;
}

.jcf-select-drop.jcf-select-trip-select-v2 .jcf-hover {
	background: #b0a377;
}

.trip-form-v2 .input-group.date:before {
	content: '\e109';
	font-family: 'Glyphicons Halflings';
}

.jcf-select.jcf-select-trip-select-v2.jcf-select-region .jcf-select-text:before {
	content: '\e92d';
}

.jcf-select.jcf-select-trip-select-v2.jcf-select-acitvity .jcf-select-text:before {
	content: '\e95e';
}

.jcf-select.jcf-select-trip-select-v2.jcf-select-price .jcf-select-text:before {
	content: '\e90f';
}

/*------------------------------------------------------------------
21. Elements / .demo-wrapper
-------------------------------------------------------------------*/

.non-collapsible-link {
	position: static !important;
	left: 0 !important;
	top: 0 !important;
	width: auto !important;
}

.non-collapsible-link li {
	padding: 10px 0;
}

.demo-thumb {
	background: #474d4b;
	border: 10px solid #2f3231;
	padding: 10px;
	margin: 0 0 20px;
	text-align: center;
}

.partner-list a {
	height: 155px;
	min-height: 0;
	white-space: nowrap;
}

.partner-list a:after {
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	margin-right: -.25em;
	content: '';
}

.partner-list a img {
	vertical-align: middle;
}

.column-demo {
	margin-bottom: 45px;
}

.carousel-wrapper h3 {
	padding-left: 25px;
}

.carousel-wrapper .article h3 {
	padding-left: 0;
}

.carousel-wrapper .single-item h3 {
	padding-left: 0;
}

.demo-animation .demo-wrapper h3 {
	margin-bottom: 10px;
}

.demo-wrapper .owl-theme .owl-controls .owl-buttons .owl-next,
.demo-wrapper .owl-theme .owl-controls .owl-buttons .owl-prev {
	position: absolute;
	top: 50%;
	width: 20px;
	height: 40px;
	margin-top: -10px;
	border-radius: 2px;
	font-size: 0;
	opacity: 1;
	-webkit-transition: all linear .3s;
	transition: all linear .3s;
	opacity: 0;
	visibility: hidden;
	background: #6b6957;
}

.demo-wrapper .owl-theme .owl-controls .owl-buttons .owl-next:hover,
.demo-wrapper .owl-theme .owl-controls .owl-buttons .owl-prev:hover {
	background: #b0a377;
}

.demo-wrapper .owl-theme .owl-controls .owl-buttons .owl-next:after,
.demo-wrapper .owl-theme .owl-controls .owl-buttons .owl-prev:after {
	font: 14px/14px 'icomoon';
	content: '\e907';
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	text-align: center;
	margin-top: -7px;
}

.demo-wrapper .owl-theme .owl-controls .owl-buttons .owl-next {
	right: 10px;
}

.demo-wrapper .owl-theme .owl-controls .owl-buttons .owl-prev {
	left: 10px;
}

.demo-wrapper .owl-theme .owl-controls .owl-buttons .owl-prev:after {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.demo-wrapper .owl-theme .article {
	padding: 0 15px;
}

#common-multiple-slide-v1 .owl-controls {
	position: absolute;
	bottom: 100%;
	right: 10px;
}

#common-multiple-slide-v1 .owl-next,
#common-multiple-slide-v1 .owl-prev {
	position: relative;
	top: 0;
	margin: 0;
	right: inherit;
	left: inherit;
	margin: 0 5px 10px;
}

.btn.btn-trip-v2 {
	background: #6b6957;
	color: #fff;
	padding: 0 40px;
	height: 52px;
	border: 0;
	position: relative;
	border-radius: 0;
}

.btn.btn-trip-v2:before {
	right: 10px;
	content: '\e91e';
	color: #fff;
	font: 14px/14px 'icomoon';
	position: absolute;
	top: 50%;
	margin-top: -7px;
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}

.btn.btn-solid {
	font-style: normal;
	font-weight: 500;
	background: #3e3e3e;
	color: #fff;
	padding: 10px 20px;
	font-size: 18px;
	line-height: 27px;
	border-radius: 5px;
}

.btn.btn-solid:hover {
	background: #252525;
}

.owl-carousel:hover .owl-controls .owl-buttons .owl-next,
.owl-carousel:hover .owl-controls .owl-buttons .owl-prev {
	opacity: 1;
	visibility: visible;
}

.owl-item .demo-thumb {
	margin-bottom: 0;
}

.owl-item .article {
	margin: 0;
}

#common-slide {
	margin: 0 0 30px;
}

.demo-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
}

.demo-wrapper .trip-form {
	background: rgba(36, 36, 34, .88);
	padding: 15px;
}

.demo-wrapper h3 {
	margin-bottom: 50px;
}

.demo-wrapper .article h3 {
	margin-bottom: 15px;
}

.btn-demo-wrapper .btn {
	width: auto;
	margin: 0 5px 10px;
}

blockquote,
.blockquote {
	text-align: center;
}

blockquote .img,
.blockquote .img {
	border: 2px solid #fff;
	width: 90px;
	border-radius: 100%;
	margin: 0 auto 45px;
}

blockquote .img img,
.blockquote .img img {
	max-width: 100%;
	border-radius: 100%;
}

blockquote .text,
.blockquote .text {
	font-size: 16px;
}

blockquote cite,
.blockquote cite {
	display: block;
	border-bottom: 1px solid #e2e2e2;
	padding-bottom: 20px;
	margin-bottom: 45px;
	position: relative;
	font-size: 18px;
}

blockquote cite:after,
.blockquote cite:after {
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	width: 170px;
	height: 8px;
	background: #e2e2e2;
	content: '';
	position: absolute;
	left: 50%;
	bottom: 0;
}

.seperator.double-border {
	padding-bottom: 0;
	margin: 30px 0;
	height: 5px;
}

.seperator.double-border:before {
	height: 1px;
	left: 0;
	top: 0;
	right: 0;
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
	width: auto;
}

.seperator.left:before {
	left: 0;
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
}

.seperator.right:before {
	left: auto;
	right: 0;
	-webkit-transform: translate(0);
	-ms-transform: translate(0);
	transform: translate(0);
}

h4,
.h4 {
	font-size: 1.132857em;
	font-weight: normal;
}

.hold {
	margin-bottom: 20px;
}

.modal {
	top: 10% !important;
}

.modal .modal-header {
	padding: 30px 20px;
}

.modal .modal-body {
	padding: 30px 20px;
}

.modal .modal-footer {
	padding: 30px 20px;
}

.modal .hold.btn-holder {
	padding: 0;
}

.modal .hold.btn-holder .btn {
	margin: 0;
	display: block;
	width: 100%;
}

.modal-v2 .modal-header {
	border-bottom: 0;
	text-transform: uppercase;
	text-align: center;
}

.modal-v2 .modal-footer {
	border-top: 0;
	padding-top: 0;
	text-align: center;
}

.modal-v2 .modal-footer .btn {
	margin-left: auto;
	margin-right: auto;
}

.modal-v2 .modal-footer .btn + .btn {
	margin-left: auto;
}

.modal-v2 .log-option {
	margin-bottom: 20px;
}

.btn.no-border {
	border: 0;
}

.btn.btn-fb-login {
	background: #136ad5;
	color: #fff;
}

.btn.btn-fb-login:hover {
	background: #0f53a6;
}

.btn.btn-google-login {
	background: #dd4b39;
	color: #fff;
}

.btn.btn-google-login:hover {
	background: #c23321;
}

.seperator.text {
	padding: 0;
	margin: 45px 0;
}

.seperator.text:before {
	display: none;
}

.seperator.text span {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background: #ebebeb;
	border-radius: 100%;
	padding: 2px 5px;
}

.bottom-spacing {
	margin-bottom: 45px;
}

.modal-footer {
	text-align: center;
}

.modal-footer .btn {
	margin: 10px 0;
	font-size: 14px;
	padding: 6px;
	display: block;
}

.modal-footer .btn + .btn {
	margin-left: 0;
}

.counter-small .holder {
	padding: 45px 20px;
}

.counter-small .holder .info {
	margin: 0;
}

.content-heading.text-left {
	text-align: left;
}

.content-heading.text-right {
	text-align: right;
}

.global-box {
	padding: 45px 15px;
	box-shadow: 0 0 1px 0 rgba(0, 0, 0, .2);
	margin: 20px 0;
	border-radius: 2px;
}

.global-box .btn {
	margin-bottom: 5px;
	margin-top: 5px;
}

.global-box.box-sm {
	max-width: 400px;
}

.global-box.box-md {
	max-width: 700px;
}

.icon-demo {
	padding: 50px 0;
}

#set-1 {
	background: #6b6957;
}

#set-2 {
	background: #fff;
}

#set-3 {
	background: #252525;
}

#set-4 {
	background: #6b6957;
}

#set-5 {
	background: #b0a377;
}

.badge.hot {
	background: #f00;
}

.badge.trending {
	background: #39f;
}

.badge.top-pos {
	position: absolute;
	bottom: 100%;
	left: 100%;
}

.gallery-list.gallery-main li {
	width: auto;
	float: none;
	max-width: 380px;
	overflow: hidden;
	margin: 0 auto 30px;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-caption {
	font-size: 18px;
	text-align: center;
	padding: 10px 0 0;
	font-weight: 700;
}

.pagination-wrap.pagination-solid {
	background: #b0a377;
	color: #fff;
}

.pagination-wrap.pagination-solid .btn-prev a,
.pagination-wrap.pagination-solid .btn-next a {
	color: #fff;
}

.pagination-wrap.pagination-solid .btn-prev a:hover,
.pagination-wrap.pagination-solid .btn-next a:hover {
	color: #fff;
}

.pagination-wrap.pagination-solid .active a {
	background: #fff;
	color: #b0a377;
}

.pagination-wrap.pagination-solid .active a:hover,
.pagination-wrap.pagination-solid .active a:focus {
	background: #fff;
	color: #b0a377;
}

.pagination-wrap.pagination-solid a {
	color: #fff;
}

.pagination-wrap.pagination-solid a:hover {
	color: #fff;
}

.pagination-wrap.pagination-center .btn-prev,
.pagination-wrap.pagination-center .btn-next {
	float: none;
	display: inline-block;
	vertical-align: middle;
	padding-top: 0;
}

.pagination-wrap.pagination-center .pagination {
	display: inline-block;
	vertical-align: middle;
}

.demo-wrapper .social-networks {
	font-size: 24px;
}

.demo-wrapper .social-networks.social-share {
	font-size: 18px;
}

.demo-wrapper .social-networks li {
	padding: 0 10px;
}

.demo-wrapper .social-networks .ico,
.demo-wrapper .social-networks .text {
	display: inline-block;
	vertical-align: middle;
}

.demo-wrapper .social-networks .ico {
	margin-right: 10px;
}

.demo-wrapper .input-group.date {
	max-width: none;
}

.headings-wrapper h1,
.headings-wrapper h2,
.headings-wrapper h3,
.headings-wrapper h4,
.headings-wrapper h5,
.headings-wrapper h6 {
	margin-bottom: 40px;
}

.has-mega-dropdown.mega-md .dropdown-menu {
	background: none;
}

.has-mega-dropdown.mega-md .dropdown-menu .drop-wrap {
	background: #252525;
}

.accordion-v2 > li:before,
.accordion-v2 > li:after {
	display: none;
}

.accordion-v2 > li > a {
	padding-left: 20px;
	background: #f8f8f8;
	border-bottom: 1px solid #e2e2e2;
}

.accordion-v2 .slide {
	padding: 0;
}

.accordion-v2 .slide-holder {
	padding: 20px;
	margin: 0;
	border-top: none;
}

.material-icons-list {
	text-align: justify;
	padding: 50px 0 0;
}

.material-icons-list li {
	display: inline-block;
	vertical-align: top;
	width: 16%;
	margin-bottom: 40px;
	min-width: 100px;
}

.material-icons-list .material-icons {
	font-size: 40px;
}

.demo-wrapper .data-table,
.demo-wrapper .data-table thead,
.demo-wrapper .data-table tbody,
.demo-wrapper .data-table th,
.demo-wrapper .data-table td,
.demo-wrapper .data-table tr {
	display: block;
	overflow: hidden;
}

.demo-wrapper .data-table thead tr {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

.demo-wrapper table.table tbody tr td {
	padding: 15px;
}

.demo-wrapper table.table thead tr th {
	padding: 15px;
}

.demo-wrapper .data-table td {
	padding: 10px;
}

.demo-wrapper .data-table td:before {
	content: attr(data-label) ': ';
	display: inline-block;
	font-weight: bold;
	width: 5em;
}

.hi-icon-wrap {
	text-align: center;
	margin: 0 auto;
	padding: 2em 0 3em;
}

.hi-icon {
	display: inline-block;
	cursor: pointer;
	margin: 15px 30px;
	width: 90px;
	height: 90px;
	border-radius: 50%;
	text-align: center;
	position: relative;
	z-index: 1;
	color: #fff;
}

.hi-icon [class^='icon-'],
.hi-icon [class*=' icon-'] {
	display: inline-block;
	vertical-align: middle;
	line-height: 90px;
	font-size: 40px;
}

.hi-icon:after {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: '';
	box-sizing: content-box;
}

/* Effect 1 */

.hi-icon-effect-1 .hi-icon {
	background: rgba(0, 0, 0, .5);
	-webkit-transition: background .2s, color .2s;
	transition: background .2s, color .2s;
}

.hi-icon-effect-1 .hi-icon:after {
	top: -7px;
	left: -7px;
	padding: 7px;
	box-shadow: 0 0 0 4px #fff;
	-webkit-transition: -webkit-transform .2s, opacity .2s;
	-webkit-transform: scale(.8);
	-moz-transition: -moz-transform .2s, opacity .2s;
	-moz-transform: scale(.8);
	-ms-transform: scale(.8);
	-webkit-transition: -webkit-transform .2s, opacity .2s;
	transition: transform .2s, opacity .2s;
	-webkit-transform: scale(.8);
	-ms-transform: scale(.8);
	transform: scale(.8);
	opacity: 0;
}

/* Effect 1a */

.hi-icon-effect-1a .hi-icon:hover {
	background: white;
	color: #333;
}

.hi-icon-effect-1a .hi-icon:hover:after {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

/* Effect 1b */

.hi-icon-effect-1b .hi-icon:hover {
	background: white;
	color: #333;
}

.hi-icon-effect-1b .hi-icon:after {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
}

.hi-icon-effect-1b .hi-icon:hover:after {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

/* Effect 2 */

.hi-icon-effect-2 .hi-icon {
	color: #fff;
	box-shadow: 0 0 0 3px #b0a377;
	-webkit-transition: color .3s;
	transition: color .3s;
}

.hi-icon-effect-2 .hi-icon:after {
	top: -2px;
	left: -2px;
	padding: 2px;
	z-index: -1;
	background: #fff;
	-webkit-transition: -webkit-transform .2s, opacity .2s;
	transition: transform .2s, opacity .2s;
}

/* Effect 2a */

.hi-icon-effect-2 .hi-icon:after {
	background: #b0a377;
	color: #333;
}

.hi-icon-effect-2a .hi-icon:hover {
	color: #fff;
}

.hi-icon-effect-2a .hi-icon:hover:after {
	-webkit-transform: scale(.85);
	-ms-transform: scale(.85);
	transform: scale(.85);
}

/* Effect 2b */

.hi-icon-effect-2b .hi-icon:hover:after {
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	opacity: 0;
	-webkit-transition: -webkit-transform .4s, opacity .2s;
	transition: transform .4s, opacity .2s;
}

.hi-icon-effect-2b .hi-icon:hover {
	color: #fff;
}

/* Effect 3 */

.hi-icon-effect-3 .hi-icon {
	box-shadow: 0 0 0 4px #fff;
	-webkit-transition: color .3s;
	transition: color .3s;
}

.hi-icon-effect-3 .hi-icon:after {
	top: -2px;
	left: -2px;
	padding: 2px;
	z-index: -1;
	background: #fff;
	-webkit-transition: -webkit-transform .2s, opacity .3s;
	transition: transform .2s, opacity .3s;
}

/* Effect 3a */

.hi-icon-effect-3a .hi-icon {
	color: #333;
}

.hi-icon-effect-3a .hi-icon:hover {
	color: #fff;
}

.hi-icon-effect-3a .hi-icon:hover:after {
	-webkit-transform: scale(1.3);
	-ms-transform: scale(1.3);
	transform: scale(1.3);
	opacity: 0;
}

/* Effect 3b */

.hi-icon-effect-3b .hi-icon {
	color: #fff;
}

.hi-icon-effect-3b .hi-icon:hover {
	color: #333;
}

.hi-icon-effect-3b .hi-icon:after {
	-webkit-transform: scale(1.3);
	-ms-transform: scale(1.3);
	transform: scale(1.3);
	opacity: 0;
}

.hi-icon-effect-3b .hi-icon:hover:after {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	opacity: 1;
}

/* Effect 4 */

.hi-icon-effect-4 .hi-icon {
	width: 92px;
	height: 92px;
	box-shadow: 0 0 0 4px #b0a377;
	color: #b0a377;
}

.hi-icon-effect-4a .hi-icon {
	-webkit-transition: box-shadow .2s;
	transition: box-shadow .2s;
}

.hi-icon-effect-4 .hi-icon:before {
	line-height: 92px;
}

.hi-icon-effect-4 .hi-icon:after {
	top: -4px;
	left: -4px;
	padding: 0;
	z-index: 10;
	border: 4px dashed #b0a377;
}

.hi-icon-effect-4 .hi-icon:hover {
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
}

/* Effect 4b */

.hi-icon-effect-4b .hi-icon:hover {
	-webkit-transition: box-shadow .2s;
	transition: box-shadow .2s;
}

.hi-icon-effect-4b .hi-icon:hover:after {
	-webkit-animation: spinAround 9s linear infinite;
	animation: spinAround 9s linear infinite;
}

/* Effect 5 */

.hi-icon-effect-5 .hi-icon {
	box-shadow: 0 0 0 4px white;
	overflow: hidden;
	-webkit-transition: background .3s, color .3s, box-shadow .3s;
	transition: background .3s, color .3s, box-shadow .3s;
}

.hi-icon-effect-5 .hi-icon:after {
	display: none;
}

.hi-icon-effect-5 .hi-icon:hover {
	background: white;
	color: #333;
	box-shadow: 0 0 0 8px rgba(255, 255, 255, .3);
}

.hi-icon-effect-5a .hi-icon:hover:before {
	-webkit-animation: toRightFromLeft .3s forwards;
	animation: toRightFromLeft .3s forwards;
}

.hi-icon-effect-5b .hi-icon:hover:before {
	-webkit-animation: toLeftFromRight .3s forwards;
	animation: toLeftFromRight .3s forwards;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {

#tour-slide {
	height: 320px;
}

#tour-slide .bg-stretch {
	position: absolute;
}

}

@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {

.trip-info .bg-stretch {
	position: static;
}

.trip-info .bg-stretch img {
	width: 100% !important;
	height: auto !important;
	margin: 0 !important;
}

}

@media only screen and (min-width: 480px) {

.newsletter-form .form-control {
	font-size: 32px;
	line-height: 36px;
}

.social-wrap [class^='icon-'],
.social-wrap [class*=' icon-'] {
	font-size: 40px;
}

}

@media only screen and (min-width: 540px) {

.demo-feature-list li {
	width: 49%;
	display: inline-block;
	vertical-align: top;
}

}

@media only screen and (min-width: 768px) and (max-width: 991px) {

.trip-info .bg-stretch {
	position: static;
}

.trip-info .bg-stretch img {
	width: 100% !important;
	height: auto !important;
	margin: 0 !important;
}

}

@media screen and (min-width: 768px) {

.demo-wrapper .data-table {
	display: table;
	width: 100%;
}

.demo-wrapper .data-table thead {
	display: table-header-group;
}

.demo-wrapper .data-table thead tr {
	position: static;
	top: auto;
	left: auto;
}

.demo-wrapper .data-table tbody {
	display: table-row-group;
	overflow: visible;
}

.demo-wrapper .data-table tr {
	display: table-row;
	overflow: visible;
}

.demo-wrapper .data-table th,
.demo-wrapper .data-table td {
	display: table-cell;
	padding: 6px 12px;
	vertical-align: top;
	text-align: left;
	overflow: visible;
}

.demo-wrapper .data-table td:before {
	content: '';
	width: auto;
}

}

@media only screen and (min-width: 768px) {

#content {
	float: right;
	margin-bottom: 0;
}

.content-with-sidebar.content-left #content {
	float: left;
}

.content-with-sidebar.content-both-sidebar #content {
	float: none;
	margin-top: 45px;
}

.common-spacing {
	padding-top: 90px;
	padding-bottom: 90px;
}

.db-3-col [class^='col-'],
.db-3-col [class*=' col-'] {
	display: inline-block;
	vertical-align: top;
	float: none;
	margin-right: -.25em;
}

h1 {
	font-size: 2.857142em;
}

h2 {
	font-size: 1.714em;
}

h2.intro-heading,
h3.intro-heading {
	font-size: 1.857142em;
	margin-bottom: 25px;
}

h1.small-size {
	font-size: 2.143em;
}

h1.content-main-heading,
h2.content-main-heading {
	font-size: 2.142857em;
}

p.intro {
	font-size: 1.286em;
	line-height: 2;
}

p.special-text {
	font-size: 2em;
}

blockquote {
	font-size: 1.7857em;
}

blockquote cite {
	letter-spacing: 1px;
}

blockquote.testimonial-quote {
	font-size: 1.286em;
	line-height: 2;
}

blockquote.normal {
	font-size: 1.857142em;
}

.main-subtitle {
	font-size: 1.857142em;
}

.side-list.horizontal-list li {
	width: 25%;
	padding: 0 5px;
	margin-bottom: 10px;
}

.side-list.gallery-side-list {
	margin: 0 -10px;
}

.side-list.gallery-side-list li {
	width: 33.3333%;
	padding: 0 10px;
	margin-bottom: 20px;
}

.testimonial-holder blockquote cite {
	padding-bottom: 45px;
	letter-spacing: 1px;
}

.testimonial-holder blockquote {
	margin: 0 8.2%;
}

.testimonial-holder {
	padding: 90px 0;
}

.services-block {
	padding: 90px 0;
}

.description-text {
	margin-top: 60px;
}

.banner {
	min-height: 600px;
}

.banner.banner-inner .subtitle {
	font-size: 1.571428em;
}

.banner-image {
	max-height: none;
	position: static;
}

.banner-icon-search .banner-text h1 {
	font-size: 4.571em;
}

.banner-caption-s1 .center-text .banner-subtitle,
.banner-home .banner-caption-s1 .center-text .banner-subtitle {
	font-size: 1.714em;
}

.feature-block {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}

.gallery-home-holder {
	margin-top: 60px;
}

.gallery-home-holder .text-block {
	padding-bottom: 10px;
}

.gallery-home-holder .gallery-list {
	margin-bottom: 0;
}

.content-block.content-center .img-wrap {
	width: auto;
	margin: 0 0 40px;
	height: 120px;
}

.content-block.content-center p {
	margin-bottom: 0;
}

.content-block.guide-sub .description,
.content-block.guide-sub .bar-holder {
	max-width: none;
}

.content-block {
	padding: 90px 0;
}

.content-block .article {
	margin-top: 45px;
}

.content-block.content-sub {
	padding-top: 60px;
	padding-bottom: 60px;
}

.content-block.content-sub .content-holder {
	margin-top: -35px;
}

.content-block.guide-sub .img-article {
	margin-top: 60px;
}

.content-block.article-boxed .content-heading {
	margin-bottom: 15px;
}

.content-block.content-spacing .content-heading {
	margin-bottom: 15px;
}

.adventure-holder .text-block .centered {
	padding: 60px 15px;
}

.adventure-holder.gallery-home-holder .text-block .centered {
	padding-bottom: 0;
}

.grid-view-sidebar {
	padding-bottom: 90px;
}

.grid-view-sidebar .container {
	padding-top: 198px;
}

.grid-view-sidebar #content {
	margin-top: 45px;
}

.grid-view-sidebar #sidebar {
	margin-top: 45px;
}

.gallery-list .info {
	letter-spacing: 1px;
}

.gallery-list .hover {
	font-size: 48px;
}

.btn-banner {
	font-size: 18px;
	line-height: 22px;
	padding: 12px 70px;
	border-width: 2px;
	letter-spacing: 1px;
}

.banner-caption-s1 .center-text .btn-banner-holder,
.banner-home .banner-caption-s1 .center-text .btn-banner-holder {
	margin-top: 50px;
}

.filter-option {
	max-width: none;
}

.filter-option .btn-filter {
	display: none;
}

.filter-option .filter-slide {
	position: static;
	background: none;
	max-height: none;
	width: auto;
	padding-top: 0;
}

.filter-option .filter-slide .select-col {
	margin-top: 0;
	width: 200px;
}

.count-block .holder {
	font-size: 1em;
	padding: 60px 10px;
}

.count-block .icon {
	margin-bottom: 25px;
}

.count-block .info {
	font-size: 2em;
	margin-bottom: 15px;
}

.count-block .txt {
	font-size: 1em;
	text-transform: uppercase;
}

.article {
	max-width: none;
}

.article .heading {
	margin: 0 0 10px;
}

.article .heading h3 {
	display: inline-block;
	vertical-align: middle;
	margin-right: -.25em;
	max-width: 78%;
	padding-right: 10px;
	margin: 0;
}

.article .meta {
	font-size: .9em;
}

.article .img-caption {
	font-size: 16px;
}

.article .thumbnail .meta {
	padding-bottom: 15px;
}

.article .thumbnail .country [class^='icon-'],
.article .thumbnail .country [class*=' icon-'],
.article .thumbnail .activities [class^='icon-'],
.article .thumbnail .activities [class*=' icon-'] {
	font-size: 13px;
	margin-right: 2px;
}

.article .thumbnail .img-wrap {
	margin-bottom: 15px;
}

.article .info-day {
	display: inline-block;
	vertical-align: middle;
	font-size: 1.143em;
	line-height: 1em;
	padding-left: 10px;
	position: relative;
	border-left: 1px solid #e2e2e2;
	max-width: 20%;
}

.guide-info {
	margin-top: 60px;
}

.content-with-sidebar .article .social-networks {
	margin: 0 -5px;
}

.content-with-sidebar .article .social-networks li {
	padding: 0 5px;
}

.list-view .filter-option {
	max-width: none;
}

.list-view .article {
	max-width: none;
}

.list-view .article .col-left {
	float: left;
	width: 70%;
	padding: 15px 10px 15px 0;
}

.list-view .article .info-aside {
	float: right;
	width: 30%;
	margin: 0;
	border-width: 0 0 0 1px;
	padding-top: 0;
}

.recent-block {
	padding-bottom: 60px;
}

.recent-block h2 {
	margin: 0 0 15px;
}

.booking-form .form-holder,
.twocol-form .form-holder {
	padding: 45px 30px;
}

.booking-form .payment-option,
.twocol-form .payment-option {
	display: block;
}

.booking-form h2,
.twocol-form h2 {
	margin-bottom: 45px;
}

.booking-form .wrap,
.twocol-form .wrap {
	padding-right: 30px;
}

.booking-form .btn-hold,
.twocol-form .btn-hold {
	padding-top: 45px;
}

.top-box .holder {
	padding: 25px 60px 25px 30px;
}

.top-box .left {
	display: block;
	float: left;
}

.top-box .right {
	float: right;
}

.block-quotation {
	padding: 30px 15px;
}

.partner-block {
	padding: 90px 0;
}

.partner-block .partner {
	margin-top: 60px;
}

.ico-article {
	margin-top: 45px;
	max-width: none;
}

.ico-article .ico-holder {
	min-height: 65px;
}

.step-block {
	padding: 60px 0;
}

.content-intro {
	padding-bottom: 90px;
}

.content-intro .text-holder {
	text-align: left;
	padding-top: 90px;
}

.trip-info .text-col .holder {
	padding: 45px 20px;
}

.trip-info .price {
	margin-bottom: 30px;
	font-size: 2.143em;
}

.trip-detail {
	padding-top: 60px;
	padding-bottom: 60px;
}

.trip-detail .gallery-list li {
	width: 50%;
	float: left;
}

.nav-tabs {
	padding: 0;
}

.nav-tabs > li {
	padding: 15px 10px;
}

table.table {
	table-layout: fixed;
}

table.table.table-align-right td:last-child,
table.table.table-align-right th:last-child {
	text-align: right;
}

.fancybox-close:after {
	font-size: 16px;
}

.article.blog-article .star-rating,
.article.blog-article .comment,
.article.blog-article .ico-action,
.article.blog-article .rate-info {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding: 0 10px;
}

.article.blog-article .star-rating:before,
.article.blog-article .comment:before,
.article.blog-article .ico-action:before,
.article.blog-article .rate-info:before {
	content: '';
	position: absolute;
	left: 0;
	top: 4px;
	bottom: 3px;
	width: 1px;
	background: #e2e2e2;
}

.article.blog-article .star-rating {
	padding-left: 0;
}

.article.blog-article .footer-sub {
	margin: 0;
	display: inline-block;
}

.blog-single .description {
	padding: 25px 20px;
}

.trip-form .holder {
	display: inline-block;
	vertical-align: bottom;
	width: 50%;
	padding: 0 15px;
	margin-right: -.25em;
}

.comment-form {
	padding: 60px 0 0;
}

.contact-info {
	margin-top: 60px;
	margin-bottom: 60px;
}

.contact-form .form-title {
	text-align: right;
}

.contact-form {
	padding: 60px 80px 60px 30px;
}

.contact-form .btn-holder {
	padding-top: 30px;
}

.error-page #main {
	padding-top: 90px;
}

.error-holder {
	padding-top: 90px;
}

.error-holder h1 {
	font-size: 6.143em;
}

.error-holder .title {
	font-size: 2.143em;
	margin-bottom: 60px;
}

.error-holder .button-holder .btn {
	width: auto;
	min-width: 270px;
	margin: 0 14px 20px;
}

.inner-search {
	font-size: 1.286em;
	line-height: 2;
	margin-bottom: 60px;
	max-width: 950px;
}

.inner-top {
	padding: 45px 0;
}

.special-block .special-text {
	font-size: 2em;
}

.special-block {
	padding: 45px 0;
}

.browse-block {
	display: table;
	width: 100%;
	table-layout: fixed;
	font-size: 1.286em;
}

.browse-block .column {
	display: table-cell;
	width: 50%;
	letter-spacing: 4px;
}

.browse-block a span {
	line-height: 120px;
}

.font-demo-wrapper .glyph.fs1 {
	display: inline-block;
	vertical-align: top;
	width: 49%;
}

#footer > .container {
	padding-top: 90px;
	padding-bottom: 60px;
}

.newsletter-form {
	margin-bottom: 60px;
}

.newsletter-form .form-control {
	font-size: 48px;
	line-height: 55px;
}

.newsletter-form input[type='submit'] {
	font-size: 36px;
	line-height: 40px;
	border-width: 0 0 11px;
}

.newsletter-form .info {
	font-size: 1.286em;
}

.footer-holder {
	/* margin-bottom: 90px; */
}

.footer-nav {
	margin-bottom: 30px;
}

.footer-nav.col-md-2 {
	width: 15%;
}

.footer-nav.col-md-2:last-child {
	width: 25%;
}

.footer-nav h3 {
	margin-bottom: 15px;
	pointer-events: none;
}

.footer-nav h3:before {
	display: none;
}

.footer-nav ul li {
	margin-bottom: 5px;
}

.footer-nav.last li {
	margin-bottom: 9px;
}

.footer-nav .slide {
	position: static !important;
	width: auto !important;
}

.social-wrap [class^='icon-'],
.social-wrap [class*=' icon-'] {
	font-size: 54px;
}

.footer-bottom {
	padding: 30px 0;
}

.copyright {
	font-size: 1em;
}

.copyright .icon-copyright {
	font-size: 1.4em;
	margin-right: 10px;
}

.demo-wrap .title {
	padding: 10px;
}

.demo-footer {
	padding: 45px 0;
}

.demo-footer .btn-demo {
	min-width: 304px;
}

#scroll-to-top {
	bottom: 25px;
	right: 25px;
}

.demo-animation .row:nth-child(even) [class^='col-']:first-child,
.demo-animation .row:nth-child(even) [class*=' col-']:first-child {
	float: right;
}

.demo-animation .demo-wrapper h3 {
	margin-bottom: 30px;
}

blockquote cite,
.blockquote cite {
	padding-bottom: 45px;
	letter-spacing: 1px;
}

blockquote,
.blockquote {
	margin: 0 8.2%;
}

.modal-v2 .modal-footer .btn + .btn {
	margin-left: 20px;
}

.bottom-spacing {
	margin-bottom: 60px;
}

.modal-footer {
	text-align: left;
}

.modal-footer .btn {
	font-size: 16px;
	padding: 8px;
	display: inline-block;
	margin: 0;
}

.modal-footer .btn + .btn {
	margin-left: 10px;
}

.gallery-list.gallery-main li {
	width: 50%;
	float: left;
	max-width: none;
}

}

@media only screen and (min-width: 992px) {

.content-with-sidebar.content-both-sidebar #content {
	float: left;
	margin-top: 0;
}

.content-with-sidebar.content-both-sidebar #sidebar,
.content-with-sidebar.content-both-sidebar #sidebar-right {
	max-width: none;
}

.common-spacing {
	padding-top: 100px;
	padding-bottom: 100px;
}

h1 {
	font-size: 3.428571em;
}

h2 {
	font-size: 2.143em;
	margin-bottom: 34px;
}

h3,
.h3 {
	font-size: 1.2857em;
}

h2.intro-heading,
h3.intro-heading {
	font-size: 2.142857em;
	margin-bottom: 35px;
}

h1.content-main-heading,
h2.content-main-heading {
	font-size: 2.571428em;
}

p.special-text {
	font-size: 2.571em;
}

blockquote {
	font-size: 2.1428em;
}

blockquote.normal {
	font-size: 2.142857em;
}

.main-subtitle {
	font-size: 2.142857em;
}

.inner-main-heading {
	font-size: 3.439em;
}

#header {
	border-bottom: 1px solid #2d2d2d;
	background: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

#header.fixed-position .logo {
	padding-top: 22px;
	padding-bottom: 22px;
}

.logo {
	width: 180px;
	margin: 0;
	padding: 25px 15px 25px 0;
	float: left;
	border-right: 1px solid #2d2d2d;
}

.logo a {
	width: 120px;
}

.logo img {
	width: 100%;
}

.default-page #header.white-header.fixed-position .navbar-default .v-divider,
#header.white-header.fixed-position .navbar-default .v-divider {
	border-color: #eaeaea;
}

.default-page #header.white-header.fixed-position .navbar-default .navbar-nav,
#header.white-header.fixed-position .navbar-default .navbar-nav {
	border-right-color: #eaeaea;
}

.default-page #header.white-header.fixed-position .navbar-default .navbar-nav > li > a,
#header.white-header.fixed-position .navbar-default .navbar-nav > li > a {
	color: #5c5e62;
}

.default-page #header.white-header.fixed-position .navbar-default .navbar-nav > li > a:hover,
#header.white-header.fixed-position .navbar-default .navbar-nav > li > a:hover {
	color: #b0a377;
}

.default-page #header.white-header.fixed-position .navbar-default .navbar-nav > li.hover > a,
#header.white-header.fixed-position .navbar-default .navbar-nav > li.hover > a {
	color: #b0a377;
}

.default-page #header.default-white-header,
#header.default-white-header {
	border-bottom: 1px solid #eaeaea;
}

.default-page #header.default-white-header .navbar-default .navbar-nav .v-divider,
#header.default-white-header .navbar-default .navbar-nav .v-divider {
	border-color: #eaeaea;
}

.default-page #header.default-white-header .navbar-default .navbar-nav > li > a,
#header.default-white-header .navbar-default .navbar-nav > li > a {
	color: #5c5e62;
}

.default-page #header.default-white-header .navbar-default .navbar-nav > li > a:hover,
#header.default-white-header .navbar-default .navbar-nav > li > a:hover {
	color: #b0a377;
}

.default-page #header.default-white-header .navbar-default .navbar-nav > li.hover > a,
#header.default-white-header .navbar-default .navbar-nav > li.hover > a {
	color: #b0a377;
}

.header-v2 .navbar-default .navbar-nav {
	float: left;
}

.header-v2 .navbar-default .navbar-right {
	float: right;
}

.header-v2 .navbar-default .nav-center {
	width: calc(100% - 340px);
	text-align: center;
}

.header-v2 .navbar-default .nav-center > li {
	float: none;
	display: inline-block;
}

.header-v2 .navbar-nav .has-mega-dropdown.mega-md .dropdown-menu {
	right: 20%;
	left: 20%;
	width: auto;
}

.sidebar {
	float: left;
}

.side-list.horizontal-list {
	margin-bottom: -25px;
}

.side-list.horizontal-list li {
	margin-bottom: 40px;
}

.boxed-layout .logo {
	width: 180px;
}

.testimonial-holder blockquote .img {
	display: inline-block;
	vertical-align: top;
	width: 112px;
	margin: 20px 0 0;
}

.testimonial-holder blockquote .text {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 150px);
	padding-left: 55px;
}

.testimonial-holder {
	padding: 100px 0 100px;
}

.testimonial-holder .owl-pagination {
	padding-left: 193px;
}

.services-block {
	padding: 100px 0 100px;
}

.services-block .ico-article {
	margin-top: 60px;
}

.services-block .row {
	margin-top: -60px;
}

.navbar-default {
	font-size: .9em;
}

.navbar-default .open .dropdown-menu {
	display: none;
}

.navbar-default .navbar-nav {
	max-width: calc(100% - 180px);
	float: right;
}

.navbar-default .navbar-nav .dropdown.hover > .dropdown-menu {
	display: block;
}

.navbar-default .navbar-nav .icon {
	margin-right: 0;
}

.navbar-default .navbar-nav > li {
	padding: 30px 8px 0;
	border-top: 0;
}

.navbar-default .navbar-nav > li .dropdown-menu {
	margin-top: 1px;
}

.navbar-default .navbar-nav > li.nav-visible {
	display: inline-block !important;
}

.navbar-default .navbar-nav > li > a:hover {
	color: #fff;
}

.navbar-default .navbar-nav > li.hover > a {
	color: #fff;
}

.navbar-default .navbar-nav > li.has-mega-dropdown {
	position: static;
}

.navbar-default .navbar-nav > li.active a,
.navbar-default .navbar-nav > li.open a,
.navbar-default .navbar-nav > li:focus a {
	background: none;
}

.navbar-default .navbar-nav > li.active a:hover,
.navbar-default .navbar-nav > li.active a:focus,
.navbar-default .navbar-nav > li.open a:hover,
.navbar-default .navbar-nav > li.open a:focus,
.navbar-default .navbar-nav > li:focus a:hover,
.navbar-default .navbar-nav > li:focus a:focus {
	background: none;
}

.navbar-default .navbar-nav > li > a {
	background: none;
	height: 46px;
	padding: 0;
	display: block;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	background: none;
}

.navbar-default .icon-angle-down {
	display: none;
}

#header.fixed-position .search-form .search-opener {
	display: none;
}

#header.fixed-position .search-form .search-wrap .search-opener {
	display: block;
}

#header.fixed-position .navbar-default .navbar-nav > li {
	padding-top: 25px;
}

#header.fixed-position .navbar-default .navbar-nav > li .dropdown-menu {
	margin-top: 0;
	border-top: 1px solid transparent;
}

.navbar-collapse {
	background: none;
}

.dropdown-menu {
	border-style: solid;
	box-shadow: inset 0 -1px 0 1px #252525;
	display: block;
	position: absolute;
	width: auto;
	left: 0;
	font-size: .9em;
	visibility: hidden;
	opacity: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: rotateX(-90deg);
	transform: rotateX(-90deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform-origin: top center;
	-ms-transform-origin: top center;
	transform-origin: top center;
	-webkit-transition: -webkit-transform .3s cubic-bezier(.17, .67, .59, 1.21);
	transition: transform .3s cubic-bezier(.17, .67, .59, 1.21);
}

.dropdown-menu.dropdown-md {
	min-width: 300px;
}

.dropdown-menu.dropdown-sm {
	min-width: 150px;
}

.dropdown-menu.dropdown-sm .drop-wrap {
	padding: 10px;
}

.dropdown-menu.drop-thumb-wide .des {
	max-width: 80%;
}

.dropdown-menu.drop-thumb-wide .img-wrap img {
	max-width: 80%;
}

.dropdown-menu ul {
	padding: 9px 0;
}

.dropdown-menu .drop-wrap {
	padding-top: 10px;
	padding-bottom: 10px;
}

.dropdown-menu .top-title {
	display: none;
}

.dropdown-menu .sub-link-opener:before {
	display: none;
}

.dropdown-menu .drop-holder .col {
	max-width: none;
	margin-top: 0;
}

.dropdown-menu .drop-holder .col:hover .title,
.dropdown-menu .drop-holder .col:hover .title a {
	color: #b0a377;
}

.dropdown-menu .drop-holder .img-wrap img {
	max-width: 400px;
}

.dropdown-menu .drop-holder li {
	display: block;
	margin-left: 0;
	width: auto;
}

.dropdown-menu .five-col {
	margin: 0 -15px;
}

.dropdown-menu .five-col .column {
	float: left;
	padding: 0 15px;
	width: 20%;
	position: relative;
}

.dropdown-menu .five-col .column:first-child:before {
	display: none;
}

.dropdown-menu .five-col .column:before {
	content: '';
	position: absolute;
	left: -10px;
	background: #181818;
	width: 1px;
	top: 0;
	bottom: 0;
}

.dropdown-menu .five-col ul {
	display: block;
	text-transform: uppercase;
}

.dropdown-menu .five-col ul li {
	display: block;
	width: auto;
	margin: 0;
}

.dropdown-menu .five-col ul li a {
	padding-left: 0;
}

.dropdown-menu .five-col ul li a:hover {
	padding-left: 5px;
}

.dropdown-menu .five-col ul {
	position: static !important;
	width: auto !important;
}

.dropdown-menu .five-col .sub-link-opener {
	pointer-events: none;
}

.has-mega-dropdown > .dropdown-menu {
	left: 10px;
	right: 10px;
	width: auto;
}

.has-mega-dropdown .dropdown-menu {
	border-left-width: 0;
	border-right-width: 0;
}

.has-mega-dropdown .drop-wrap {
	padding: 25px 40px;
}

.dropdown.open > .dropdown-menu {
	overflow: visible;
	transform: rotateX(0) translateZ(0);
	-ms-transform: rotateX(0) translateZ(0);
	-webkit-transform: rotateX(0) translateZ(0);
	opacity: 1;
	visibility: visible;
}

.dropdown:hover .dropdown-menu {
	overflow: visible;
	transform: rotateX(0) translateZ(0);
	-ms-transform: rotateX(0) translateZ(0);
	-webkit-transform: rotateX(0) translateZ(0);
	opacity: 1;
	visibility: visible;
}

.dropdown:hover .icon-angle-down {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.banner.banner-inner {
	height: 666px;
}

.banner.banner-inner .subtitle {
	font-size: 1.714285em;
}

.banner-image {
	height: 100vh;
}

.banner-home .banner-text h1 {
	font-size: 5.143em;
}

.banner-home .banner-text h1 span {
	font-size: .72em;
}

.banner-home .banner-text .subtitle {
	font-size: 1.714em;
}

.banner-text {
	bottom: 0;
}

.banner-text .center-text {
	margin: 5% 0;
}

.banner-text h1 {
	margin-bottom: 30px;
}

.heading-home {
	font-size: 7.143em !important;
	margin-bottom: 30px;
}

.heading-home span {
	font-size: .72em;
}

.banner-caption-s1 .center-text,
.banner-home .banner-caption-s1 .center-text {
	max-width: 940px;
}

.banner-caption-s1 .center-text .banner-subtitle,
.banner-home .banner-caption-s1 .center-text .banner-subtitle {
	font-size: 2.571em;
}

.gallery-home-holder {
	margin-top: 30px;
}

.gallery-home-holder .text-block {
	padding-bottom: 14px;
}

.content-block.guide-sub .img-article {
	margin-top: 90px;
}

.content-block {
	padding: 100px 0;
}

.content-block.content-sub {
	padding-top: 90px;
	padding-bottom: 100px;
}

.content-block.guide-sub .img-article {
	margin-top: 90px;
}

.content-block.article-boxed .content-heading {
	margin-bottom: 45px;
}

.content-block.content-spacing .content-heading {
	margin-bottom: 45px;
}

.adventure-holder .text-block:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	margin-right: -.25em;
}

.adventure-holder .text-block .centered {
	display: inline-block;
	vertical-align: middle;
	white-space: normal;
	max-width: 680px;
}

.grid-view-sidebar {
	padding-top: 90px;
	padding-bottom: 100px;
}

.grid-view-sidebar .container {
	padding-top: 0;
}

.grid-view-sidebar #content {
	float: right;
	margin-top: 0;
}

.grid-view-sidebar #sidebar {
	margin-top: 0;
}

.grid-view-sidebar .filter-option {
	max-width: none;
}

.grid-view-sidebar .db-3-col .article {
	display: inline-block;
	margin-right: -.25em;
}

.grid-view-sidebar .pagination-wrap {
	max-width: none;
}

.btn.btn-lg {
	font-size: 30px;
	line-height: 38px;
	font-weight: 300;
	min-width: 345px;
	letter-spacing: 1px;
}

.pagination-wrap .btn-next,
.pagination-wrap .btn-prev {
	width: 70px;
	font-size: 20px;
	padding-top: 0;
}

.pagination-wrap li {
	padding: 0 15px;
}

.count-block .txt {
	letter-spacing: 2px;
}

.article .heading h3 {
	padding-right: 30px;
}

.article .meta {
	font-size: 1em;
}

.article .thumbnail .meta {
	padding-bottom: 25px;
}

.article .thumbnail .meta .country [class^='icon-'],
.article .thumbnail .meta .country [class*=' icon-'],
.article .thumbnail .meta .activities [class^='icon-'],
.article .thumbnail .meta .activities [class*=' icon-'] {
	font-size: 15px;
	margin-right: 5px;
}

.article .thumbnail .img-wrap {
	margin-bottom: 25px;
}

.article footer .social-networks,
.article .info-aside .social-networks {
	max-width: 68%;
	float: right;
	padding-top: 5px;
	border-bottom: 0;
}

.article footer .price,
.article .info-aside .price {
	overflow: hidden;
	display: block;
	font-style: italic;
	letter-spacing: 1px;
	max-width: 32%;
	font-weight: 400;
}

.article footer .price span,
.article .info-aside .price span {
	display: block;
	font-weight: 900;
	font-style: normal;
	font-size: 1.286em;
	margin-top: -5px;
}

.guide-info {
	margin-top: 90px;
}

.list-view .article .img-wrap {
	float: left;
	margin: 0 20px 0 0;
	width: 350px;
}

.list-view .article .description {
	width: calc(100% - 370px);
	float: left;
}

.list-view .article .info-aside .price span {
	font-size: 1.667em;
}

.recent-block {
	padding: 60px 0 100px;
}

.recent-block .ico-list {
	float: none;
	width: auto;
}

.recent-block .sub-info {
	float: none;
	width: auto;
	margin-bottom: 15px;
}

.image-slide {
	height: calc(100% - 81px);
}

.image-slide .slide {
	height: 100%;
	width: 100%;
}

.seperator {
	padding-bottom: 45px;
}

.seperator:before {
	height: 8px;
	width: 170px;
}

.booking-form .form-holder,
.twocol-form .form-holder {
	margin-bottom: 0;
}

.product-table td,
.product-table th {
	padding: 15px 20px;
}

.featured-content.feature-small .text-block {
	padding: 60px 0;
}

.featured-content .text-block .holder {
	padding: 90px 30px;
}

.featured-content .image {
	min-height: 527px;
}

.progress-holder {
	float: right;
	padding-top: 0;
}

.block-quotation {
	padding: 45px 25px;
}

.partner-block {
	padding: 100px 0 100px;
}

.partner-block .partner {
	margin-top: 90px;
}

.step-block {
	padding: 90px 0;
}

.content-intro {
	padding-bottom: 90px;
}

.content-intro .map-col {
	text-align: left;
}

.content-intro .map-col .holder {
	margin-top: -100px;
}

.content-intro .text-holder {
	padding-top: 100px;
}

.trip-info .reviews-info {
	margin-bottom: 0;
}

.trip-detail {
	padding-top: 90px;
	padding-bottom: 90px;
}

.trip-detail .text-box {
	padding-left: 100px;
	margin-bottom: 45px;
}

.trip-detail .text-box:before {
	top: 19px;
	font-size: 48px;
}

.trip-detail .comments .thumb {
	width: 70px;
}

.trip-detail .comments .text {
	width: calc(100% - 80px);
}

.trip-detail [class^='col-'],
.trip-detail [class*=' col-'] {
	margin-bottom: 0;
}

.trip-detail .detail {
	padding: 40px 40px 20px 30px;
}

.trip-detail .gallery-list li {
	width: 33.3333%;
}

.trip-detail .text-box {
	padding-right: 80px;
}

.reviews-info {
	margin: 0 -30px 50px;
}

.reviews-info > li {
	padding: 0 30px 5px;
	display: inline-block;
	vertical-align: top;
	width: 50%;
	margin-right: -.25em;
	margin-bottom: 40px;
	font-size: 16px;
}

.reviews-info > li:before {
	left: 30px;
	right: 30px;
}

.question-select .jcf-select {
	padding: 20px 50px 20px 30px;
}

.question-select .jcf-select .jcf-select-text {
	font-size: 16px;
	line-height: 1.71429;
}

.question-select .jcf-select .jcf-select-opener {
	width: 41px;
}

.question-select .jcf-select .jcf-select-opener:after {
	font-size: 20px;
	margin-top: -10px;
}

.header-box {
	padding: 20px 30px;
	font-size: 16px;
}

.nav-tabs > li {
	width: auto;
	padding: 25px 10px;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .dropdown-menu {
	right: 140px;
}

table.table .btn {
	min-width: 150px;
}

table.table thead tr th {
	padding: 10px 20px 15px;
}

table.table tbody td .middle {
	padding: 5px 20px;
}

table.table {
	font-size: 1em;
}

.detail-accordion > li > a {
	padding-left: 100px;
}

.detail-accordion .slide-holder {
	padding-right: 60px;
	margin-left: 80px;
}

.detail-accordion .slide-holder p {
	margin: 0 0 25px;
}

.fancybox-prev span:after,
.fancybox-next span:after {
	left: 7px;
}

.fancybox-next span:after {
	right: 7px;
}

.fancybox-close {
	width: 60px;
	height: 60px;
}

.fancybox-close:after {
	line-height: 60px;
}

.fancybox-nav {
	width: 60px;
	height: 60px;
	margin-top: -30px;
}

.fancybox-nav.fancybox-next span:after {
	left: auto;
	right: 20px;
}

.fancybox-nav span {
	width: 60px;
	height: 60px;
}

.fancybox-nav span:after {
	line-height: 60px;
	left: 20px;
}

.carousel .carousel-control {
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 11px;
}

.article.blog-article .description {
	padding: 30px 30px;
}

.blog-list.list-view .img-wrap {
	float: left;
	width: 250px;
}

.blog-list.list-view .article.blog-article .description {
	padding: 10px 0 10px 20px;
}

.blog-list.list-view .description {
	width: calc(100% - 270px);
}

.blog-list.list-view .star-rating,
.blog-list.list-view .comment,
.blog-list.list-view .ico-action,
.blog-list.list-view .rate-info {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding: 0 10px;
}

.blog-list.list-view .star-rating:before,
.blog-list.list-view .comment:before,
.blog-list.list-view .ico-action:before,
.blog-list.list-view .rate-info:before {
	content: '';
	position: absolute;
	left: 0;
	top: 4px;
	bottom: 3px;
	width: 1px;
	background: #e2e2e2;
}

.blog-list.list-view .star-rating {
	padding-left: 0;
}

.blog-list.list-view .rate-info,
.blog-list.list-view .ico-action {
	padding-left: 0;
}

.blog-list.list-view .rate-info:before,
.blog-list.list-view .ico-action:before {
	display: none;
}

.blog-list.list-view .footer-sub {
	margin: 0;
	display: block;
}

.blog-single .description {
	padding: 30px 30px;
}

.search-form {
	top: 19px;
}

.search-form .search-opener {
	font-size: 11px;
	color: #fff;
}

.search-form .ui-menu .ui-menu-item {
	font-size: 30px;
	line-height: 40px;
	padding: 10px 0;
}

.search-form .ui-autocomplete {
	padding-top: 45px;
}

.search-wrap {
	padding: 30px 0;
}

.search-wrap .form-control {
	font-size: 30px;
	line-height: 50px;
}

.trip-form {
	display: block;
	margin-bottom: -30px;
}

.trip-form .holder {
	width: 25%;
	padding: 0 30px;
	margin-bottom: 30px;
}

.trip-form-v2.trip-search-main {
	vertical-align: middle;
	margin-top: 0;
}

.trip-form-v2.trip-search-main .holder {
	width: 33.33333%;
}

.jcf-select .jcf-select-text {
	font-size: 12px;
	line-height: 18px;
}

.jcf-select-drop.jcf-select-trip {
	box-shadow: none;
}

.jcf-select-drop.jcf-select-question .jcf-option {
	padding-left: 30px;
}

.comment-form {
	padding: 90px 0 0;
}

.map-col-main {
	margin-top: 0;
}

.contact-info {
	margin-top: 90px;
	margin-bottom: 104px;
}

.contact-info .col-sm-4 {
	margin-bottom: 0;
}

.error-page #main {
	padding-top: 100px;
}

.error-holder {
	padding-top: 100px;
}

.error-holder h1 {
	font-size: 7.143em;
}

.error-holder .title {
	margin-bottom: 90px;
}

.inner-search {
	margin-bottom: 90px;
}

.default-spacing.confirmation-block {
	padding-bottom: 100px;
}

/* .default-page #main {
	padding-top: 77px;
} */

.inner-top {
	padding: 60px 0;
}

.inner-top .breadcrumbs {
	font-size: 1em;
}

.inner-main .contact-form {
	padding: 60px 20px;
}

.inner-main .contact-form .btn-holder {
	padding-top: 15px;
}

.special-block .special-text {
	font-size: 2.571em;
}

.special-block {
	padding: 60px 0;
}

.browse-block a span {
	line-height: 150px;
}

.cart-option {
	max-width: none;
}

.cart-option .coupon-hold {
	margin-bottom: 0;
}

.cart-option .coupon-hold .input-hold .form-control {
	margin-bottom: 0;
}

.cart-option .button-hold {
	float: right;
	max-width: 50%;
	margin-bottom: 0;
}

.cart-option .button-hold .btn {
	margin: 0 0 0 25px;
	float: left;
}

.cart-option .btn {
	width: auto;
}

.coupon-hold {
	width: 400px;
	float: left;
}

.coupon-hold .submit-wrap {
	float: right;
	height: auto;
	margin-left: 20px;
}

.font-demo-wrapper .glyph.fs1 {
	width: 32%;
}

#footer > .container {
	padding-top: 100px;
	padding-bottom: 90px;
}

.newsletter-form {
	margin-bottom: 90px;
}

.banner.banner-inner.intro-banner .subtitle {
	font-size: 36px;
}

.slider-top {
	padding: 60px 0;
}

.demo-wrap {
	padding: 60px 0;
}

.demo-wrap .title {
	font-size: 15px;
	font-size: 18px;
}

.demo-wrap .content-heading {
	margin-bottom: 60px;
}

.demo-feature-list li {
	width: 32%;
	margin-top: 45px;
}

.demo-footer {
	padding: 60px 0;
}

.trip-form-v2 .holder {
	width: 50%;
}

.trip-form-v2 button {
	height: 52px;
}

blockquote .img,
.blockquote .img {
	display: inline-block;
	vertical-align: top;
	width: 112px;
	margin: 20px 0 0;
}

blockquote .text,
.blockquote .text {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 150px);
	padding-left: 55px;
}

h4,
.h4 {
	font-size: 1.2457em;
}

.bottom-spacing {
	margin-bottom: 90px;
}

.modal-footer .btn {
	font-size: 18px;
	padding: 10px;
}

.gallery-list.gallery-main li {
	width: 33%;
}

.pagination-wrap.pagination-center .btn-next,
.pagination-wrap.pagination-center .btn-prev {
	padding-top: 5px;
}

.has-mega-dropdown.mega-md .dropdown-menu {
	left: auto;
	width: 850px;
	right: 140px;
}

}

@media only screen and (min-width: 1025px) {

#header {
	position: fixed;
}

#header.fixed-position {
	background: #252525;
	border-color: #181818;
}

#header.fixed-position .logo {
	border-right-color: #181818;
}

#header.fixed-position .logo a {
	width: 98px;
}

#header.fixed-position .navbar-default .navbar-nav > li > a:hover {
	color: #b0a377;
}

#header.fixed-position .navbar-default .navbar-nav > li > a:active {
	color: #b0a377;
}

#header.fixed-position .search-form {
	top: 30px;
}

#header.fixed-position .search-form .search-opener {
	color: #9d9d9d;
}

#header.fixed-position .search-form .search-opener:hover {
	color: #b0a377;
}

.default-page #header.white-header.fixed-position,
#header.white-header.fixed-position {
	background: #f8f8f8;
	border-bottom: 1px solid #e2e2e2;
}

.default-page #header.white-header.fixed-position .logo,
#header.white-header.fixed-position .logo {
	border-right-color: #eaeaea;
}

.default-page #header.white-header.fixed-position .logo .normal,
#header.white-header.fixed-position .logo .normal {
	display: none;
}

.default-page #header.white-header.fixed-position .logo .gray-logo,
#header.white-header.fixed-position .logo .gray-logo {
	display: block;
}

.default-page #header.white-header.fixed-position .search-form .search-opener,
#header.white-header.fixed-position .search-form .search-opener {
	color: #5c5e62;
}

.default-page #header.white-header.fixed-position .search-form .search-opener:hover,
#header.white-header.fixed-position .search-form .search-opener:hover {
	color: #000;
}

.default-page #header.white-header.fixed-position .search-form .search-opener.close,
#header.white-header.fixed-position .search-form .search-opener.close {
	color: #fff;
}

.default-page #header.default-white-header.fixed-position,
#header.default-white-header.fixed-position {
	border-bottom: 1px solid #e2e2e2;
}

.default-page #header.default-white-header.fixed-position .logo,
#header.default-white-header.fixed-position .logo {
	border-right-color: #eaeaea;
}

#header.fixed-position .navbar-default .navbar-nav > li > a {
	height: 44px;
}

#header.fixed-position .navbar-default .v-divider {
	border-left-color: #181818;
}

.trip-form-v2 {
	margin: 0;
}

}

@media only screen and (min-width: 1230px) {

#header {
	height: 78px;
}

#header.fixed-position {
	height: 70px;
}

#header.fixed-position .search-form {
	right: 50px;
}

.logo {
	width: 240px;
	/* padding: 38px 15px 38px 0; */
}

.header-v2 .navbar-default .nav-center {
	width: calc(100% - 430px);
}

.navbar-default .navbar-nav {
	max-width: calc(100% - 240px);
}

.navbar-default .navbar-nav > li {
	/* padding: 40px 14px 0; */
}

.navbar-default .navbar-nav > li .dropdown-menu {
	margin-top: 0;
}

.navbar-default .navbar-nav > li.v-divider {
	padding-left: 20px;
	padding-right: 20px;
}

.navbar-default .navbar-nav > li > a {
	/* height: 62px; */
}

.navbar-default .navbar-nav .v-divider {
	border-left: 1px solid #2d2d2d;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
}

.navbar-collapse {
	padding-right: 0;
}

.dropdown-menu .five-col {
	margin: 0 -40px;
}

.dropdown-menu .five-col .column {
	padding: 0 40px;
}

.banner-caption-s1 .center-text,
.banner-home .banner-caption-s1 .center-text {
	max-width: 1200px;
}

.gallery-list {
	margin: 0 23px 0 -7px;
}

.gallery-list li {
	padding: 0 7px;
	margin-bottom: 14px;
}

.gallery-list.gallery-with-icon .info {
	display: block;
}

.gallery-list .info {
	letter-spacing: 3px;
	bottom: 20px;
	left: 10px;
	right: 10px;
}

.filter-option.filter-box .result-info {
	max-width: 45%;
}

.filter-option.filter-box .select-col {
	width: 200px;
}

.filter-option .result-info {
	float: left;
	max-width: 40%;
	margin-bottom: 0;
}

.filter-option .layout-holder {
	float: right;
	width: 60%;
}

.filter-option.filter-box .layout-holder {
	width: 55%;
}

.filter-option.filter-box .select-holder {
	text-align: right;
}

.count-block .info {
	font-size: 2.857em;
}

.count-block .txt {
	font-size: 1.286em;
	letter-spacing: 4px;
}

.article .info-day {
	padding-left: 30px;
	font-size: 1.286em;
}

.article .img-caption {
	font-size: 18px;
}

.content-with-sidebar .article .meta > span,
.content-with-sidebar .article .meta .country {
	display: inline-block;
	vertical-align: middle;
	margin: 0 -.25em 0 0;
	width: 49%;
}

.list-view .article .ico-list li {
	margin: 0 30px 0 0;
}

.list-view .article .info-footer .ico-action li {
	margin: 0 0 0 30px;
}

.recent-block h2 {
	margin-bottom: 45px;
}

.recent-block .ico-list {
	float: left;
	width: calc(100% - 112px);
}

.recent-block .article {
	margin-top: 0;
}

.recent-block .sub-info {
	float: right;
	width: 124px;
	margin-bottom: 0;
}

.featured-content .image {
	min-height: 627px;
}

.trip-detail {
	padding-bottom: 90px;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .dropdown-menu {
	right: 236px;
}

.article.blog-article .meta {
	padding-bottom: 25px;
}

.blog-list .img-wrap {
	width: auto;
	float: none;
}

.blog-list.list-view .img-wrap {
	width: 350px;
}

.blog-list.list-view .description {
	width: calc(100% - 370px);
}

.blog-list.list-view .rate-info,
.blog-list.list-view .ico-action {
	padding-left: 10px;
}

.blog-list.list-view .rate-info:before,
.blog-list.list-view .ico-action:before {
	display: block;
}

.blog-list.list-view .footer-sub {
	margin: 0;
	display: inline-block;
}

.search-form {
	right: 50px;
}

.contact-info .tel {
	margin-bottom: 43px;
}

.contact-info [class^='icon-'],
.contact-info [class*=' icon-'] {
	margin: 0 10px;
}

/* .default-page #main {
	padding-top: 100px;
} */

.footer-nav {
	margin-bottom: 15px;
}

.footer-nav h3 {
	margin-bottom: 35px;
}

.footer-bottom {
	text-align: left;
}

.payment-option {
	margin-top: 0;
	display: block;
}

.demo-feature-list li {
	width: 24%;
	margin-top: 60px;
}

.has-mega-dropdown.mega-md .dropdown-menu {
	left: auto;
	width: 800px;
	right: 234px;
}

}

@media only screen and (min-width: 1230px) and (max-width: 1300px) {

.container {
	max-width: 1110px;
}

}

@media only screen and (min-width: 1300px) {

.reviews-info {
	margin-bottom: 80px;
}

}

@media only screen and (min-width: 1600px) {

.has-mega-dropdown > .dropdown-menu {
	left: 170px;
	right: 170px;
}

.navbar-nav > li.has-mega-dropdown:last-child .dropdown-menu,
.navbar-nav > li.has-mega-dropdown.last-dropdown .dropdown-menu {
	left: 170px;
	right: 170px;
}

.banner-home .banner-text h1 {
	font-size: 7.143em;
}

.banner-home .banner-text h1 span {
	font-size: .72em;
}

.banner-home .banner-text .subtitle {
	font-size: 2.143em;
}

.banner-text h1 {
	margin-bottom: 30px;
}

.banner-icon-search .banner-text h1 {
	font-size: 5.571em;
}

.feature-block {
	font-size: 1em;
	padding: 30px 0 15px;
}

.feature-block ul li {
	padding: 20px 10px;
}

.feature-block li a:hover .ico {
	top: -10px;
}

.feature-block .ico {
	font-size: 45px;
}

.count-block .holder {
	padding: 90px 10px;
}

.trip-info {
	padding-bottom: 0;
}

.trip-info > .row {
	height: calc(100vh - 101px);
}

.trip-info > .row > .height {
	min-height: 100%;
}

.trip-info .reviews-info {
	margin-bottom: 45px;
}

.trip-info .social-share li .ico {
	margin-right: 10px;
}

.trip-info .text-col .holder {
	padding: 100px 20px 100px;
}

.trip-info .description {
	margin-bottom: 90px;
}

.trip-info .btn-holder {
	margin-bottom: 100px;
}

.trip-info .btn-holder {
	margin-bottom: 100px;
}

.trip-form .trip-form {
	margin-top: 10%;
}

}

@media only screen and (max-width: 1599px) {

.banner.banner-slider .banner-text {
	width: 1000px;
}

}

@media only screen and (max-width: 1299px) {

.feature-block ul {
	min-width: 1210px;
	overflow: visible;
}

}

@media only screen and (max-width: 1169px) {

.banner.banner-slider .banner-text {
	width: 950px;
}

}

@media only screen and (max-width: 1023px) {

.nav-wrap.is-stuck {
	top: 0 !important;
}

}

@media only screen and (max-width: 991px) {

#header .container-fluid {
	padding: 0;
}

.header-v1 .container {
	width: auto;
	padding: 0;
}

.header-v1 .header-top {
	padding: 10px 30px;
}

.grid-view-sidebar .accordion .collapse {
	display: block;
	height: auto !important;
}

.grid-view-sidebar .accordion .collapsing {
	height: auto !important;
}

.grid-view-sidebar .accordion .collapse.in {
	display: none;
}

.navbar-default {
	min-height: 0;
}

.navbar-default .navbar-collapse {
	border-color: #252525;
}

.banner.banner-slider .banner-text {
	width: 520px;
}

.grid-view-sidebar .container {
	position: relative;
	padding-top: 168px;
	max-width: 500px;
}

.grid-view-sidebar [class^='col-'],
.grid-view-sidebar [class*=' col-'] {
	position: static;
}

.grid-view-sidebar .filter-option {
	position: absolute;
	top: 60px;
	left: 15px;
	right: 15px;
	max-width: none;
}

.grid-view-sidebar .filter-option .result-info {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.featured-content .bg-stretch {
	position: static;
	margin: 0 -15px;
}

.featured-content .bg-stretch img {
	width: 100% !important;
	height: auto !important;
	margin: 0 !important;
}

.featured-content .height {
	height: auto !important;
}

.featured-content .row:nth-child(even) .image {
	float: none;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .drop-wrap {
	padding-top: 20px;
	padding-bottom: 20px;
}

.table-responsive {
	overflow: auto;
	overflow-y: hidden;
	width: 100%;
}

.wow {
	visibility: visible !important;
	-webkit-animation: none !important;
	animation: none !important;
}

.animated {
	-webkit-transition: none !important;
	transition: none !important;
}

.contact-info .tel {
	font-size: 18px;
}

.contact-info .tel [class^='icon-'],
.contact-info .tel [class*=' icon-'] {
	font-size: 25px;
}

.footer-holder .col-xs-6 {
	margin-bottom: 45px;
	float: none;
	display: inline-block;
	vertical-align: top;
	margin-right: -.27em;
}

.trip-form-v2 {
	max-width: 600px;
	padding: 10px 10px 20px;
}

.trip-form-v2 .form-control {
	height: 32px;
	font-size: 12px;
}

.trip-form-v2 label {
	margin-bottom: 0;
}

.trip-form-v2 .input-group.date {
	border-width: 2px;
}

.trip-form-v2 .holder {
	margin-bottom: 15px;
}

.trip-form-v2 .input-group {
	padding-left: 30px;
}

.trip-form-v2 .input-group:before {
	font-size: 12px;
	left: 10px;
}

.trip-note {
	margin: 0 15px 10px;
	font-size: 13px;
}

.btn-holder {
	padding: 0 15px;
}

.jcf-select.jcf-select-trip-select-v2 {
	border-width: 2px;
	height: 32px;
}

.jcf-select.jcf-select-trip-select-v2 .jcf-select-text {
	padding: 2px 0 1px 35px;
	font-size: 12px;
}

.jcf-select.jcf-select-trip-select-v2 .jcf-select-text:before {
	font-size: 12px;
	left: 10px;
}

.btn.btn-solid {
	font-size: 14px;
	line-height: 20px;
	padding: 10px;
}

.btn.btn-trip-v2 {
	height: 32px;
}

}

@media only screen and (max-width: 767px) {

.header-top .visible-xs.cart-visible {
	display: inline-block !important;
}

.mobile-openclose {
	max-height: 0;
	overflow: hidden;
	padding: 0;
	min-height: 0;
	max-width: 470px;
	margin: 0 auto;
}

.mobile-openclose .layout-holder {
	padding-bottom: 30px;
}

.rev_slider .tparrows {
	display: none !important;
}

.parallax {
	background-attachment: scroll;
	background-position: 50% 50% !important;
}

.adventure-holder .height {
	height: auto !important;
}

.filter-option .select-col.sidebar-filter-opener {
	display: inline-block;
	width: 54px;
	margin-left: 10px;
	text-align: center;
	font-size: 17px;
}

.filter-option .select-col.sidebar-filter-opener a {
	display: block;
	border: 2px solid #5c5e62;
	height: 34px;
	width: 34px;
}

.filter-option.filter-box {
	padding: 10px;
}

.filter-option.filter-box .select-holder {
	width: calc(100% - 86px);
}

.filter-option .select-col {
	padding: 0 15px 10px;
}

.article.height {
	height: auto !important;
}

.image-slide {
	margin-right: -15px;
}

.blog-single .meta-article .footer-sub {
	display: block;
}

.blog-single .meta-article .ico-action,
.blog-single .meta-article .rate-info {
	padding-left: 0;
}

.blog-single .meta-article .ico-action:before,
.blog-single .meta-article .rate-info:before {
	display: none;
}

.trip-form {
	background: transparent;
	box-shadow: none;
	border: none;
	padding: 0;
	max-width: 450px;
	margin-top: 0;
}

.trip-form label {
	display: none;
}

.contact-info .tel {
	line-height: 32px;
	height: 72px;
	padding: 12px 10px;
}

.contact-info .tel [class^='icon-'],
.contact-info .tel [class*=' icon-'] {
	display: none;
}

.map-holder iframe {
	height: 360px;
}

.trip-note {
	margin: 0 5px 10px;
	text-align: center;
}

.btn-holder {
	padding: 0 5px;
	text-align: center;
}

.btn.btn-trip-v2 {
	height: 40px;
}

#common-slide .owl-buttons {
	display: none;
}

.table.data-table tr:first-child td,
.table.data-table td {
	border-top: 1px solid #e2e2e2;
}

}

@media only screen and (max-width: 539px) {

.banner.banner-slider .banner-text {
	width: 300px;
}

.count-block [class^='col-'],
.count-block [class*=' col-'] {
	width: 100%;
}

.nav-tabs > li {
	font-size: 16px;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .drop-holder {
	position: relative;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .drop-holder:before {
	top: 0;
	bottom: 0;
	left: 95px;
	width: 1px;
	background: #181818;
	content: '';
	position: absolute;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .nav-hover {
	width: 100px;
	font-size: 11px;
	padding-right: 10px;
	line-height: 1.2;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .nav-hover li {
	font-size: 11px;
	width: auto;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .tab-content {
	width: calc(100% - 105px);
	padding-left: 5px;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .tab-content .header-link {
	-webkit-column-count: 1;
	-moz-column-count: 1;
	column-count: 1;
}

.navbar-nav .mega-center.has-mega-dropdown.mega-md .tab-content .header-link li {
	padding: 0;
	margin-bottom: 5px;
}

}

@media only screen and (max-width: 479px) {

.comments.reply {
	padding-left: 0;
}

.comments .comment-slot .thumb {
	display: block;
	margin-bottom: 10px;
}

.comments .comment-slot .text {
	display: block;
	width: auto;
}

.comments .link-more {
	margin-left: 0;
}

.contact-info {
	margin-bottom: 20px;
}

.footer-holder .col-xs-6 {
	width: auto;
}

}

@-webkit-keyframes steam {

0% {
	height: 0;
}

100% {
	height: 10px;
}

}

@keyframes steam {

0% {
	height: 0;
}

100% {
	height: 10px;
}

}

@-webkit-keyframes spinAround {

from {
	-webkit-transform: rotate(0deg);
}

to {
	-webkit-transform: rotate(360deg);
}

}

@keyframes spinAround {

from {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
}

to {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
}

}

@-webkit-keyframes toRightFromLeft {

49% {
	-webkit-transform: translate(100%);
}

50% {
	opacity: 0;
	-webkit-transform: translate(-100%);
}

51% {
	opacity: 1;
}

}

@keyframes toRightFromLeft {

49% {
	-webkit-transform: translate(100%);
	transform: translate(100%);
}

50% {
	opacity: 0;
	-webkit-transform: translate(-100%);
	transform: translate(-100%);
}

51% {
	opacity: 1;
}

}
.navbar{
    border: 1px solid black;
}
.navbar-default{
    border-color: #000;
}
.navbar-default{
    background-color: #252525;
}


.dropdown-menu-1{
    position: absolute;
	width: 900px;
    right: 0px;
    background: #252525;
    display: none;
}
.has-mega-dropdown:hover .dropdown-menu-1{
    display: block;
}
#footer{
    background: #252525;
}

.carousel-root{
	background-color: #25252585;
}

.bgimg1{
	background-image: url('../img/sundarban/Sundari-Tree-in-Sundarban.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	height: 400px;
	
}
.about-box{
	background-color: #000000a8;
	padding-top: 200px;
	height: 100%;
}
.btn{
	padding: 10px !important;
}
.form-control{
	height: 38px !important;
}
.nav-tabs > li.active > a{
	background-color: #252525 !important;
    border: 1px solid #252525 !important;
}
.btn-readmore{
	background: #a97648;
    color: white;
    width: 20%;
}

.left-box .col-sm-4{
	font-size: large;
    color: #6e2009;
    padding-top: 20px;
}
.padding-top-20{
	padding-top: 20px;
}
.def-color{
	color: #6e2009;
}
.details-div p{
	font-size: 15px;
	padding-top: 20px;
}
.box-login{
	width: 33%;
	margin: auto;
	margin-top: 20px;
}
.box-login .form-control{
	background-color: transparent;
	padding-left: 30px;
}
.box-login .btn{
	background-color: #f7b803;
	color: white;
	font-weight: bold;
}
.text-white{
	color: white;
}
.underline{
	text-decoration: underline;
	text-align: center;
	font-size: 15px;
}
.input-icons svg{
	position: absolute;
	padding-top:8px;
}
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap')
;
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap');
/* .about-text{
	font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
} */

.contact-box p, .contact-box p span{
	font-size: 15px;
	font-family: "PT Serif", serif;
}
.contact-box{
	padding-top: 30px;
}
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.iconCon{
	position: relative;
	top: 10px;
}
.shocial-list{
	list-style: none;
}
.shocial-list li svg{
	height: 30px;
	color: #f4b104;
	margin: 5px;
}
.shocial-list li {
	float: left;
}
.haer-2{
	/* background-color: rgb(56, 15, 3); */
	background-color: #252525;
	position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%;
  z-index: 99999999;
	}
.logo-img{
	height: 88px;
}
.img-div{
	position: relative;
	display: inline-block;
}
.ul-div{
	position: relative;
	display: inline-block;
	float: right;	
}
.ul-div ul{
	list-style: none;
	padding: 0px;
	margin: 0px;
}
.ul-div ul li{
	float: left;
	display: inline-block;
	padding: 27px 2px;
}
.ul-div ul li a{
	color: white;
    padding: 10px;
    font-size: 18px;	
	font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: normal;
}
.marjin-top{
	padding-top: 30px;
}
.menu-bar{
	display: none;
}
/* For Mobile Portrait View */
@media only screen and (max-width: 600px) {
	.btn-readmore{
		position: relative;
    width: 100%;
    bottom: 20px;
	}
	.hide-mobile{
		display: none;
	}
	.footer-nav ul li{
		text-align: center;
		padding: 4px;

	}
	@keyframes slideaway {
		from { display: none; }
		to { display: block;}
	  }
	.show-mobile{
		animation: slideaway 1000ms;

	}
	.has-mega-dropdown:hover .dropdown-menu-1{
		display: none !important;
	}
	.box-login{
		width: 80% !important;
	}
    .img-div{
		width: 100%;
		text-align: center;
		border-bottom: 1px solid;
	}
	.menu-bar{
		display: inline-block;
		float: right;
		position: relative;
		margin-top: 13px;
		margin-right: 12px;
	}
	.logo-img{
		height: 60px;
	}
	.ul-div{
		background-color: #252525;

	}
	.ul-div ul li{
		display: block;
		width: 100%;
		padding: 5px 5px;
		text-align: center;
		border-bottom: 1px solid;
	}
	.ul-div ul li a{
		width: 100%;
	}
	.mobile-hide{
		display: none;
	}
	.mobile-hide{
		display: none !important;
	}
}
 
/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) 
    and (orientation: landscape) {
   
}


@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');

.text-1, .about-text{
	font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-style: normal;
    font-size: larger;
    line-height: 35px;
}
.mega-menu{
	border: 1px solid red;
}
.img-wrap1 p.short-text{
	max-height: 74px;
	white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
}
.img-wrap1 p.long-text{
    text-align: justify;
}